import { i18n } from '@/components/I18N';
import { Generic_Service_Error_Type } from '@/graphql-types';
import { GraphQLFormattedError } from 'graphql';

type GetTranslatedGraphQLErrors = {
  message: string;
  errorStatus?: number;
};

const getTranslatedGraphQLErrors = (
  errors: ReadonlyArray<GraphQLFormattedError>,
  i18nParam?: string | null
): GetTranslatedGraphQLErrors[] => {
  return errors.map((error) => {
    const errorCode = error.extensions?.errorCode;
    const errorStatus = error.extensions?.errorStatus as number;

    const isKnownErrorCode = Object.values(Generic_Service_Error_Type).includes(
      errorCode as Generic_Service_Error_Type
    );

    if (isKnownErrorCode) {
      return {
        message: i18n.t(`error.graphql.${errorCode}`, { i18nParam }),
        errorStatus: errorStatus,
      };
    }

    // Return the original error message if the errorCode is not found in the enum
    return {
      message: error.message,
      errorStatus: errorStatus,
    };
  });
};

export default getTranslatedGraphQLErrors;
