import { ActionIcon, Flex } from '@mantine/core';
import { SelectionPreviewVariant, SELECTION_PREVIEW_VARIANT } from '../types';
import IconPencil from '@/assets/icons/IconPencil.svg?react';
import IconTrashCan from '@/assets/icons/IconTrashCan.svg?react';
import { CustomerAvatar } from '@/components/Customers/CustomerAvatar/CustomerAvatar';
import { Customer } from '@/graphql/types';

type SelectionPreviewCustomerProps = {
  stack1Rows: (string | number | undefined | React.ReactNode)[];
  stack2Rows?: string[];
  variant: SelectionPreviewVariant;
  handleEditClick?: () => void;
  handleDeleteClick?: () => void;
  customer: Partial<Customer>;
};

const SelectionPreviewCustomer = ({
  stack1Rows,
  variant,
  handleEditClick,
  handleDeleteClick,
  customer,
}: SelectionPreviewCustomerProps) => {
  const getActionAreaIcon = () => {
    if (variant === SELECTION_PREVIEW_VARIANT.NONE) {
      return null;
    }

    if (variant === SELECTION_PREVIEW_VARIANT.EDIT) {
      return (
        <ActionIcon className="rounded-full bg-angel-feather hover:bg-feather-dark" size="lg" onClick={handleEditClick}>
          <IconPencil height={20} width={20} />
        </ActionIcon>
      );
    }

    if (variant === SELECTION_PREVIEW_VARIANT.EDIT_REMOVE) {
      return (
        <div className="flex justify-between gap-2">
          <ActionIcon
            className="rounded-full bg-angel-feather hover:bg-feather-dark"
            size="lg"
            onClick={handleEditClick}
          >
            <IconPencil height={20} width={20} />
          </ActionIcon>
          <ActionIcon
            className="rounded-full bg-angel-feather hover:bg-feather-dark"
            size="lg"
            onClick={handleDeleteClick}
          >
            <IconTrashCan height={20} width={20} />
          </ActionIcon>
        </div>
      );
    }
    if (variant === SELECTION_PREVIEW_VARIANT.REMOVE) {
      return (
        <ActionIcon
          className="rounded-full bg-angel-feather hover:bg-feather-dark"
          size="lg"
          onClick={handleDeleteClick}
        >
          <IconTrashCan height={20} width={20} />
        </ActionIcon>
      );
    }
  };

  return (
    <Flex gap="md" justify="space-between" align="center" direction="column" wrap="wrap">
      <Flex justify="space-between" className="w-full">
        <Flex align="center">
          <CustomerAvatar customer={customer} size={48} radius={16} />
          <div className="ml-3">
            {stack1Rows?.map((row, index) => (
              <div
                key={index}
                className={index === 0 ? 'text-md max-w-[450px] text-black-olive' : 'text-silver-chalise'}
              >
                {row}
              </div>
            ))}
          </div>
        </Flex>

        <div className="my-auto flex self-end">{getActionAreaIcon()}</div>
      </Flex>
    </Flex>
  );
};

export default SelectionPreviewCustomer;
