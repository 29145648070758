import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { InputError, useCombobox } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useGetCustomersQuery } from '@/graphql-operations';
import { Customer } from '@/graphql-types';
import { showErrorToast } from '@/components/Toast';
import CustomSearchCombobox from '@/components/CustomSearchCombobox';
import { CreateInvoiceFormValues, SELECTION_PREVIEW_VARIANT } from '../types';
import SelectionPreviewCustomer from './SelectionPreviewCustomer';
import { DashedButton } from '@/components/common/DashedButton/DashedButton';
import CustomModal from '@/components/CustomModal';
import CreateCustomerModalForm from '@/components/Customers/CreateCustomerModal/CreateCustomerModalForm';

type FormCustomer = {
  fieldName: string;
  preSelectedCustomerData: Customer | null;
  canDeleteCustomer?: boolean;
};

const FormCustomer = ({ fieldName, preSelectedCustomerData = null, canDeleteCustomer = true }: FormCustomer) => {
  const { setFieldValue, errors } = useFormikContext<CreateInvoiceFormValues>();
  const [field] = useField<Customer | null>(fieldName);
  const [customerToEdit, setCustomerToEdit] = useState<Partial<Customer> | null>(null);
  const [isMobileCustomerDrawerOpened, setMobileCustomerDrawerOpened] = useState(false);

  const { t } = useTranslation();

  const isEditing = !!customerToEdit;

  const modalTitle = isEditing ? t('modal.editCustomer.title') : t('label.newCustomer');

  const [search, setSearch] = useState('');
  const [fetchedCustomersData, setFetchedCustomersData] = useState<Partial<Customer>[] | null>(
    preSelectedCustomerData ? [preSelectedCustomerData] : []
  );
  const [opened, setOpened] = useState(false);
  const [debouncedSearch] = useDebouncedValue(search, 500);

  const { loading, refetch, fetchMore } = useGetCustomersQuery({
    variables: { searchParams: { search: debouncedSearch } },
    // skip: !debouncedSearch,
    onCompleted(data) {
      setFetchedCustomersData(data.customers);
    },
    onError: (error) => {
      showErrorToast({
        title: t('error.error'),
        message: error.message,
      });
    },
  });

  const handleFetchMoreCustomers = async () => {
    const { data } = await fetchMore({
      variables: {
        params: { limit: 5 },
        searchParams: { search: debouncedSearch },
      },
    });

    setFetchedCustomersData(data.customers);
  };

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: handleFetchMoreCustomers,
  });

  const formattedCustomerData =
    fetchedCustomersData?.map((customer) => ({
      label: customer.name as string,
      value: customer.id!,
      country: customer.country,
      taxpayerNumber: customer.taxpayerNumber,
      customer: customer,
    })) || [];

  const handleCreateCustomerSuccess = async (newCustomer: Partial<Customer>) => {
    await setFieldValue(fieldName, newCustomer);
    setFetchedCustomersData([newCustomer]);
    resetCustomerToEdit();
    setOpened(false);
  };

  const handleComboboxValueChange = async (val: string) => {
    resetCustomerToEdit();

    if (val === '$create') {
      setOpened(true);
    } else {
      const selectedOptionLabel = formattedCustomerData.find((option) => option.value === val)?.label || '';

      const fullItemObjectFromValue = fetchedCustomersData!.find((customer) => val === customer.id);
      await setFieldValue(fieldName, fullItemObjectFromValue);

      setSearch(selectedOptionLabel);
    }
    combobox.closeDropdown();
    handleMobileCustomerDrowerClose();
  };

  // TODO: WHAT THE ACTUAL FUCK IS THIS?????
  const selectedCustomer =
    fetchedCustomersData?.find((customer) => customer.id === field.value?.id) ||
    (field.value?.name ? field.value : undefined);

  const stack1Rows = selectedCustomer
    ? [
        selectedCustomer.country === 'PT'
          ? `${selectedCustomer.taxpayerNumber ? `${selectedCustomer.country} ${selectedCustomer.taxpayerNumber}` : t('label.noNif')}`
          : `${selectedCustomer.country} ${selectedCustomer.taxpayerNumber}`,
      ]
    : [];

  const handleEditClick = (customer: Partial<Customer>) => {
    setCustomerToEdit(customer);
    setOpened(true);
  };

  const resetCustomerToEdit = () => {
    if (customerToEdit) {
      setCustomerToEdit(null);
    }
  };

  const getSectionPreviewCustomerVariant = () => {
    if (canDeleteCustomer) {
      // FIXME: figure more robust way to check if customer is default in https://linear.app/fiz-co/issue/WEB-784
      return selectedCustomer?.name === 'Consumidor final'
        ? SELECTION_PREVIEW_VARIANT.REMOVE
        : SELECTION_PREVIEW_VARIANT.EDIT_REMOVE;
    }

    return SELECTION_PREVIEW_VARIANT.NONE;
  };

  const handleMobileCustomerDrowerOpen = () => {
    handleFetchMoreCustomers();
    setMobileCustomerDrawerOpened(true);
  };

  const handleMobileCustomerDrowerClose = () => {
    setSearch('');
    setMobileCustomerDrawerOpened(false);
  };

  return (
    <>
      {selectedCustomer ? (
        <SelectionPreviewCustomer
          stack1Rows={[selectedCustomer.name, stack1Rows]}
          customer={selectedCustomer}
          variant={getSectionPreviewCustomerVariant()}
          handleDeleteClick={async () => {
            resetCustomerToEdit();
            await setFieldValue(fieldName, '');
            setSearch('');

            const { data } = await refetch({ params: { limit: 5 } });
            setFetchedCustomersData(data.customers);
          }}
          handleEditClick={() => handleEditClick(selectedCustomer)}
        />
      ) : (
        <>
          <DashedButton className="my-2 sm:my-0" onClick={handleMobileCustomerDrowerOpen}>
            {t('button.label.addCustomer')}
          </DashedButton>
          {errors.invoiceFormCustomer && <InputError size="md">{errors.invoiceFormCustomer}</InputError>}
          <CustomModal
            title={t('button.label.addCustomer')}
            isOpened={isMobileCustomerDrawerOpened}
            handleClose={handleMobileCustomerDrowerClose}
            onOpenChange={setMobileCustomerDrawerOpened}
          >
            <CustomSearchCombobox
              fieldName={fieldName}
              comboboxInstance={combobox}
              setSearch={setSearch}
              placeholder={t('placeholder.searchForCustomers')}
              search={search}
              loading={loading}
              formattedOptions={formattedCustomerData}
              handleValueChange={handleComboboxValueChange}
              // fieldError={errors.invoiceFormCustomer}
              searchName={t('dropdown.label.customer')}
              mode="mobile"
              minOptionsToShowSearch={5}
            />
          </CustomModal>
        </>
      )}

      <CustomModal isOpened={opened} title={modalTitle} handleClose={() => setOpened(false)} onOpenChange={setOpened}>
        <CreateCustomerModalForm
          isEditing={!!customerToEdit}
          defaultFormValues={
            customerToEdit
              ? {
                  taxpayerNumber: customerToEdit.taxpayerNumber,
                  email: customerToEdit.email,
                  name: customerToEdit.name,
                  country: customerToEdit.country,
                  city: customerToEdit.city,
                  address: customerToEdit.address,
                  postalCode: customerToEdit.postalCode,
                  phone: customerToEdit.phone,
                  companyPosition: customerToEdit.companyPosition,
                  companyName: customerToEdit.companyName,
                }
              : {
                  name: search,
                }
          }
          updateCustomerId={customerToEdit?.id}
          onSuccess={handleCreateCustomerSuccess}
        />
      </CustomModal>
    </>
  );
};

export default FormCustomer;
