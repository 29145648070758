import { ApolloClient, ApolloLink, createHttpLink, createQueryPreloader, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { shutdown as shutdownIntercom } from '@intercom/messenger-js-sdk';
import { GraphQLFormattedError } from 'graphql';
import posthog from 'posthog-js';

import { getAccessToken, redirectToLogin, removeAccessToken, setAccessToken } from '@/utils';
import { ptAddressToTitleCase, transformNameAndCityToTitleCase } from '@/utils/allCapsToTitleCase';
import getTranslatedGraphQLErrors from '@/utils/getTranslatedGraphQLErrors';

import { CURRENT_TENANT_STORAGE_KEY } from './constants';
import { captureExceptionEvent } from './sentry/initSentry';

export function handleGraphQLError(error: GraphQLFormattedError) {
  const errorCode = error.extensions?.errorCode;
  const { message, locations, path } = error;
  console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
  captureExceptionEvent(new Error(message), { operation: path?.[0] || '' });
  if (errorCode === 'AUTH_FORBIDDEN') {
    shutdownIntercom();
    posthog.reset();
    removeAccessToken();
    localStorage.removeItem(CURRENT_TENANT_STORAGE_KEY);
    redirectToLogin();
  } else {
    const translatedErrors = getTranslatedGraphQLErrors([error]);
    // @ts-expect-error
    error.message = translatedErrors[0].message;
  }
}

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_ENDPOINT,
  credentials: import.meta.env.MODE === 'development' ? 'include' : 'same-origin',
});

// Create an afterware link to check for the new access token header
const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const headers = context.response?.headers;

    if (headers) {
      const newAccessToken = headers.get('x-new-access-token');

      if (newAccessToken && newAccessToken !== getAccessToken()) {
        setAccessToken(newAccessToken);
      }
    }

    return response;
  });
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(handleGraphQLError);
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext(async (_, { headers }) => {
  const token = getAccessToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'x-tenant-id': localStorage.getItem(CURRENT_TENANT_STORAGE_KEY) || '',
    },
  };
});

export const client = new ApolloClient({
  link: from([errorLink, authLink, afterwareLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      TenantSubtype: {
        keyFields: false,
      },
      TenantSubtypeCategory: {
        keyFields: false,
      },
      Localization: {
        keyFields: false,
      },
      Query: {
        fields: {
          businessDataByTaxInfo: {
            keyArgs: false,
            merge(existing = {}, incoming) {
              return {
                ...existing,
                ...incoming,
                city: transformNameAndCityToTitleCase(incoming.city),
                address: ptAddressToTitleCase(incoming.address),
              };
            },
          },
        },
      },
    },
  }),
});

export const preloadQuery = createQueryPreloader(client);
