import { useField } from 'formik';
import { Loader, CloseButton } from '@mantine/core';
import { InputRightSectionProps } from '.';

const InputRightSection = ({ loading, setSearch, search, fieldName }: InputRightSectionProps) => {
  const [field] = useField(fieldName);

  if (loading) {
    return <Loader size={18} />;
  }

  if (field.value !== null && !!search) {
    return (
      <CloseButton
        size="sm"
        onMouseDown={(event) => event.preventDefault()}
        onClick={() => {
          setSearch('');
        }}
        aria-label="Clear value"
      />
    );
  }
};

export default InputRightSection;
