import { TFunction } from 'i18next';

import { Invoice_Document_Type } from '@/graphql-types';

export const translateInvoiceDocumentType = (type: Invoice_Document_Type, t: TFunction) => {

  switch (type) {
    case Invoice_Document_Type.CreditNote:
      return t('label.creditNote');
    case Invoice_Document_Type.DebitNote:
      return t('label.debitNote');
    case Invoice_Document_Type.Invoice:
      return t('label.invoice');
    case Invoice_Document_Type.InvoiceReceipt:
      return t('label.invoiceReceipt');
    case Invoice_Document_Type.SimplifiedInvoice:
      return t('label.simplifiedInvoice');
    case Invoice_Document_Type.Receipt:
      return t('label.receipt');
  }
};
