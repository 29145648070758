import type { ClassValue } from 'clsx';
import { cn } from '@/utils';

type InfoCardProps = {
  icon?: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
  iconClassName?: ClassValue;
};

const InfoCard = ({ icon, title, description, iconClassName }: InfoCardProps) => {
  return (
    <div className="flex gap-2">
      {icon && (
        <div className={cn('flex size-10 items-center justify-center rounded-[10px]', iconClassName)}>{icon}</div>
      )}

      <div className="text-sm">
        <div className="font-semibold md:text-[17px]">{title}</div>
        <div className="text-dark-bronze md:text-[14px]">{description}</div>
      </div>
    </div>
  );
};

export default InfoCard;
