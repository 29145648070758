import { Group, SegmentedControl, Switch, Text } from '@mantine/core';
import { useDebouncedValue, useScrollIntoView } from '@mantine/hooks';
import { IconPercentage } from '@tabler/icons-react';
import { useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CardBlock } from '@/components/common/CardBlock/CardBlock';
import { Discount_Type, Invoice_Document_Type } from '@/graphql-types';
import { cn } from '@/utils';

import { CreateInvoiceFormValues } from '../types';
import FormikDiscountInput from './FormikDiscountInput';

type FormDiscountProps = {
  invoiceDocumentType?: Invoice_Document_Type;
  handleTotalsOnDiscountChange: (value: number) => void;
  originalInvoiceDiscountAmount?: number | undefined;
};

const FormDiscount = ({
  invoiceDocumentType,
  handleTotalsOnDiscountChange,
  originalInvoiceDiscountAmount,
}: FormDiscountProps) => {
  const { setFieldValue, values, errors, setFieldError } = useFormikContext<CreateInvoiceFormValues>();
  const isCreditNote = invoiceDocumentType === Invoice_Document_Type.CreditNote;
  const isDebitNote = invoiceDocumentType === Invoice_Document_Type.DebitNote;
  const isCreditOrDebitNote = isCreditNote || isDebitNote;
  let discountInputFieldName: string;
  if (isCreditOrDebitNote) {
    discountInputFieldName = 'discountPercent';
    values.discountType = Discount_Type.Percent;
  } else {
    discountInputFieldName = values.discountType === Discount_Type.Percent ? 'discountPercent' : 'discountAmount';
  }
  const [discountInputField] = useField(discountInputFieldName);
  const [localValue, setLocalValue] = useState<string | number | undefined>(discountInputField.value || '');
  const [debouncedValue] = useDebouncedValue(localValue, 500);
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 60,
  });

  const { t } = useTranslation();

  useEffect(() => {
    setFieldValue(discountInputField.name, debouncedValue);
    handleTotalsOnDiscountChange(debouncedValue ? +debouncedValue : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const isDebitOrCreditNoteWithoutDiscount = isCreditOrDebitNote && !originalInvoiceDiscountAmount;

  if (isDebitOrCreditNoteWithoutDiscount) {
    return null;
  }

  return (
    <CardBlock.Stack className="rounded-[20px] bg-angel-feather p-4">
      <Group justify="space-between">
        <Text fw={400} className="text-[17px]" lh={1} c="#3D3B36">
          {t('label.discount')}
        </Text>
        {!isCreditOrDebitNote && (
          <Switch
            checked={values.discountSwitch}
            size="md"
            color="#438BFA"
            onChange={(event) => {
              setFieldValue('discountSwitch', event.currentTarget.checked);
              setFieldValue('discountPercent', undefined);
              setFieldValue('discountAmount', undefined);
              setFieldValue('discountType', Discount_Type.Percent);
              setLocalValue('');
            }}
          />
        )}
      </Group>

      {values.discountSwitch && (
        <>
          {!isCreditOrDebitNote && (
            <SegmentedControl
              ref={targetRef}
              radius="xl"
              defaultValue={values.discountType}
              data={[
                {
                  label: `${t('label.percent')} (%)`,
                  value: Discount_Type.Percent,
                },
                {
                  label: t('label.amount'),
                  value: Discount_Type.Amount,
                },
              ]}
              className={cn(
                `bg-deep-white`,
                {
                  'max-w-[100%]': isCreditOrDebitNote,
                },
                {
                  'lg:max-w-[80%] xl:max-w-[60%]': !isCreditOrDebitNote,
                }
              )}
              onChange={(value) => {
                if (errors[discountInputField.name as keyof typeof errors]) {
                  setFieldError('discountPercent', '');
                  setFieldError('discountAmount', '');
                }

                setFieldValue('discountType', value);
                setLocalValue('');
                setFieldValue('discountPercent', undefined);
                setFieldValue('discountAmount', undefined);
                handleTotalsOnDiscountChange(0);
              }}
            />
          )}

          <FormikDiscountInput
            id={discountInputFieldName === 'discountPercent' ? 'discountPercent' : 'discountAmount'}
            fieldName={discountInputField.name}
            localValue={localValue}
            disabled={isCreditOrDebitNote}
            rightSectionIcon={
              values.discountType === Discount_Type.Percent ? <IconPercentage width={16} height={16} /> : '€'
            }
            handleChange={(value) => {
              if (errors[discountInputField.name as keyof typeof errors]) {
                setFieldError('discountPercent', '');
                setFieldError('discountAmount', '');
              }

              setLocalValue(value);
            }}
            onFocus={() => scrollIntoView()}
            min={isCreditNote ? originalInvoiceDiscountAmount : 0}
            decimalSeparator=","
            thousandSeparator="."
            className="w-full"
            decimalScale={7}
            error={
              errors[discountInputField.name as keyof typeof errors]
                ? (errors[discountInputField.name as keyof typeof errors] as string)
                : null
            }
          />
        </>
      )}
    </CardBlock.Stack>
  );
};

FormDiscount.displayName = 'FormDiscount';

export default FormDiscount;
