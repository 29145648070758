import { normilizeInvoiceDate } from '@/utils/normalizeData';
import dayjs from 'dayjs';
import { FormikProps } from 'formik';
import { modals } from '@mantine/modals';
import { Text } from '@mantine/core';
import { CreateInvoiceFormValues, InvoiceActionType } from '../types';
import IssueInvoiceConfirmationTexts from './IssueInvoiceConfirmationTexts';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import { scrollToErrors } from '@/utils/scrollToErrors';

type UseHandleConfirmationModalProps = {
  formikProps: FormikProps<CreateInvoiceFormValues>;
  isCreditOrDebitNote: boolean;
  handleFormSubmit: (formikProps: FormikProps<CreateInvoiceFormValues>, actionType: InvoiceActionType) => void;
  handleSetupATConnection: () => void;
};

const useHandleConfirmationModal = ({
  setIsFirstSubmitAttempt,
}: {
  setIsFirstSubmitAttempt: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const tenantObject = useReactiveVar(tenantVar);

  const openConfirmationModal = async ({
    formikProps,
    isCreditOrDebitNote,
    handleFormSubmit,
    handleSetupATConnection,
  }: UseHandleConfirmationModalProps) => {
    const normilizedIssueDate = normilizeInvoiceDate(formikProps.values.invoiceDate, isCreditOrDebitNote);
    const isIssueDateAfterToday = dayjs(normilizedIssueDate).isAfter(dayjs(), 'day');

    const formErrors = await formikProps.validateForm();

    scrollToErrors(formErrors, {
      invoiceFormCustomer: 'invoiceCustomer',
      invoiceItems: 'invoiceItems',
    });

    if (typeof setIsFirstSubmitAttempt === 'function') {
      setIsFirstSubmitAttempt(true);
    }

    if (Object.keys(formErrors).length) {
      return;
    }

    if (!tenantObject?.credentials?.verified === true) {
      handleSetupATConnection();
      // modals.openConfirmModal({
      //   title: <Text className="text-[20px] font-bold text-black-magic">{t('label.accessRequired')}</Text>,
      //   children: (
      //     <Text className="text-[17px] font-normal text-light-black-magic">
      //       {t('label.needAtAandChooseCommunicationMethod')}
      //     </Text>
      //   ),
      //   centered: true,
      //   labels: { confirm: t('button.label.setUpAtConnection'), cancel: t('button.label.cancel') },
      //   onConfirm: handleSetupATConnection,
      //   cancelProps: {
      //     color: '#f5f2f0',
      //     variant: 'filled',
      //     className: 'hover:bg-feather-dark shrink grow xs:shrink-0 xs:grow-0',
      //   },
      //   confirmProps: {
      //     color: '#ffc850',
      //     variant: 'filled',
      //     className: 'shrink grow xs:shrink-0 xs:grow-0 hover:bg-light-orange',
      //   },
      //   groupProps: { className: 'flex-wrap xs:flex-none gap-2' },
      // });
    } else if (isCreditOrDebitNote) {
      modals.openConfirmModal({
        title: (
          <Text size="lg" fw={600}>
            {tenantObject?.isEnabledSyncWithAT
              ? t('modal.invoiceIssueConfirmationCreditDebitAuto.title')
              : t('modal.invoiceIssueConfirmationCreditDebitManual.title')}
          </Text>
        ),
        children: isCreditOrDebitNote ? (
          tenantObject?.isEnabledSyncWithAT ? (
            t('modal.invoiceIssueConfirmationCreditDebitAuto.body')
          ) : (
            t('modal.invoiceIssueConfirmationCreditDebitManual.body')
          )
        ) : (
          <IssueInvoiceConfirmationTexts isIssueDateAfterToday={isIssueDateAfterToday} />
        ),
        centered: true,
        labels: { confirm: t('button.label.yes'), cancel: t('button.label.no') },
        onConfirm: () => {
          handleFormSubmit(formikProps, InvoiceActionType.issue);
        },
      });
    } else {
      modals.openConfirmModal({
        title: (
          <Text size="lg" fw={600}>
            {tenantObject?.isEnabledSyncWithAT
              ? t('modal.invoiceIssueConfirmationAuto.title')
              : t('modal.invoiceIssueConfirmationManual.title')}
          </Text>
        ),
        children: tenantObject?.isEnabledSyncWithAT ? (
          t('modal.invoiceIssueConfirmationAuto.body')
        ) : tenantObject?.isEnabledSyncWithAT === false ? (
          t('modal.invoiceIssueConfirmationManual.body')
        ) : (
          <IssueInvoiceConfirmationTexts isIssueDateAfterToday={isIssueDateAfterToday} />
        ),
        centered: true,
        labels: { confirm: t('button.label.yes'), cancel: t('button.label.no') },
        onConfirm: () => {
          handleFormSubmit(formikProps, InvoiceActionType.issue);
        },
      });
    }
  };

  return openConfirmationModal;
};

export default useHandleConfirmationModal;
