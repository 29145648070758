import { FormikSelect } from '@/components/FormikWrapped';
import { useFormikContext } from 'formik';
import { CreateInvoiceFormValues } from '../types';
import { Invoice_Document_Type } from '@/graphql-types';
import { useTranslation } from 'react-i18next';

const FormInvoiceType = ({ fieldName }: { fieldName: string }) => {
  const { errors, touched } = useFormikContext<CreateInvoiceFormValues>();
  const { t } = useTranslation();

  return (
    <FormikSelect
      label={t('label.type')}
      size="md"
      name={fieldName}
      data={[
        { value: Invoice_Document_Type.Invoice, label: t('label.invoice') },
        { value: Invoice_Document_Type.InvoiceReceipt, label: t('label.invoiceReceipt') },
        { value: Invoice_Document_Type.SimplifiedInvoice, label: t('label.simplifiedInvoice') },
      ]}
      placeholder={t('placeholder.selectInvoiceType')}
      error={errors.invoiceType && touched.invoiceType ? errors.invoiceType : null}
    />
  );
};

export default FormInvoiceType;
