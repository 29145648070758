import { TFunction } from 'i18next';
import * as Yup from 'yup';

import { Discount_Type, Invoice_Document_Type } from '@/graphql-types';

const ItemSchema = (t: TFunction) =>
  Yup.object().shape({
    // id: Yup.string().required(t('validation.itemIdRequired')),
    name: Yup.string().required(t('validation.itemNameRequired')),
    type: Yup.string().required(t('validation.itemTypeRequired')),
    unitPrice: Yup.number().required(t('validation.unitPriceRequired')),
    unitType: Yup.string().required(t('validation.unitTypeRequired')),
    taxRate: Yup.number().required(t('validation.taxRateRequired')),
    quantity: Yup.number().required(t('validation.quantityRequired')),
  });

const validationSchema = (
  isCreditNote: boolean,
  isDebitNote: boolean,
  t: TFunction,
  amountWithoutTaxWithoutDiscount: number | undefined
) => {
  const isCreditOrDebitNote = isCreditNote || isDebitNote;

  return Yup.object().shape({
    invoiceType: Yup.string().required(t('validation.invoiceTypeRequired')),
    invoiceFormCustomer: Yup.object().required(t('validation.customerRequired')),
    invoiceItems: Yup.array().of(ItemSchema(t)).min(1, t('validation.itemRequired')),
    notes: Yup.string().notRequired().max(440, t('validation.notesTooLong')),
    paymentMethod: Yup.string().when('invoiceType', {
      is: (invoiceType: Invoice_Document_Type) =>
        [Invoice_Document_Type.SimplifiedInvoice, Invoice_Document_Type.InvoiceReceipt].includes(invoiceType) &&
        !isCreditOrDebitNote,
      then: () => Yup.string().required(t('validation.paymentMethodRequired')),
    }),
    discountSwitch: Yup.boolean(),
    discountType: Yup.string().oneOf([Discount_Type.Percent, Discount_Type.Amount]),
    discountPercent: Yup.number().when(['discountSwitch', 'discountType'], {
      is: (discountSwitchValue: boolean, discountType: Discount_Type) => {
        return discountSwitchValue === true && discountType === Discount_Type.Percent;
      },
      then: () =>
        Yup.number()
          .required('Discount percent is required')
          .test('is-greater-than-amount', 'Discount percent must not be more than 99%', function () {
            return (amountWithoutTaxWithoutDiscount || 0) > 0;
          }),
      otherwise: () => Yup.number().nullable().notRequired(),
    }),
    discountAmount: Yup.number().when(['discountSwitch', 'discountType'], {
      is: (discountSwitchValue: boolean, discountType: Discount_Type) => {
        return discountSwitchValue === true && discountType === Discount_Type.Amount;
      },
      then: () =>
        Yup.number()
          .required('Discount amount is required')
          .test('is-greater-than-amount', 'Discount amount must not be more than total amount', function () {
            return (amountWithoutTaxWithoutDiscount || 0) > 0;
          }),
      otherwise: () => Yup.number().nullable().notRequired(),
    }),
    reasonType: isCreditOrDebitNote
      ? Yup.string().required(t('validation.reasonRequired'))
      : Yup.string().notRequired(),
    reason: Yup.string().when(['invoiceType', 'reasonType'], {
      is: () => isCreditOrDebitNote,
      then: () =>
        Yup.string().when(['reasonType'], {
          is: 'other',
          then: () =>
            Yup.string()
              .required(t('validation.reasonRequired'))
              .max(50, t('validation.reasonTooLong'))
              .matches(/^(?!\s*$).+/, t('validation.reasonLimitations')),
        }),
    }),
    invoiceDate: isCreditOrDebitNote
      ? Yup.string().notRequired()
      : Yup.string().nullable().required(t('validation.invoiceDateRequired')),
    dueDate: isCreditOrDebitNote
      ? Yup.string().notRequired()
      : Yup.string().when('invoiceType', {
          is: (invoiceType: Invoice_Document_Type) =>
            [Invoice_Document_Type.SimplifiedInvoice, Invoice_Document_Type.InvoiceReceipt].includes(invoiceType),
          then: () => Yup.string().notRequired(),
          otherwise: () => Yup.string().nullable().required(t('validation.dueDateRequired')),
        }),
    scheduled: isCreditOrDebitNote
      ? Yup.boolean().notRequired()
      : Yup.boolean().required(t('validation.scheduledRequired')),
  });
};

export default validationSchema;
