import { Radio, Stack, Title } from '@mantine/core';
import { IconCalendar, IconCurrencyEuro } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/ButtonVariants';
// import { CALENDAR_FORMAT } from '@/utils';
import CustomModal from '@/components/CustomModal';
import { FormikInput, FormikSelect } from '@/components/FormikWrapped';
import FormikDateInput from '@/components/FormikWrapped/FormikDateInput';
import { Invoice, Invoice_Status } from '@/graphql-types';

import { MarkAsPaidActionModalFormikValues } from '../../types';
import { validationSchema } from './validation';

type MarkAsPaidActionModalProps = {
  opened: boolean;
  invoice: Invoice;
  title: string;
  close: () => void;
  invoiceTotal: number;
  status: Invoice_Status;
  handleMarkInvoicePaid: (formData: MarkAsPaidActionModalFormikValues) => void;
};

type TypedFormikProps = FormikProps<MarkAsPaidActionModalFormikValues>;

const MarkAsPaidActionModal = ({
  opened,
  invoice,
  title,
  close,
  invoiceTotal,
  status,
  handleMarkInvoicePaid,
}: MarkAsPaidActionModalProps) => {
  const { t } = useTranslation();

  const openMarkAsPaidConfirmationModal = async (
    submitForm: TypedFormikProps['submitForm'],
    validateForm: TypedFormikProps['validateForm']
  ) => {
    const formErrors = await validateForm();

    if (Object.keys(formErrors).length) {
      return;
    }

    submitForm();
  };

  return (
    <CustomModal isOpened={opened} handleClose={close} title={<Title className="text-[16px]">{title}</Title>}>
      <Formik
        initialValues={{
          paymentMethod: 'card',
          isPaidInFull: true,
          isPaidPartially: false,
          isPaidToday: true,
          isChooseDate: false,
          dateOfPayment: new Date(),
          amountPaid: invoiceTotal,
          totalRef: invoiceTotal,
        }}
        validationSchema={validationSchema}
        onSubmit={handleMarkInvoicePaid}
        validateOnBlur={false}
        validateOnChange
      >
        {({ values, setFieldValue, errors, submitForm, validateForm, isSubmitting }) => (
          <Form autoComplete="off">
            <Stack>
              <h6 className="font-semibold text-md">{t('markAsPaidActionModal.label.paymentMethodUsed')}</h6>
              <FormikSelect
                name="paymentMethod"
                label={t('label.yourChosenPaymentMethod')}
                placeholder={t('placeholder.selectPaymentMethod')}
                data={[
                  { value: 'card', label: t('option.card') },
                  { value: 'cash', label: t('option.cash') },
                  { value: 'bankTransfer', label: t('option.bankTransfer') },
                  { value: 'mbWay', label: t('option.mbWay') },
                  { value: 'spin', label: t('option.spin') },
                  { value: 'multibanco', label: t('option.multibanco') },
                  { value: 'other', label: t('option.other') },
                ]}
                error={errors.paymentMethod}
              />
              {status !== Invoice_Status.Issued && (
                <>
                  <h6 className="font-semibold text-md">{t('markAsPaidActionModal.label.hasPaidinFullOrPart')}</h6>
                  <Stack className="p-3 rounded-2xl bg-alice-blue">
                    <Field
                      type="radio"
                      name="isPaidInFull"
                      label={t('modal.markAsPaidActionModal.label.paidInFull')}
                      as={Radio}
                      checked={values.isPaidInFull}
                      onChange={() => {
                        if (values.amountPaid) {
                          setFieldValue('amountPaid', '');
                        }

                        setFieldValue('amountPaid', invoiceTotal);
                        setFieldValue('isPaidPartially', false);
                        setFieldValue('isPaidInFull', true);
                      }}
                    />
                    <Field
                      type="radio"
                      name="isPaidPartially"
                      label={t('modal.markAsPaidActionModal.label.paidPartially')}
                      as={Radio}
                      checked={!values.isPaidInFull}
                      onChange={() => {
                        setFieldValue('isPaidInFull', false);
                        setFieldValue('isPaidPartially', true);
                      }}
                    />
                    {values.isPaidPartially && (
                      <FormikInput
                        name="amountPaid"
                        type="number"
                        rightSection={<IconCurrencyEuro size={18} />}
                        size="md"
                        w="50%"
                        error={errors.amountPaid}
                      />
                    )}
                  </Stack>
                </>
              )}
              <h6 className="font-semibold text-md">{t('markAsPaidActionModal.label.dateOfPayment')}</h6>
              <Stack className="p-3 rounded-2xl bg-alice-blue">
                <Field
                  type="radio"
                  name="isPaidToday"
                  label={t('label.today')}
                  as={Radio}
                  checked={values.isPaidToday}
                  onChange={() => {
                    setFieldValue('isChooseDate', false);
                    setFieldValue('dateOfPayment', new Date());
                    setFieldValue('isPaidToday', true);
                  }}
                />
                <Field
                  type="radio"
                  name="isChooseDate"
                  label={t('label.chooseDate')}
                  as={Radio}
                  checked={!values.isPaidToday}
                  onChange={() => {
                    setFieldValue('isPaidToday', false);
                    setFieldValue('dateOfPayment', null);
                    setFieldValue('isChooseDate', true);
                  }}
                />
                {values.isChooseDate && (
                  <FormikDateInput
                    placeholder={t('label.chooseDate')}
                    value={values.dateOfPayment}
                    onChange={(value) => {
                      // datepicker returns a date with 00:00:00 +tz offset, so we need to set it to 12:00:00 so it can be converted to UTC
                      setFieldValue('dateOfPayment', dayjs(value).hour(12).toDate());
                    }}
                    size="md"
                    rightSection={<IconCalendar size={18} />}
                    name="dateOfPayment"
                    minDate={invoice.date ? new Date(invoice.date) : new Date()}
                    maxDate={new Date()}
                  />
                )}
              </Stack>
              <Button
                size="m"
                variant="primary"
                loading={isSubmitting}
                onClick={() => openMarkAsPaidConfirmationModal(submitForm, validateForm)}
              >
                {t('button.label.markAsPaid')}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default MarkAsPaidActionModal;
