import { Card, LoadingOverlay, Space, UnstyledButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconEdit } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// import Header from './parts/Header';
// import Body from './parts/Body';
import CustomModal from '@/components/CustomModal';
import { ConnectWithATOptions } from '@/components/Settings/AtCommunication/Credentials/ConnectWithATOptions';
import { Invoice_Document_Type, Invoice_Status } from '@/graphql-types';
import { CALENDAR_FORMAT, cn, convertPriceToFromCents, currencyFormatter } from '@/utils';
import { dateToISOString } from '@/utils/normalizeData';
import { titleOfTotal } from '@/utils/titleOfTotal';

import IconCreditNoteBlue from '../../../assets/icons/IconCreditNoteBlue.svg?react';
import CreditNoteActionModal from './parts/CreditNoteAction';
import DebitNoteActionModal from './parts/DebitNoteAction';
import DownloadPdfActionModal from './parts/DownloadPdfAction';
// import IconDebitNoteBlue from '../../../assets/icons/IconDebitNoteBlue.svg?react';
// import IconSaveBlue from '../../../assets/icons/IconSaveBlue.svg?react';
import InvoiceSummaryActions from './parts/InvoiceSummaryActions';
import MarkAsPaidActionModal from './parts/MarkAsPaidAction';
import useMiniInvoiceCardMutations from './parts/useMiniInvoiceCardMutations';
import { MarkAsPaidActionModalFormikValues, MiniInvoiceCardProps } from './types';
// import IconShare from '../../../assets/icons/IconShare.svg?react';
// import IconRepeat from '../../../assets/icons/IconRepeat.svg?react';

// const InvoiceSummary = ({ invoice }: MiniInvoiceCardProps) => {
//   const { t } = useTranslation();
//   const total = convertPriceToFromCents(invoice.summary?.total || 0, true);
//   const taxAmount = convertPriceToFromCents(invoice.summary?.taxAmount || 0, true);
//   const amountWithoutTax = convertPriceToFromCents(invoice.summary?.amountWithoutTax || 0, true);

//   const formattedTotal = currencyFormatter(total, invoice.currency || 'EUR');
//   const formattedTaxAmount = currencyFormatter(taxAmount, invoice.currency || 'EUR');
//   const formattedAmountWithoutTax = currencyFormatter(amountWithoutTax, invoice.currency || 'EUR');

//   return (
//     <>
//       <div className="flex justify-between">
//         <span className="text-sm text-black-burgundy">{t('label.total')}</span>
//         <span className="text-[17px] font-semibold text-dark-dirty">{formattedTotal}</span>
//       </div>

//       <div className="flex justify-between pt-[4px]">
//         <span className="text-sm text-black-burgundy">{t('label.vat')}</span>
//         <span className="text-sm font-medium text-black-burgundy">{formattedTaxAmount}</span>
//       </div>

//       <div className="flex justify-between pb-5 pt-[4px]">
//         <span className="text-sm text-black-burgundy">{t('label.totalWithoutVat')}</span>
//         <span className="text-sm font-medium text-black-burgundy">{formattedAmountWithoutTax}</span>
//       </div>
//     </>
//   );
// };

const InvoiceActions = ({
  invoice,
  creditNoteModalOpen,
  // debitNoteModalOpen,
  // downloadModalOpen,
}: MiniInvoiceCardProps & {
  creditNoteModalOpen: () => void;
  debitNoteModalOpen: () => void;
  downloadModalOpen: () => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const isSimpleOrReceipt = [Invoice_Document_Type.InvoiceReceipt, Invoice_Document_Type.SimplifiedInvoice].includes(
  //   invoice.documentType!
  // );

  const isCreditOrDebitNote = [Invoice_Document_Type.CreditNote, Invoice_Document_Type.DebitNote].includes(
    invoice.documentType!
  );

  const actions = [
    {
      name: 'creditNote',
      icon: <IconCreditNoteBlue className="fill-black" />,
      label: t('button.label.createCreditNote'),
      description: t('label.reduceTheAmount'),
      onClick: creditNoteModalOpen,
      isAvailable:
        (invoice.status === Invoice_Status.Issued && !isCreditOrDebitNote) ||
        (invoice.status === Invoice_Status.Paid &&
          [
            Invoice_Document_Type.Invoice,
            Invoice_Document_Type.InvoiceReceipt,
            Invoice_Document_Type.SimplifiedInvoice,
          ].includes(invoice.documentType!)),
    },
    // {
    //   name: 'debitNote',
    //   icon: <IconDebitNoteBlue className="fill-black" />,
    //   label: t('button.label.createDebitNote'),
    //   description: t('label.increaseTheAmount'),
    //   onClick: debitNoteModalOpen,
    //   isAvailable:
    //     (invoice.status === Invoice_Status.Issued && !isSimpleOrReceipt && !isCreditOrDebitNote) ||
    //     (invoice.status === Invoice_Status.Paid &&
    //       [Invoice_Document_Type.Invoice, Invoice_Document_Type.InvoiceReceipt].includes(invoice.documentType!)),
    // },
    // {
    //   name: 'download',
    //   icon: <IconSaveBlue />,
    //   label: t('button.label.downloadPdf'),
    //   onClick: () => {
    //     downloadModalOpen();
    //   },
    //   isAvailable: invoice.status !== Invoice_Status.Draft,
    // },
    {
      name: 'edit',
      icon: <IconEdit className="text-black" />,
      label: t('button.label.editDraft'),
      onClick: () => navigate(`/invoices/${invoice.id}/edit`),
      isAvailable: false,
    },
    // {
    //   name: 'share',
    //   icon: <IconShare />,
    //   label: t('label.share'),
    //   onClick: () => alert('Share is not implemented yet :('),
    //   isAvailable: true,
    // },
    // {
    //   name: 'repeat',
    //   icon: <IconRepeat />,
    //   label: t('label.repeatInvoice'),
    //   onClick: () => alert('Repeat is not implemented yet :('),
    //   isAvailable: true,
    // },
  ];

  // if (invoice.status === Invoice_Status.Issued) {
  //   <Button
  //     variant="default"
  //     onClick={creditNoteModalOpen}
  //     fullWidth
  //     leftSection={<IconPencilMinus size={14} stroke={1.5} />}
  //   >
  //     {t('button.label.creditNote')}
  //   </Button>;

  //   <Button
  //     variant="default"
  //     onClick={debitNoteModalOpen}
  //     fullWidth
  //     leftSection={<IconPencilPlus size={14} stroke={1.5} />}
  //   >
  //     {t('button.label.debitNote')}
  //   </Button>;
  // }

  const availableActions = actions.filter((action) => action.isAvailable);

  if (!availableActions.length) {
    return null;
  }

  return (
    <Card className="p-0">
      {availableActions.map((action) => (
        <div className="pb-3 last:pb-0" key={action.name}>
          <UnstyledButton
            onClick={action.onClick}
            variant="transparent"
            className="flex w-full flex-col items-center justify-center rounded-[20px] bg-white-vanilla py-3 hover:bg-angel-feather"
          >
            <div className="flex items-center justify-center space-x-1">
              {action.icon}
              <span className="text-[17px]">{action.label}</span>
            </div>
            <span className="text-center text-[14px] text-black-burgundy">{action.description}</span>
          </UnstyledButton>
        </div>
      ))}
    </Card>
  );
};

const InvoiceSidebar = ({ invoice }: MiniInvoiceCardProps) => {
  const navigate = useNavigate();
  const [markAsPaidModalOpened, { open: markAsPaidModalOpen, close: markAsPaidModalClose }] = useDisclosure(false);
  const [creditNoteModalOpened, { open: creditNoteModalOpen, close: creditNoteModalClose }] = useDisclosure(false);
  const [debitNoteModalOpened, { open: debitNoteModalOpen, close: debitNoteModalClose }] = useDisclosure(false);
  const [downloadModalOpened, { open: downloadModalOpen, close: downloadModalClose }] = useDisclosure(false);
  const [setupATConnectionModalOpened, setupATConnectionModal] = useDisclosure(false);

  const {
    issueDraftInvoice,
    issueDraftInvoiceLoading,
    deleteInvoice,
    deleteInvoiceLoading,
    markInvoicePaid,
    // markInvoicePaidLoading,
    cancelInvoice,
    cancelInvoiceLoading,
  } = useMiniInvoiceCardMutations(invoice.documentType, markAsPaidModalClose);

  const { summary } = invoice;
  const { t } = useTranslation();

  const handleEditDraftInvoice = () => {
    navigate(`/invoices/${invoice.id}/edit`);
  };

  const handleIssueDraftInvoice = () => {
    issueDraftInvoice({
      variables: {
        id: invoice.id,
      },
    });
  };

  const handleMarkInvoicePaid = (formValues: MarkAsPaidActionModalFormikValues) => {
    markInvoicePaid({
      variables: {
        markInvoicePaidId: invoice.id,
        payment: {
          date: dateToISOString(formValues.dateOfPayment || dayjs().toDate()),
          method: formValues.paymentMethod,
        },
      },
    });
  };

  const handleDeleteInvoice = () => {
    deleteInvoice({
      variables: {
        deleteInvoiceId: invoice.id,
      },
    });
  };

  const handleCancelInvoice = () => {
    cancelInvoice({
      variables: {
        cancelInvoiceId: invoice.id,
      },
    });
  };

  const handleSetupATConnection = () => {
    setupATConnectionModal.open();
  };

  // const isMutationLoading =
  //   issueDraftInvoiceLoading || markInvoicePaidLoading || deleteInvoiceLoading || cancelInvoiceLoading;

  const hasWithholding = invoice.summary?.withholdingTaxAmount ? invoice.summary?.withholdingTaxAmount > 0 : false;
  const total = convertPriceToFromCents(summary?.total || 0, true);
  const withholdingTaxAmount = invoice.summary?.withholdingTaxAmount || 0;
  const totalToPay = hasWithholding ? total - withholdingTaxAmount : total;
  const formattedTotal = currencyFormatter(totalToPay, invoice.currency || 'EUR');

  return (
    <>
      <LoadingOverlay visible={issueDraftInvoiceLoading || deleteInvoiceLoading || cancelInvoiceLoading} />
      <Card className="p-5 bg-white-vanilla">
        {invoice.status === Invoice_Status.Paid ? (
          <div
            className={cn(
              {
                'pb-0': invoice.status === Invoice_Status.Paid,
              },
              {
                'pb-4': invoice.status !== Invoice_Status.Paid,
              }
            )}
          >
            <div className="flex justify-between pb-1">
              <h1 className="text-[16px] font-bold text-black-magic">
                <span>{titleOfTotal(t, invoice.status, invoice.documentType!)}</span>
              </h1>
              <span className="text-[16px] font-bold text-black-magic">{formattedTotal}</span>
            </div>
            <div className="flex justify-between pb-0">
              <h1 className="text-[14px] font-normal text-black-burgundy">{t('label.paymentDate')}</h1>
              <span className="text-[14px] font-semibold text-black-magic">
                {dayjs(invoice.payment?.date).format(CALENDAR_FORMAT)}
              </span>
            </div>
            <div className="flex justify-between pb-2">
              <h1 className="text-[14px] font-normal text-black-burgundy">{t('label.paymentMethod')}</h1>
              <span className="text-end text-[14px] font-semibold text-black-magic">
                {invoice.payment?.method ? t(`invoice.method.${invoice.payment.method}`) : ''}
              </span>
            </div>
          </div>
        ) : (
          <div className="flex justify-between pb-2">
            <h1 className="text-[16px] font-bold text-black-magic">
              {titleOfTotal(t, invoice.status, invoice.documentType!)}
            </h1>
            <span className="text-[16px] font-bold text-black-magic">{formattedTotal}</span>
          </div>
        )}

        {/* <InvoiceSummary invoice={invoice} /> */}
        <InvoiceSummaryActions
          invoice={invoice}
          status={invoice.status}
          documentType={invoice.documentType!}
          markAsPaidModalOpen={markAsPaidModalOpen}
          handleEditDraftInvoice={handleEditDraftInvoice}
          handleIssueDraftInvoice={handleIssueDraftInvoice}
          handleDeleteInvoice={handleDeleteInvoice}
          handleCancelInvoice={handleCancelInvoice}
          handleSetupATConnection={handleSetupATConnection}
        />
      </Card>
      <Space className="pt-3 xs:pt-8" />
      <InvoiceActions
        invoice={invoice}
        creditNoteModalOpen={creditNoteModalOpen}
        debitNoteModalOpen={debitNoteModalOpen}
        downloadModalOpen={downloadModalOpen}
      />
      {markAsPaidModalOpened && (
        <MarkAsPaidActionModal
          invoice={invoice}
          opened={markAsPaidModalOpened}
          title={t('label.markAsPaid')}
          close={markAsPaidModalClose}
          invoiceTotal={total}
          status={invoice.status}
          handleMarkInvoicePaid={handleMarkInvoicePaid}
        />
      )}
      {creditNoteModalOpened && (
        <CreditNoteActionModal
          opened={creditNoteModalOpened}
          close={creditNoteModalClose}
          title={t('label.creditNote')}
          invoice={invoice}
        />
      )}
      {debitNoteModalOpened && (
        <DebitNoteActionModal
          opened={debitNoteModalOpened}
          close={debitNoteModalClose}
          title={t('label.debitNote')}
          invoice={invoice}
        />
      )}
      {downloadModalOpened && (
        <DownloadPdfActionModal
          opened={downloadModalOpened}
          close={downloadModalClose}
          title={t('label.downloadPdf')}
          invoice={invoice}
        />
      )}
      {setupATConnectionModalOpened && (
        <CustomModal
          title={t('label.atCommunication')}
          isOpened={setupATConnectionModalOpened}
          handleClose={setupATConnectionModal.close}
          size={500}
        >
          <ConnectWithATOptions onConnectionAdded={setupATConnectionModal.close} />
        </CustomModal>
      )}
    </>
  );
};

export default InvoiceSidebar;
