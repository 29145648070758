import { TFunction } from 'i18next';
import { Invoice_Status } from '@/graphql-types';

export const translateInvoiceStatus = (status: Invoice_Status, t: TFunction): string => {
  switch (status) {
    case Invoice_Status.Canceled:
      return t('status.canceled');
    case Invoice_Status.Created:
      return t('status.created');
    case Invoice_Status.Draft:
      return t('status.draft');
    case Invoice_Status.Issued:
      return t('status.unpaid');
    case Invoice_Status.Paid:
      return t('status.paid');
    case Invoice_Status.Processing:
      return t('status.processing');
  }
};
