import { useTranslation } from 'react-i18next';
import { Button, Card } from '@mantine/core';
import { InvoiceCardHeaderProps } from '../types';
import { translateInvoiceDocumentType } from '@/utils/translateInvoiceDocumentType';
import { useInvoiceQuery } from '@/graphql-operations';
import { showErrorToast } from '@/components/Toast';
import { useParams } from 'react-router-dom';
// import { StickySpy } from './StickySpy';
import { useNavigate } from 'react-router-dom';
import { Invoice_Document_Type, Invoice_Status } from '@/graphql-types';
import { cn, DATE_FORMAT } from '@/utils';
import { isOverdueInvoice } from '@/utils/dates';
import { useReactiveVar } from '@apollo/client';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { useMediaQuery } from '@mantine/hooks';
import InvoiceSyncWithATForm from '@/components/InvoiceSyncWithATForm/InvoiceSyncWithATForm';
import dayjs from 'dayjs';

const Header = ({ invoiceType }: InvoiceCardHeaderProps) => {
  const { t } = useTranslation();
  const { invoiceId } = useParams();
  const navigate = useNavigate();
  const tenant = useReactiveVar(tenantVar);
  const isSmScreen = useMediaQuery('(max-width: 575px)');

  const { data, loading, error } = useInvoiceQuery({
    variables: {
      invoiceId: invoiceId!,
    },
    skip: !invoiceId,
    onError: (error) => {
      showErrorToast({ title: t('error.gettingInvoice'), message: error.message });
    },
  });

  if (loading) return <div>{t('label.loading')}</div>;
  if (!data?.invoice) return <div>{t('error.invoiceDoesNotExist')}</div>;
  if (error) return <div>Error: {error.message}</div>;

  const { invoice } = data;

  const canBePaid = [
    Invoice_Document_Type.Invoice,
    Invoice_Document_Type.CreditNote,
    Invoice_Document_Type.DebitNote,
  ].includes(invoiceType);

  const translatedDocumentType = invoice!.documentType ? translateInvoiceDocumentType(invoice!.documentType, t) : '';
  const isOverdue = isOverdueInvoice(invoice.dueDate!, tenant!.timezone) && canBePaid;
  const statusLabels = {
    [Invoice_Status.Canceled]: t('status.canceled'),
    [Invoice_Status.Created]: t('status.created'),
    [Invoice_Status.Draft]: t('status.draft'),
    [Invoice_Status.Paid]: t('status.paid'),
    [Invoice_Status.Processing]: t('status.processing'),
    [Invoice_Status.Issued]: t('status.unpaid'),
  };

  const statusColors = {
    [Invoice_Status.Canceled]: '#FB9B88',
    [Invoice_Status.Created]: '#FFC281',
    [Invoice_Status.Draft]: '#E9E7E6',
    [Invoice_Status.Paid]: '#7FDB9E',
    [Invoice_Status.Processing]: '#FFC850',
    [Invoice_Status.Issued]: '#FFDA8A',
  };

  const isCreditNote = invoiceType === Invoice_Document_Type.CreditNote;
  const isDebitNote = invoiceType === Invoice_Document_Type.DebitNote;
  const isCreditOrDebitNote = isCreditNote || isDebitNote;

  let statusLabel;
  let backgroundColor;

  if (invoice.status === Invoice_Status.Canceled) {
    statusLabel = statusLabels[Invoice_Status.Canceled];
    backgroundColor = statusColors[Invoice_Status.Canceled];
  } else if (isCreditOrDebitNote && (invoice.status === Invoice_Status.Issued || isOverdue)) {
    statusLabel = t('status.issued');
    backgroundColor = statusColors[Invoice_Status.Issued];
  } else {
    statusLabel =
      isOverdue && invoice.status !== Invoice_Status.Draft && invoice.status !== Invoice_Status.Paid
        ? t('status.overdue')
        : statusLabels[invoice.status];
    backgroundColor =
      isOverdue && invoice.status !== Invoice_Status.Draft && invoice.status !== Invoice_Status.Paid
        ? '#ffa662'
        : statusColors[invoice.status];
  }

  return (
    <>
      {/* TODO: uncomment when we fix the lags */}
      {/* <StickySpy> */}
      {/* data-[react-is-sticky=true]:h-[100px] data-[react-is-sticky=true]:w-full data-[react-is-sticky=true]:rounded-none data-[react-is-sticky=true]:rounded-b-[20px] data-[react-is-sticky=true]:py-[10px] */}
      <Card
        className="top-0 z-50 mx-auto min-h-[100px] rounded-b-[0px] rounded-t-[40px] py-5 sm:px-[30px] lg:h-40 lg:px-11 lg:pt-[30px]"
        style={{ backgroundColor }}
      >
        <div className="lg:flex lg:justify-between">
          <div
            className={cn('text-center lg:text-start', {
              'lg:pt-5': !invoice.syncWithAt,
            })}
          >
            <span
              className={cn('font-semibold text-black-magic', {
                'text-[22px] sm:text-[22px] lg:text-[32px]': invoice.status === Invoice_Status.Draft,
                'text-[12px] sm:text-[16px] lg:text-sm': invoice.status !== Invoice_Status.Draft,
              })}
              style={invoice.status === Invoice_Status.Draft && !isSmScreen ? { fontFamily: 'Artex, sans-serif' } : {}}
            >
              {statusLabel} {translatedDocumentType}
            </span>
            <br />
            <span
              style={!isSmScreen ? { fontFamily: 'Artex, sans-serif' } : undefined}
              className="text-[20px] font-semibold text-black-magic data-[react-is-sticky=true]:text-[20px] sm:text-[20px] lg:text-[30px]"
            >
              {invoice.number}
            </span>
            <br />
            {invoice.syncWithAt && (
              <InvoiceSyncWithATForm
                invoiceId={invoice.id}
                status={invoice.status}
                dueDate={invoice.dueDate?.toString()}
                invoiceType={invoiceType}
                syncWithAt={invoice.syncWithAt}
                syncWithAtDate={dayjs(invoice.syncWithAt.updatedAt).format(DATE_FORMAT)}
              />
            )}
          </div>
          <div className="relative z-10 hidden lg:block">
            <img className="w-[50] sm:w-[232px] md:w-[250px] lg:w-[343px]" src="/file-pic.png" alt="File" />
            <div className="absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
              <Button
                onClick={() => navigate(`/invoices/${invoice.id}/preview`)}
                className="hover:shadow-[light-orange/70 h-10 rounded-[18px] bg-light-brand shadow-lg shadow-light-brand/70 hover:bg-light-orange"
              >
                {t('button.label.preview')}
              </Button>
            </div>
          </div>
        </div>
      </Card>
      {/* </StickySpy> */}

      <div className="pb-3 xs:pb-8" />
    </>
  );
};

export default Header;
