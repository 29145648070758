import 'dayjs/locale/pt';

import { Tooltip } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconInfoCircle } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikSelect } from '@/components/FormikWrapped';
import FormikDateInput from '@/components/FormikWrapped/FormikDateInput';
import { Invoice_Document_Type } from '@/graphql-types';
import { cn } from '@/utils';
import { getMinDate5WorkingDaysInPast } from '@/utils/dates';

import { CreateInvoiceFormValues, InvoiceDueDateOptions } from '../types';

// type FormSettingsProps = {
//   invoiceDocumentType?: Invoice_Document_Type;
// };

const FormSettings = () => {
  const {
    setFieldValue,
    values: { dueDate, invoiceType, invoiceDate },
    errors,
  } = useFormikContext<CreateInvoiceFormValues>();
  const isDebitNote = invoiceType === Invoice_Document_Type.DebitNote;

  const { t } = useTranslation();
  const [tooltipOpened, setTooltipOpened] = useState(false);
  const isSmScreen = useMediaQuery('(max-width: 575px)');
  const tooltipRef = useRef<HTMLDivElement>(null);

  const dueDateOptions = [
    { value: InvoiceDueDateOptions.InAWeek, label: t('option.inAWeek') },
    { value: InvoiceDueDateOptions.InTwoWeeks, label: t('option.inTwoWeeks') },
    { value: InvoiceDueDateOptions.InAMonth, label: t('option.inAMonth') },
  ];

  const shouldShowPaymentMethod =
    [Invoice_Document_Type.SimplifiedInvoice, Invoice_Document_Type.InvoiceReceipt].includes(invoiceType) &&
    !isDebitNote;

  const handleTooltipClick = () => {
    if (isSmScreen) {
      setTooltipOpened((o) => !o);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
      setTooltipOpened(false);
    }
  };

  const handleTooltipMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (tooltipOpened) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tooltipOpened]);

  return (
    <div className="flex flex-col gap-2 grow xs:flex-row">
      {!isDebitNote && (
        <div className="xs:w-[50%]">
          <FormikDateInput
            name="invoiceDate"
            value={typeof invoiceDate === 'string' ? dayjs().toDate() : dayjs(invoiceDate).toDate()}
            minDate={getMinDate5WorkingDaysInPast(dayjs())}
            maxDate={dayjs().toDate()}
            label={shouldShowPaymentMethod ? t('label.paymentDate') : t('label.issueDate')}
            rightSection={
              <Tooltip
                label={t('tooltip.only5DaysInPast')}
                color="#F9F7F4"
                position="bottom"
                withArrow
                classNames={{
                  tooltip: 'text-black-magic',
                }}
                opened={isSmScreen ? tooltipOpened : undefined}
                ref={tooltipRef}
                onMouseDown={handleTooltipMouseDown}
              >
                <IconInfoCircle stroke={1} onClick={handleTooltipClick} />
              </Tooltip>
            }
          />
        </div>
      )}
      {invoiceType === Invoice_Document_Type.Invoice || isDebitNote ? (
        <div
          className={cn(
            `border-t last-of-type:border-0`,
            {
              'w-[100%]': isDebitNote,
              'mt-4': isDebitNote,
            },
            {
              'xs:w-[50%]': !isDebitNote,
            }
          )}
        >
          <FormikSelect
            label={t('label.dueDate')}
            key="invoice:dueDate"
            name="dueDate"
            data={dueDateOptions}
            value={dueDate ? dueDate.toString() : ''}
            onChange={(value) => {
              setFieldValue('dueDate', value);
            }}
          />
        </div>
      ) : (
        shouldShowPaymentMethod && (
          <div className="h-16 xs:w-[50%]">
            <FormikSelect
              key="invoice:paymentMethod"
              name="paymentMethod"
              label={t('markAsPaidActionModal.label.paymentMethodUsed')}
              size="md"
              data={[
                { value: 'card', label: t('option.card') },
                { value: 'cash', label: t('option.cash') },
                { value: 'bankTransfer', label: t('option.bankTransfer') },
                { value: 'mbWay', label: t('option.mbWay') },
                { value: 'spin', label: t('option.spin') },
                { value: 'multibanco', label: t('option.multibanco') },
                { value: 'other', label: t('option.other') },
              ]}
              error={errors.paymentMethod}
            />
          </div>
        )
      )}
      {/* {!isDebitNote && (
        <Group
          justify="space-between"
           className="h-16 border-t"
        >
          <Text fw={500} size="sm" lh={1} c="#3D3B36">
            {t('label.recurringInvoice')}
          </Text>
          <Switch
            error={errors.scheduled}
            checked={scheduled}
            color="#438BFA"
            size="md"
            onChange={(event) => setFieldValue('scheduled', event.currentTarget.checked)}
          />
        </Group>
      )} */}
    </div>
  );
};

export default FormSettings;
