import * as Yup from 'yup';
import { TFunction } from 'i18next';
// import { testPTIBAN } from '@/utils/iban';
import { PT_POSTAL_CODE_REGEX } from '@/utils';

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    territory: Yup.string().required(t('validation.territoryRequired')).trim(),
    city: Yup.string().required(t('validation.cityRequired')).trim().max(50, t('validation.cityTooLong')),
    address: Yup.string().required(t('validation.addressRequired')).trim().max(210, t('validation.addressTooLong')),
    postalCode: Yup.string()
      .required(t('validation.postalCodeRequired'))
      .matches(PT_POSTAL_CODE_REGEX, t('validation.postalCodeFormat')),
    // iban: Yup.string()
    //   .nullable()
    //   .test('pt-iban', t('validation.invalidIBANFormat'), (iban) => testPTIBAN(iban ? `PT${iban}` : '', 'PT')),
  });
