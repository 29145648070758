import { Customer, Discount_Type, Invoice_Document_Type, Item } from '@/graphql-types';
import { FormikProps } from 'formik';

export interface FormItem extends Item {
  quantity?: number;
  ref?: string;
}

export enum InvoiceActionType {
  draft = 'draft',
  issue = 'issue',
}

export type CreateInvoiceFormValues = {
  invoiceType: Invoice_Document_Type;
  invoiceFormCustomer: Customer | null;
  invoiceItems: FormItem[];
  notes: string;
  invoiceDate: Date | string | InvoiceDateOptions;
  dueDate: Date | string | InvoiceDueDateOptions;
  scheduled: boolean;
  paymentMethod: string;
  reason: string;
  reasonType: string;
  discountSwitch: boolean;
  discountType?: Discount_Type;
  discountPercent?: number;
  discountAmount?: number;
};

export enum InvoiceDateOptions {
  Immediately = 'Today',
  ChooseDate = 'Choose date',
}

export enum InvoiceDueDateOptions {
  InAWeek = 'In a week',
  InTwoWeeks = 'In two weeks',
  InAMonth = 'In a month',
}

export const SELECTION_PREVIEW_VARIANT = {
  EDIT: 'edit',
  REMOVE: 'remove',
  EDIT_REMOVE: 'edit-remove',
  NONE: 'none',
} as const;

export type SelectionPreviewVariant = (typeof SELECTION_PREVIEW_VARIANT)[keyof typeof SELECTION_PREVIEW_VARIANT];

export type HandlePreviewCalculationsProps = {
  invoiceType: Invoice_Document_Type;
  actionType: InvoiceActionType;
  newFormItemsValue: Partial<FormItem>[];
  selectedDsicountType?: Discount_Type;
  newDiscountPercentValue?: CreateInvoiceFormValues['discountPercent'];
  newDiscountAmountValue?: CreateInvoiceFormValues['discountAmount'];
  setFieldValue: FormikProps<CreateInvoiceFormValues>['setFieldValue'];
};
