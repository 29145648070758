import { Button, LoadingOverlay, UnstyledButton } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { saveAs } from 'file-saver';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconRadioButtonOff from '@/assets/icons/IconRadioButtonOff.svg?react';
import IconRadioButtonOn from '@/assets/icons/IconRadioButtonOn.svg?react';
import CustomModal from '@/components/CustomModal';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@/components/Drawer/Drawer';
import { showErrorToast } from '@/components/Toast';
import { useDownloadInvoicePdfLazyQuery } from '@/graphql-operations';
import { Invoice } from '@/graphql-types';
import { cn } from '@/utils';

type DownloadPdfActionProps = {
  opened: boolean;
  title: string;
  close: () => void;
  invoice: Invoice;
};

enum PdfType {
  Original = 'original',
  Duplicate = 'duplicate',
}

const DownloadPdfAction = ({ opened, close, title, invoice }: DownloadPdfActionProps) => {
  const isSmScreen = useMediaQuery('(max-width: 575px)');
  const { t } = useTranslation();
  const [pdfType, setPdfType] = React.useState<PdfType.Original | PdfType.Duplicate>(PdfType.Original);
  const [downloadInvoicePDF, { loading: loadingInvoicePDF }] = useDownloadInvoicePdfLazyQuery({});

  const isMobileScreen = () => {
    const userAgent = window.navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  };

  if (typeof isSmScreen === 'undefined') return <LoadingOverlay visible />;

  const getName = (name?: string) => (name ? name.replace(/\//g, '_').replace(/\s/g, '_') : 'invoice.pdf');
  const downloadPDFFile = async () => {
    const data = await downloadInvoicePDF({
      fetchPolicy: 'network-only',
      variables: {
        id: invoice.id,
        isDuplicate: pdfType === PdfType.Duplicate,
      },
    });

    if (!data.data?.downloadInvoicePDF?.url) {
      showErrorToast({ message: t('error.error') });
      return;
    }

    const downloadURL = new URL(data.data.downloadInvoicePDF.url);
    const fileName = getName(data.data.downloadInvoicePDF.name);

    downloadURL.searchParams.append('response-content-disposition', `attachment; filename=${fileName}`);
    downloadURL.searchParams.append('response-content-type', 'application/octet-stream');

    if (isMobileScreen()) {
      try {
        const isFileSaverSupported = !!new Blob();

        if (!isFileSaverSupported) {
          throw new Error('File saver is not supported');
        }

        saveAs(downloadURL.toString(), fileName);
      } catch (e) {
        console.error(e);
        window.location.href = downloadURL.toString();
      }
    } else {
      const anchor = document.createElement('a');
      anchor.href = downloadURL.toString();
      anchor.download = fileName;
      anchor.click();
    }
  };

  if (isSmScreen) {
    return (
      <Drawer open={opened} onClose={close}>
        <DrawerContent>
          <LoadingOverlay visible={loadingInvoicePDF} />
          <div className="mx-auto w-full max-w-sm">
            <DrawerHeader>
              <DrawerTitle>{title}</DrawerTitle>
              <DrawerDescription>{invoice.number}</DrawerDescription>
            </DrawerHeader>
            <div className="grid gap-y-2 px-4 pt-1">
              <UnstyledButton
                className={cn(
                  `rounded-[20px] px-4 py-[14px] outline`,
                  {
                    'outline-1 outline-dark-braun': pdfType !== PdfType.Original,
                  },
                  {
                    'outline-2 outline-moroccan-day': pdfType === PdfType.Original,
                  }
                )}
                onClick={() => setPdfType(PdfType.Original)}
              >
                <div className="flex items-center">
                  {pdfType === PdfType.Original ? (
                    <IconRadioButtonOn className="fill-moroccan-blue" />
                  ) : (
                    <IconRadioButtonOff />
                  )}
                  <div className="ml-3 flex flex-col">
                    <span className="text-[17px] font-normal">Orignial</span>
                  </div>
                </div>
              </UnstyledButton>
              <UnstyledButton
                className={cn(
                  `rounded-[20px] px-4 py-[14px] outline`,
                  {
                    'outline-1 outline-dark-braun': pdfType !== PdfType.Duplicate,
                  },
                  {
                    'outline-2 outline-moroccan-day': pdfType === PdfType.Duplicate,
                  }
                )}
                onClick={() => setPdfType(PdfType.Duplicate)}
              >
                <div className="flex items-center">
                  {pdfType === PdfType.Duplicate ? (
                    <IconRadioButtonOn className="fill-moroccan-blue" />
                  ) : (
                    <IconRadioButtonOff />
                  )}
                  <div className="ml-3 flex flex-col">
                    <span className="text-[17px] font-normal">Duplicate</span>
                  </div>
                </div>
              </UnstyledButton>
            </div>

            <DrawerFooter>
              <Button
                className="mt-4 h-12 w-full rounded-[20px] bg-light-brand text-[17px] font-semibold hover:bg-light-orange"
                onClick={downloadPDFFile}
              >
                {t('button.label.download')}
              </Button>
            </DrawerFooter>
          </div>
        </DrawerContent>
      </Drawer>
    );
  }

  return (
    <CustomModal isOpened={opened} handleClose={close} title={title}>
      <LoadingOverlay visible={loadingInvoicePDF} />
      <div className="text-center">
        <span className="text-[18px] font-medium text-black-magic">{invoice.number}</span>
        <div className="grid gap-y-2 pt-4">
          <UnstyledButton
            className={cn(
              `rounded-[20px] px-4 py-[14px] outline`,
              {
                'outline-1 outline-dark-braun': pdfType !== PdfType.Original,
              },
              {
                'outline-2 outline-moroccan-day': pdfType === PdfType.Original,
              }
            )}
            onClick={() => setPdfType(PdfType.Original)}
          >
            <div className="flex items-center">
              {pdfType === PdfType.Original ? (
                <IconRadioButtonOn className="fill-moroccan-blue" />
              ) : (
                <IconRadioButtonOff />
              )}
              <div className="ml-3 flex flex-col">
                <span className="text-[17px] font-normal">{t('button.label.original')}</span>
              </div>
            </div>
          </UnstyledButton>
          <UnstyledButton
            className={cn(
              `rounded-[20px] px-4 py-[14px] outline`,
              {
                'outline-1 outline-dark-braun': pdfType !== PdfType.Duplicate,
              },
              {
                'outline-2 outline-moroccan-day': pdfType === PdfType.Duplicate,
              }
            )}
            onClick={() => setPdfType(PdfType.Duplicate)}
          >
            <div className="flex items-center">
              {pdfType === PdfType.Duplicate ? (
                <IconRadioButtonOn className="fill-moroccan-blue" />
              ) : (
                <IconRadioButtonOff />
              )}
              <div className="ml-3 flex flex-col">
                <span className="text-[17px] font-normal">{t('button.label.duplicate')}</span>
              </div>
            </div>
          </UnstyledButton>
        </div>
      </div>
      <Button
        className="mt-8 h-12 w-full rounded-[20px] bg-light-brand text-[17px] font-semibold hover:bg-light-orange"
        onClick={downloadPDFFile}
      >
        {t('button.label.download')}
      </Button>
    </CustomModal>
  );
};

export default DownloadPdfAction;
