import { PropsWithChildren } from 'react';
import { UnstyledButton, UnstyledButtonProps, Text } from '@mantine/core';
import { cn } from '@/utils';

export type DashedButtonProps = PropsWithChildren<
  UnstyledButtonProps & {
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  }
>;

export const DashedButton = ({ children, className, ...props }: DashedButtonProps) => {
  return (
    <UnstyledButton
      className={cn(
        'h-[66px] w-full rounded-[20px] border-2 border-dashed border-light-sky px-6 py-[18px] text-center',
        className
      )}
      {...props}
    >
      <Text className="text-[17px] font-semibold text-moroccan-blue">{children}</Text>
    </UnstyledButton>
  );
};
