import { Modal, ModalProps, ScrollArea } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
// import { useMediaQuery } from '@mantine/hooks';
// import { MobileDrawer } from '@/components/Invoice/Create/parts/MobileDrawer';

export type CustomModalProps = {
  isOpened: boolean;
  handleClose: () => void;
  // showDrawerOnMobile?: boolean;
  onOpenChange?: (opened: boolean) => void;
} & Omit<ModalProps, 'opened' | 'onClose'>;

const CustomModal = ({
  isOpened,
  handleClose,
  // onOpenChange,
  title,
  children,
  // showDrawerOnMobile,
  ...rest
}: CustomModalProps) => {
  const isSmScreen = useMediaQuery('(max-width: 575px)');

  // if (typeof isSmScreen === 'undefined') {
  //   return null;
  // }

  // if (isSmScreen && showDrawerOnMobile) {
  //   return (
  //     <MobileDrawer title={title} opened={isOpened} close={handleClose} onOpenChange={onOpenChange}>
  //       {children}
  //     </MobileDrawer>
  //   );
  // }

  return (
    <Modal
      opened={isOpened}
      onClose={handleClose}
      title={title}
      scrollAreaComponent={ScrollArea.Autosize}
      centered
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      radius={24}
      padding={isSmScreen ? 16 : 32}
      classNames={{
        title: 'text-black-magic text-[18px] font-semibold text-center absolute break-words max-w-[190px] xs:max-w-max',
        header: 'relative flex justify-center items-center my-3 xs:my-2',
        close: 'bg-angel-feather rounded-[12px] w-[35px] h-[35px] text-black-magic absolute right-5',
      }}
      {...rest}
      // fullScreen={false}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
