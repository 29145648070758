import { cn } from '@/utils';
import { Invoice_Document_Type, Invoice_Status, Invoice_Sync_With_At_Status, InvoiceSyncWithAt } from '@/graphql-types';
import { useTranslation } from 'react-i18next';
import { isOverdueInvoice } from '@/utils/dates';
import { useReactiveVar } from '@apollo/client';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import IconWatch from '@/assets/icons/IconWatch.svg?react';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useState } from 'react';
import RetryConnectATForm from '../RetryConnectATModal/RetryConnectATForm';
import CustomModal from '../CustomModal';

type InvoiceSyncWithATFormProps = {
  invoiceId: string;
  status: Invoice_Status;
  dueDate?: string;
  invoiceType: Invoice_Document_Type;
  syncWithAt: InvoiceSyncWithAt;
  syncWithAtDate: string;
};

const InvoiceSyncWithATForm = ({
  status,
  dueDate,
  invoiceType,
  invoiceId,
  syncWithAt,
  syncWithAtDate,
}: InvoiceSyncWithATFormProps) => {
  const { t } = useTranslation();
  const tenantObject = useReactiveVar(tenantVar);
  const isDraft = status === Invoice_Status.Draft;
  const isProcessing = status === Invoice_Status.Processing;
  const isPending = status === Invoice_Status.Created;
  const isPaid = status === Invoice_Status.Paid;
  const isCancelled = status === Invoice_Status.Canceled;
  const isUnpaid = status === Invoice_Status.Issued;

  const [opened, setOpened] = useState(false);

  const hasPastDueDate = dueDate ? isOverdueInvoice(dueDate, tenantObject!.timezone) : false;
  const canBePaid = [
    Invoice_Document_Type.Invoice,
    Invoice_Document_Type.CreditNote,
    Invoice_Document_Type.DebitNote,
  ].includes(invoiceType);

  const isOverdue = hasPastDueDate && !isDraft && !isPaid && canBePaid;
  const containerClasses = cn('inline-block rounded-[12px] px-3 py-2 cursor-pointer active:translate-y-[1px]', {
    'bg-light-green-deep': isPaid,
    'bg-peach-orange': isPending,
    'bg-salmon-pink': isCancelled,
    'bg-light-yellow': isUnpaid || isProcessing,
    'bg-coral-orange': isOverdue,
  });

  return (
    <>
      <button className={containerClasses} onClick={() => setOpened(true)}>
        <span className="whitespace-nowrap text-[14px] font-semibold">
          {syncWithAt.status === Invoice_Sync_With_At_Status.Processing && (
            <div className="flex gap-1">
              <IconWatch className="my-auto fill-black" />
              {t('label.processingSyncWithAT')}: {syncWithAtDate}
            </div>
          )}
          {syncWithAt.status === Invoice_Sync_With_At_Status.Synced && (
            <div className="flex gap-1">
              <IconCheck className="my-auto w-[16px]" />
              {t('label.syncedWithAT')}: {syncWithAtDate}
            </div>
          )}
          {syncWithAt.status === Invoice_Sync_With_At_Status.Failed && (
            <div className="flex gap-1">
              <IconX className="my-auto w-[16px]" />
              {t('label.failedToSyncWithAT')}: {syncWithAtDate}
            </div>
          )}
        </span>
      </button>
      <CustomModal
        isOpened={opened}
        handleClose={() => setOpened(false)}
        title={t('modal.syncWithAT.title')}
        onOpenChange={setOpened}
      >
        <RetryConnectATForm syncWithAt={syncWithAt} id={invoiceId} onRetry={() => setOpened(false)} />
      </CustomModal>
    </>
  );
};

export default InvoiceSyncWithATForm;
