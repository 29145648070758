import _compact from 'lodash/compact';
import _uniq from 'lodash/uniq';

import { i18n } from '@/components/I18N';
import { Invoice, Item_Vat_Rate, TaxExemptionReason, Tenant, Tenant_Type } from '@/graphql-types';

import { getCountryInPortuguese } from './getCountryInPortuguese';
import { getVatExemptionReasonsForPdf } from './getVatExemptionReasonOptions';

interface getTaxDetailsProps {
  invoice?: Invoice;
  tenant?: Tenant;
  taxExemptionReasons?: (TaxExemptionReason | undefined)[];
}

export const getTaxDetails = ({ invoice, tenant, taxExemptionReasons }: getTaxDetailsProps) => {
  const reducedTax = invoice?.summary?.tax?.find((tax) => tax?.name === 'Reduced');
  const intermediaTax = invoice?.summary?.tax?.find((tax) => tax?.name === 'Intermediate');
  const normalTax = invoice?.summary?.tax?.find((tax) => tax?.name === 'Normal');
  const exemptTax = invoice?.summary?.tax?.find((tax) => tax?.name === 'Exempt');
  const invoiceIssuer = invoice?.issuer?.data || tenant;
  const isTenantTypeCompany = invoiceIssuer?.type === Tenant_Type.Company;

  const customerAddress = _compact([
    invoice?.customer?.data?.address,
    invoice?.customer?.data?.postalCode,
    invoice?.customer?.data?.city,
    getCountryInPortuguese(invoice?.customer?.data?.country ?? ''),
  ]).join(', ');

  const vendorAddress = _compact([
    invoiceIssuer?.address,
    invoiceIssuer?.postalCode,
    invoiceIssuer?.city,
    getCountryInPortuguese(invoiceIssuer?.country ?? ''),
  ]).join(', ');

  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const reasons = getVatExemptionReasonsForPdf(taxExemptionReasons);

  const incidenceExempt = exemptTax ? exemptTax.incidence! : undefined;
  const incidenceReduced = reducedTax ? reducedTax.incidence! : undefined;
  const incidenceIntermedia = intermediaTax ? intermediaTax.incidence! : undefined;
  const incidenceNormal = normalTax ? normalTax.incidence! : undefined;

  const totalExemptTax = exemptTax ? exemptTax.amount! : undefined;
  const totalReduced = reducedTax ? reducedTax.amount! : undefined;
  const totalIntermedia = intermediaTax ? intermediaTax.amount! : undefined;
  const totalNormal = normalTax ? normalTax.amount! : undefined;

  const amountTotal = invoice?.summary?.total;
  const totalVat = invoice?.summary?.taxAmount;

  const subtotal = invoice?.summary?.amountWithoutTaxWithoutDiscount;

  const vatExemptionReasons = _uniq(
    invoice?.items
      ?.filter(
        (item) => item?.data?.vatRate === Item_Vat_Rate.Exempt || item?.data?.vatRate === Item_Vat_Rate.Reversecharge
      )
      .map((item) => item?.data?.vatExemptionReason)
  );

  const vatExemptions = vatExemptionReasons?.map((code, index) => {
    const reasonItem = taxExemptionReasons?.find((reason) => reason?.id === code);
    const lang = i18n.language as 'pt' | 'en';
    const description = reasonItem?.description ? reasonItem.description[lang] : '';

    return {
      items:
        invoice?.items
          ?.filter(
            (item) =>
              (item?.data?.vatRate === Item_Vat_Rate.Exempt || item?.data?.vatRate === Item_Vat_Rate.Reversecharge) &&
              item?.data?.vatExemptionReason === code
          )
          .map((item) => item?.id as string) || [],
      id: letters[index],
      reason: `${code}: ${reasonItem?.label}`,
      description,
    };
  });

  return {
    reducedTax,
    intermediaTax,
    normalTax,
    exemptTax,
    isTenantTypeCompany,
    customerAddress,
    vendorAddress,
    letters,
    reasons,
    incidenceExempt,
    incidenceReduced,
    incidenceIntermedia,
    incidenceNormal,
    totalExemptTax,
    totalReduced,
    totalIntermedia,
    totalNormal,
    amountTotal,
    totalVat,
    subtotal,
    vatExemptionReasons,
    vatExemptions,
  };
};
