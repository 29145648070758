import dayjs from 'dayjs';
import { InvoiceDateOptions, InvoiceDueDateOptions } from '../types';
import { Invoice } from '@/graphql-types';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

type GetIncomingInvoiceDateProps = {
  isCreditNote: boolean;
  isDebitNote: boolean;
  isDraftInvoice: boolean;
  invoiceIssueDate: Invoice['date'];
};

type GetIncomingInvoiceDueDateProps = {
  isCreditNote: boolean;
  isDraftInvoice: boolean;
  invoiceDueDate: Invoice['dueDate'];
  invoiceIssueDate: Invoice['date'];
};

const getDueDateDifference = (invoiceDate: Date, dueDate: Date) => {
  const start = dayjs(invoiceDate);
  const end = dayjs(dueDate);

  const diffInDays = end.diff(start, 'day');

  let result: string;

  if (diffInDays === 7) {
    result = InvoiceDueDateOptions.InAWeek;
  } else if (diffInDays === 14) {
    result = InvoiceDueDateOptions.InTwoWeeks;
  } else if (diffInDays >= 28 && diffInDays < 32) {
    result = InvoiceDueDateOptions.InAMonth;
  } else {
    result = dueDate.toISOString();
  }

  return result;
};

export const getIncomingInvoiceDate = ({
  isCreditNote,
  isDebitNote,
  isDraftInvoice,
  invoiceIssueDate,
}: GetIncomingInvoiceDateProps) => {
  if (isCreditNote || isDebitNote) {
    return invoiceIssueDate as Date;
  }

  if (isDraftInvoice && invoiceIssueDate) {
    return dayjs(invoiceIssueDate).toDate();
  }

  return InvoiceDateOptions.Immediately;
};

export const getIncomingInvoiceDueDate = ({
  isCreditNote,
  isDraftInvoice,
  invoiceDueDate,
  invoiceIssueDate,
}: GetIncomingInvoiceDueDateProps) => {
  if (isCreditNote) {
    return invoiceDueDate as Date;
  }

  if (isDraftInvoice && invoiceDueDate && invoiceIssueDate) {
    return getDueDateDifference(dayjs(invoiceIssueDate).toDate(), dayjs(invoiceDueDate).toDate());
  }

  return InvoiceDueDateOptions.InAWeek;
};
