import dayjs from 'dayjs';
import { CreateInvoiceFormValues, InvoiceDueDateOptions } from '@/components/Invoice/Create/types';
import { Invoice, Customer, InvoiceItem, Item, Item_Type, Item_Unit_Type, Item_Vat_Rate } from '@/graphql-types';
import { setDateToISONoon } from './dates';

export interface ExtendedItem extends Item {
  ref?: string;
  vatRate?: Item_Vat_Rate;
  quantity?: number;
}

export const normilizeInvoiceCustomerToCustomer = (invoiceCustomer: Invoice['customer']): Customer | null => {
  if (!invoiceCustomer) return null;

  return {
    id: invoiceCustomer?.ref || '',
    name: invoiceCustomer?.data?.name || '',
    email: invoiceCustomer?.data?.email || '',
    phone: invoiceCustomer?.data?.phone || '',
    country: invoiceCustomer?.data?.country || '',
    taxpayerNumber: invoiceCustomer?.data?.taxpayerNumber || '',
    city: invoiceCustomer?.data?.city || '',
    postalCode: invoiceCustomer?.data?.postalCode || '',
    address: invoiceCustomer?.data?.address || '',
  };
};

export const normalizeInvoiceItemToItem = (invoiceItem: InvoiceItem): ExtendedItem => {
  return {
    id: invoiceItem?.id || '',
    type: invoiceItem?.data?.type || Item_Type.Service,
    name: invoiceItem?.data?.name || '',
    taxRate: invoiceItem?.data?.taxRate || 0,
    vatRate: invoiceItem?.data?.vatRate || undefined,
    vatExemptionReason: invoiceItem?.data?.vatExemptionReason || undefined,
    unitPrice: invoiceItem?.data?.unitPrice || 0,
    quantity: invoiceItem?.meta?.quantity || 0,
    unitType: invoiceItem?.data?.unitType || Item_Unit_Type.Unit,
    withholdingTaxAvailable: invoiceItem?.data?.withholdingTaxAvailable,
    withholdingTaxPercent: invoiceItem?.data?.withholdingTaxPercent,
    withholdingTaxReason: invoiceItem?.data?.withholdingTaxReason,
    withholdingTaxType: invoiceItem?.data?.withholdingTaxType,
    ref: invoiceItem?.ref || '',
  };
};

export const normilizeInvoiceDate = (
  invoiceDate: CreateInvoiceFormValues['invoiceDate'],
  isCreditOrDebitNote?: boolean
): string => {
  if (isCreditOrDebitNote) return setDateToISONoon();

  if (dayjs(invoiceDate).isValid()) return setDateToISONoon(invoiceDate);

  return setDateToISONoon();
};

export const dateToISOString = (date: Date): string => {
  if (!dayjs(date).isValid()) return setDateToISONoon();

  return setDateToISONoon(date);
};

export const normilizeInvoiceDueDate = (
  dueDate: CreateInvoiceFormValues['dueDate'],
  invoiceDate: CreateInvoiceFormValues['invoiceDate'],
  isCreditNote?: boolean,
  isDebitNote?: boolean
) => {
  if (isCreditNote) return undefined;

  const date = normilizeInvoiceDate(isDebitNote ? dueDate : invoiceDate);

  if (dueDate === InvoiceDueDateOptions.InTwoWeeks) return setDateToISONoon(dayjs(date).add(2, 'week'));
  if (dueDate === InvoiceDueDateOptions.InAMonth) return setDateToISONoon(dayjs(date).add(1, 'month'));
  if (dueDate === InvoiceDueDateOptions.InAWeek) return setDateToISONoon(dayjs(date).add(1, 'week'));
  if (dayjs(dueDate).isValid()) return setDateToISONoon(dueDate);

  return setDateToISONoon(dayjs(date).add(1, 'week'));
};
