import { useTranslation } from '@/components/I18N';
import { cn } from '@/utils';
import { Invoice, Invoice_Status } from '@/graphql/types';
import { Button } from '@/components/ButtonVariants';
// import { useMediaQuery } from '@mantine/hooks';

type FormActionButtonsProps = {
  wrapperClasses?: string;
  handleDraftSubmission: () => void;
  handleIssueSubmission: () => void;
  openDeleteConfirmationModal: () => void;
  invoice: Invoice;
};

const FormActionButtons = ({
  invoice,
  wrapperClasses,
  handleDraftSubmission,
  handleIssueSubmission,
  openDeleteConfirmationModal,
}: FormActionButtonsProps) => {
  const { t } = useTranslation();
  const isDraftInvoice = invoice?.status === Invoice_Status.Draft;
  // const isLgScreen = useMediaQuery('(max-width: 1023px)');

  return (
    <div className={cn(wrapperClasses, 'flex-wrap')}>
      {isDraftInvoice ? (
        <>
          <div className="flex w-full gap-2">
            <Button
              className="shrink grow"
              size="m"
              fullWidth
              variant="secondary"
              onClick={openDeleteConfirmationModal}
            >
              <span className="text-carmine-red">{t('button.label.delete')}</span>
            </Button>
            <Button className="shrink grow" size="m" variant="secondary" fullWidth onClick={handleDraftSubmission}>
              {t('button.label.save')}
            </Button>
          </div>
          <Button variant="primary" size="m" fullWidth onClick={handleIssueSubmission}>
            {t('button.label.issue')}
          </Button>
        </>
      ) : (
        <div className="flex w-full flex-wrap gap-2">
          <Button size="m" variant="secondary" className="min-w-[48%] shrink grow" onClick={handleDraftSubmission}>
            {t('button.label.saveDraft')}
          </Button>
          <Button size="m" variant="primary" className="min-w-[48%] shrink grow" onClick={handleIssueSubmission}>
            {t('button.label.issue')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default FormActionButtons;
