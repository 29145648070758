import React, { forwardRef } from 'react';
import { InputBase, InputBaseProps } from '@mantine/core';
import { useField, useFormikContext } from 'formik';
import classes from '../CustomInput.module.css';
import { IMaskInput } from 'react-imask';

export interface FormikInputProps extends InputBaseProps {
  name: string;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
  mask?: string;
  styles?: Record<string, string | number>;
  type?: HTMLInputElement['type'];
  onChange?: (value: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  customClassNames?: Record<string, string>;
  inputMode?: 'numeric' | 'decimal' | 'email' | 'none' | 'search' | 'tel' | 'text' | 'url';
  autocomplete?: 'off' | 'on';
  // TODO: decide how to add all the IMaskInputProps here based on the mask prop
  unmask?: boolean;
  controlled?: boolean;
  value?: string;
  readOnly?: boolean;
}

const FormikInput = forwardRef(
  (
    {
      name,
      // placeholder,
      label,
      component,
      mask,
      leftSection,
      rightSection,
      styles,
      inputContainer,
      onChange,
      onBlur,
      wrapperProps,
      customClassNames,
      autocomplete = 'off',
      controlled,
      ...rest
    }: FormikInputProps,
    ref
  ) => {
    const { errors, touched, setFieldValue } = useFormikContext<Record<string, unknown>>();
    const [field] = useField(name);
    const props = mask
      ? {
          // unmask: true,
          onAccept: (value: unknown) => {
            setFieldValue(name, value);
          },
          component: IMaskInput,
          // onChange: null,
          ...rest,
        }
      : { ...rest };

    const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
      // this means we use mask and should use onAccept instead of onChange
      if (mask) {
        return;
      }
      if (onChange) {
        onChange(event);
      } else {
        field.onChange(event);
      }
    };

    const mergedClasses = {
      ...classes,
      input: `${customClassNames?.input} ${classes.input}`,
    };

    const val = controlled ? rest.value : field.value;

    return (
      <InputBase
        {...styles}
        size={(styles?.size as string) || 'md'}
        value={field.value}
        label={val ? label : ''}
        // placeholder={placeholder}
        wrapperProps={wrapperProps}
        placeholder={label}
        name={name}
        id={name}
        onBlur={onBlur}
        onChange={handleOnChange}
        component={component}
        mask={mask}
        leftSection={leftSection}
        rightSection={rightSection}
        inputContainer={inputContainer}
        // classNames={rest.classNames}
        classNames={mergedClasses}
        error={errors[name] && touched[name] ? errors[name] : null}
        // {...rest}
        className="rounded-3xl xs:flex-grow"
        {...props}
        ref={ref}
        styles={{ label: { fontSize: '11px' } }}
        autoComplete={autocomplete}
      />
    );
  }
);

export default FormikInput;
