import { TaxExemptionReason } from '@/graphql-types';

export const getVatExemptionReasonsForPdf = (reasons?: (TaxExemptionReason | undefined)[]) => {
  return (reasons || []).map((reason) => ({ value: reason!.id, label: `${reason!.id} ${reason!.name}` }));
};

export const getVatExemptionReasonOptions = (reasons?: (TaxExemptionReason | undefined)[]) => {
  return (reasons || []).map((reason) => ({ value: reason!.id, label: `${reason!.id}: ${reason!.label}` }));
};

export const getVatExemptionReasonExtendedOptions = (reasons?: (TaxExemptionReason | undefined)[], lang?: string) => {
  return (reasons || []).map((reason) => ({
    value: reason!.id,
    label: `${reason!.id}: ${reason!.label}`,
    // @ts-expect-error
    description: reason?.description && lang ? reason.description[lang] : '',
  }));
};
