import { captureException, init, replayIntegration, setUser } from '@sentry/react';

import { CURRENT_TENANT_STORAGE_KEY } from '../constants';

const initSentry = () => {
  const currentTenantId = localStorage.getItem(CURRENT_TENANT_STORAGE_KEY);

  setUser({
    id: currentTenantId || undefined,
  });

  init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_ENV,
    release: import.meta.env.VITE_APP_VERSION,
    maxBreadcrumbs: 10,
    tracesSampleRate: 0,
    normalizeDepth: 5,
    integrations: [replayIntegration()],
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};

export const captureExceptionEvent = (error: unknown, tags: Record<string, string | number> = {}) => {
  captureException(error, {
    tags: {
      ...tags,
    },
  });
};

export default initSentry;
