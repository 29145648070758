import CustomModal from '@/components/CustomModal';
import NewInvoice from '@/components/Invoice/Create/NewInvoice';
import { Invoice, Invoice_Document_Type } from '@/graphql-types';

type CreditNoteActionProps = {
  opened: boolean;
  title: string;
  close: () => void;
  invoice: Invoice;
};

const CreditNoteAction = ({ opened, close, title, invoice }: CreditNoteActionProps) => {
  return (
    <CustomModal isOpened={opened} handleClose={close} title={title}>
      <NewInvoice
        strictDocumentType
        withPeview={false}
        invoice={invoice}
        invoiceDocumentType={Invoice_Document_Type.CreditNote}
      />
    </CustomModal>
  );
};

export default CreditNoteAction;
