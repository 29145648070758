import { useTranslation } from 'react-i18next';
import { Alert, Text } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { DATE_FORMAT_WITH_TIME } from '@/utils';
import dayjs from 'dayjs';
import { Invoice_Sync_With_At_Status, InvoiceSyncWithAt } from '@/graphql-types';
import { TenantDocument, TenantQuery, TenantQueryVariables, useRetrySyncWithAtMutation } from '@/graphql-operations';
import { useDisclosure } from '@mantine/hooks';
import CustomModal from '../CustomModal';
import { ConnectWithATOptions } from '../Settings/AtCommunication/Credentials/ConnectWithATOptions';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { useReactiveVar } from '@apollo/client';
import { client } from '@/config/ApolloClientProvider';
import { Button } from '../ButtonVariants';

const TablePreviewRow = ({ label, value }: { label: string; value: string | undefined }) =>
  !!value && (
    <div key={label} className="flex justify-between gap-4 py-4 last:pb-0">
      <Text className="text-sm font-semibold text-dark-bronze">{label}</Text>
      <Text className="text-end text-sm font-normal text-black-magic">{value}</Text>
    </div>
  );

export type RetryConnectATFormProps = {
  id: string;
  syncWithAt: InvoiceSyncWithAt;
  onRetry?: () => void;
};

const RetryConnectATForm = ({ id, syncWithAt, onRetry }: RetryConnectATFormProps) => {
  const { t } = useTranslation();
  const tenantObject = useReactiveVar(tenantVar);
  const [retry, { loading }] = useRetrySyncWithAtMutation();
  const [setupATConnectionModalOpened, setupATConnectionModal] = useDisclosure(false);

  const handleSetupATConnection = () => {
    setupATConnectionModal.open();
  };

  const handleRetry = async () => {
    if (tenantObject?.credentials?.verified === true) {
      await retry({
        variables: { retrySyncWithAtId: id },
      });
      if (typeof onRetry === 'function') {
        onRetry();
      }
    } else {
      const res = await client.query<TenantQuery, TenantQueryVariables>({
        query: TenantDocument,
        variables: { tenantId: tenantObject!.id },
        fetchPolicy: 'network-only',
      });

      const updatedTenant = res?.data?.tenant;

      if (updatedTenant) {
        tenantVar(updatedTenant);

        if (tenantVar()?.credentials?.verified === true) {
          await retry({
            variables: { retrySyncWithAtId: id },
          });
          if (typeof onRetry === 'function') {
            onRetry();
          }
        } else {
          handleSetupATConnection();
        }
      }
    }
  };

  return (
    <div>
      {syncWithAt?.status === Invoice_Sync_With_At_Status.Failed && (
        <Alert
          variant="light"
          className="bg-light-silver-gray text-light-pink"
          radius="lg"
          title={t('label.failedToSyncWithAT')}
          icon={<IconX color="#FB9B88" />}
        />
      )}

      <div className="divide-y divide-solid divide-silver-vanilla">
        <TablePreviewRow label={t('label.message')} value={syncWithAt?.atMessage || '—'} />
        <TablePreviewRow label={t('label.codeAboutFailed')} value={syncWithAt?.atCode || '—'} />
        <TablePreviewRow
          label={t('label.dateAboutFailed')}
          value={dayjs(syncWithAt?.updatedAt).format(DATE_FORMAT_WITH_TIME)}
        />
      </div>

      {syncWithAt?.isRetriable && (
        <Button
          type="submit"
          loading={loading}
          disabled={loading}
          fullWidth
          size="m"
          variant="primary"
          className="mt-[32px]"
          onClick={handleRetry}
        >
          {t('button.label.retry')}
        </Button>
      )}

      {setupATConnectionModalOpened && (
        <CustomModal
          title={t('label.atCommunication')}
          isOpened={setupATConnectionModalOpened}
          handleClose={setupATConnectionModal.close}
          size={500}
        >
          <ConnectWithATOptions onConnectionAdded={setupATConnectionModal.close} />
        </CustomModal>
      )}
    </div>
  );
};

export default RetryConnectATForm;
