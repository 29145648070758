import { useTranslation } from 'react-i18next';
import { showErrorToast } from '@/components/Toast';
import {
  useCreateDraftInvoiceMutation,
  // GetInvoicesDocument,
  useIssueInvoiceMutation,
  useUpdateInvoiceMutation,
} from '@/graphql-operations';

const useNewInvoiceMutations = () => {
  const { t } = useTranslation();

  const [createInvoice, { loading: createLoading }] = useCreateDraftInvoiceMutation({
    onError: (error) => {
      showErrorToast({ title: t('error.createInvoice'), message: error.message });
      return;
    },
    // refetchQueries: [GetInvoicesDocument],
  });

  const [issueInvoice, { loading: issueLoading }] = useIssueInvoiceMutation({
    onError: (error) => {
      showErrorToast({ title: t('error.createInvoice'), message: error.message });
      return;
    },
  });

  const [updateInvoice, { loading: updateLoading }] = useUpdateInvoiceMutation({
    onError: (error) => {
      showErrorToast({ title: t('error.updateInvoice'), message: error.message });
      return;
    },
    // refetchQueries: [GetInvoicesDocument],
  });

  return {
    createInvoice,
    createLoading,
    issueInvoice,
    issueLoading,
    updateInvoice,
    updateLoading,
  };
};

export default useNewInvoiceMutations;
