import { NumberInput, NumberInputProps } from '@mantine/core';
import { Discount_Type } from '@/graphql-types';

interface FormikDiscountInputProps extends NumberInputProps {
  fieldName: string;
  styles?: Record<string, string | number>;
  customClassNames?: Record<string, string>;
  rightSectionIcon: string | JSX.Element;
  discountType?: Discount_Type;
  handleChange: (value: string | number) => void;
  localValue?: string | number;
}

const FormikDiscountInput = (props: FormikDiscountInputProps) => {
  const { customClassNames, rightSectionIcon, handleChange, localValue, fieldName, ...rest } = props;

  const mergedClasses = {
    ...customClassNames,
    input: `${customClassNames?.input || ''} h-14`,
  };

  return (
    <NumberInput
      {...rest}
      name={fieldName}
      rightSection={<span className="mr-2.5">{rightSectionIcon}</span>}
      size={props.size || 'md'}
      value={localValue}
      onChange={handleChange}
      classNames={mergedClasses}
      allowNegative={false}
      clampBehavior="strict"
      inputMode="decimal"
    />
  );
};

FormikDiscountInput.displayName = 'FormikDiscountInput';

export default FormikDiscountInput;
