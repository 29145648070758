import { Stack, Group, Skeleton } from '@mantine/core';

const ItemTotalSkeleton = () => {
  return (
    <Stack gap={7}>
      <Group justify="flex-end">
        <Skeleton height={20} width={90} />
      </Group>
      <Group justify="flex-end">
        <Skeleton height={20} width={90} />
      </Group>
      <Group justify="flex-end">
        <Skeleton height={20} width={50} />
      </Group>
    </Stack>
  );
};

export default ItemTotalSkeleton;
