import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(timezone);

//Months are zero indexed, so January is month 0. https://day.js.org/docs/en/get-set/month#docsNav
export enum DayJS_Indexed_Months {
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}
export const getMonthIndex = (monthName: string): number => {
  return DayJS_Indexed_Months[monthName as keyof typeof DayJS_Indexed_Months];
};

export const getMinDate5WorkingDaysInPast = (today: Dayjs) => {
  let count = 0;
  let current = today;

  const weekend = [6, 7];

  while (count < 5) {
    current = current.subtract(1, 'day');
    if (!weekend.includes(current.isoWeekday())) {
      count++;
    }
  }

  return current.toDate();
};

export const setDateToISONoon = (date?: string | Date | Dayjs): string => {
  if (date) {
    return dayjs(date).hour(12).minute(0).second(0).millisecond(0).utc().toISOString();
  }
  return dayjs().hour(12).minute(0).second(0).millisecond(0).utc().toISOString();
};

export const isOverdueInvoice = (dueDate: string | Date, timezone = 'Europe/Lisbon') => {
  const dateCriteria = dayjs().tz(timezone).startOf('day').utc().toDate();

  return dayjs(dueDate).isBefore(dateCriteria);
};
