import { Accordion, LoadingOverlay } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { useTaxExemptionReasonsQuery } from '@/graphql/operations';
import { Discount_Type, Invoice, InvoiceItemData, InvoiceItemMeta } from '@/graphql-types';
import { currencyFormatter } from '@/utils';
import { getTaxDetails } from '@/utils/getTaxDetails';
import { titleOfTotal } from '@/utils/titleOfTotal';

import { InvoiceCardBodyProps } from '../types';

type InvoiceItemsTableProps = Partial<InvoiceItemData> & Partial<InvoiceItemMeta> & { taxExemptionId?: string };

const getInvoiceItemsTable = (items: InvoiceItemsTableProps[], invoice: Invoice) => {
  return items.map((item, index) => {
    return (
      <tr className="text-sm text-right border-b border-fog-bronze text-dark-olive" key={item.name}>
        <td className="w-[20px] py-2 pr-2 text-left align-top">{index + 1}</td>
        <td className="w-[200px] py-2 text-left align-top lg:w-[500px]">{item.name}</td>
        <td className="w-[20px] py-2 pr-2.5 align-top">{item.quantity}</td>
        <td className="w-[90px] py-2 pr-2.5 align-top">
          {currencyFormatter(item.unitPrice || 0, invoice.currency || 'EUR')}
        </td>
        <td className="w-[70px] text-nowrap px-2.5 py-2 text-left align-top">
          {item.taxRate}% {item.taxExemptionId ? <sup>{item.taxExemptionId}</sup> : null}
        </td>
        <td className="w-[60px] py-2 align-top font-bold">
          {currencyFormatter(item.amountWithoutTaxWithoutDiscount || 0, invoice.currency || 'EUR')}
        </td>
      </tr>
    );
  });
};

const Body = ({ items, discount, invoiceData }: InvoiceCardBodyProps) => {
  const { t } = useTranslation();
  const { data: taxExemptionReasons, loading: loadingTaxExemptionReasons } = useTaxExemptionReasonsQuery();
  const { invoice } = invoiceData as { invoice: Invoice };

  const taxDetails = getTaxDetails({ invoice, taxExemptionReasons: taxExemptionReasons?.taxExemptionReasons });

  const {
    reducedTax,
    intermediaTax,
    normalTax,
    exemptTax,
    incidenceExempt,
    incidenceReduced,
    incidenceIntermedia,
    incidenceNormal,
    totalExemptTax,
    totalReduced,
    totalIntermedia,
    totalNormal,
    amountTotal,
    totalVat,
    subtotal,
    vatExemptions,
  } = taxDetails;

  const taxExemptions = vatExemptions;

  const itemsWithExemptions = items.map((item) => ({
    ...item,
    taxExemptionId: vatExemptions?.find((exemption) => exemption.items.includes(item.id!))?.id,
  }));

  const discountUiView =
    invoiceData.invoice.summary?.globalDiscountType === Discount_Type.Percent
      ? ` - ${invoice.summary?.globalDiscountPercent}%`
      : '';

  if (loadingTaxExemptionReasons) {
    return <LoadingOverlay visible />;
  }

  const hasWithholding = invoice.summary?.withholdingTaxAmount ? invoice.summary?.withholdingTaxAmount > 0 : false;
  const total = invoice.summary?.total || 0;
  const withholdingTaxAmount = invoice.summary?.withholdingTaxAmount || 0;
  const totalToPay = hasWithholding ? total - withholdingTaxAmount : total;

  return (
    <div>
      <>
        <h1 className="pb-5 text-[20px] font-semibold">{t('label.items')}</h1>
        <section>
          <table className="w-full">
            <thead>
              <tr className="text-sm font-semibold text-right border-b border-fog-bronze text-dark-olive">
                <td className="w-[20px] py-2 text-left">#</td>
                <td className="w-[200px] py-2 text-left lg:w-[500px]">{t('label.description')}</td>
                <td className="w-[20px] py-2 pr-2.5">{t('label.qty')}</td>
                <td className="w-[90px] py-2 pr-2.5">{t('label.price')}</td>
                <td className="w-[50px] px-2.5 py-2 text-left">{t('label.vat')}</td>
                <td className="w-[90px] py-2">{t('label.total')}</td>
              </tr>
            </thead>
            <tbody>{getInvoiceItemsTable(itemsWithExemptions, invoice)}</tbody>
          </table>
        </section>
        <div className="pt-5">
          <span className="text-[14px] text-dark-olive">{invoice.notes && <>{invoice.notes}</>}</span>
        </div>
        <div className="justify-between pt-5 md:flex md:flex-row">
          <div className="col-span-2 md:w-1/2 xl:w-2/5">
            {(incidenceReduced || incidenceIntermedia || incidenceNormal || incidenceExempt) && (
              <table className="w-full">
                <thead>
                  <tr className="text-[14px]">
                    <td className="py-0.5 text-left font-semibold text-black-magic">{t('label.vat')}</td>
                    <td className="py-0.5 text-right text-dark-bronze">{t('label.incidence')}</td>
                    <td className="max-w-[60px] py-0.5 text-right text-dark-bronze">{t('label.total')}</td>
                  </tr>
                </thead>
                <tbody>
                  {!!incidenceExempt && (
                    <tr className="text-[14px] text-dark-bronze">
                      <td className="py-0.5 text-left">
                        {t('label.exempt')} {exemptTax ? exemptTax.rate! : undefined}%
                      </td>
                      <td className="py-0.5 text-right">
                        {currencyFormatter(incidenceExempt || 0, invoice.currency || 'EUR')}
                      </td>
                      <td className="max-w-[60px] py-0.5 text-right">
                        {currencyFormatter(totalExemptTax || 0, invoice.currency || 'EUR')}
                      </td>
                    </tr>
                  )}
                  {!!incidenceReduced && (
                    <tr className="text-[14px] text-dark-bronze">
                      <td className="py-0.5 text-left">
                        {t('label.reduced')} {reducedTax ? reducedTax.rate! : undefined}%
                      </td>
                      <td className="py-0.5 text-right">
                        {currencyFormatter(incidenceReduced || 0, invoice.currency || 'EUR')}
                      </td>
                      <td className="max-w-[60px] py-0.5 text-right">
                        {currencyFormatter(totalReduced || 0, invoice.currency || 'EUR')}
                      </td>
                    </tr>
                  )}
                  {!!incidenceIntermedia && (
                    <tr className="text-[14px] text-dark-bronze">
                      <td className="py-0.5 text-left">
                        {t('label.intermedia')} {intermediaTax ? intermediaTax.rate! : undefined}%
                      </td>
                      <td className="py-0.5 text-right">
                        {currencyFormatter(incidenceIntermedia || 0, invoice.currency || 'EUR')}
                      </td>
                      <td className="max-w-[60px] py-0.5 text-right">
                        {currencyFormatter(totalIntermedia || 0, invoice.currency || 'EUR')}
                      </td>
                    </tr>
                  )}
                  {!!incidenceNormal && (
                    <tr className="text-[14px] text-dark-bronze">
                      <td className="py-0.5 text-left">
                        {t('label.normal')} {normalTax ? normalTax.rate! : undefined}%
                      </td>
                      <td className="py-0.5 text-right">
                        {currencyFormatter(incidenceNormal || 0, invoice.currency || 'EUR')}
                      </td>
                      <td className="max-w-[60px] py-0.5 text-right">
                        {currencyFormatter(totalNormal || 0, invoice.currency || 'EUR')}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
          <div className="col-span-2 pt-2 mt-4 border-t border-solid border-medium-vanilla text-end md:mt-0 md:w-1/2 md:border-none md:pt-0">
            <table className="w-full">
              <tbody>
                <tr className="text-[14px]">
                  <td className="py-0.5 text-start text-dark-bronze md:text-end">{t('label.subtotal')}</td>
                  <td className="py-0.5 font-semibold text-black-magic">
                    {currencyFormatter(subtotal || 0, invoice.currency || 'EUR')}
                  </td>
                </tr>
                {discount !== 0 && (
                  <tr className="text-[14px]">
                    <td className="py-0.5 text-start text-dark-bronze md:text-end">
                      {t('label.discount')}
                      {discountUiView}
                    </td>
                    <td className="py-0.5 font-semibold text-black-magic">
                      {currencyFormatter(discount || 0, invoice.currency || 'EUR')}
                    </td>
                  </tr>
                )}
                <tr className="text-[14px]">
                  <td className="py-0.5 text-start text-dark-bronze md:text-end">{t('label.vat')}</td>
                  <td className="py-0.5 font-semibold text-black-magic">
                    {currencyFormatter(totalVat || 0, invoice.currency || 'EUR')}
                  </td>
                </tr>
                {hasWithholding && (
                  <>
                    <tr className="text-[14px]">
                      <td className="py-0.5 text-start text-dark-bronze md:text-end">{t('label.total')}</td>
                      <td className="py-0.5 font-semibold text-black-magic">
                        {currencyFormatter(amountTotal || 0, invoice.currency || 'EUR')}
                      </td>
                    </tr>
                    <tr className="text-[14px]">
                      <td className="py-0.5 text-start text-dark-bronze md:text-end">{t('label.withholdingTax')}</td>
                      <td className="py-0.5 font-semibold text-black-magic">
                        {currencyFormatter(withholdingTaxAmount || 0, invoice.currency || 'EUR')}
                      </td>
                    </tr>
                  </>
                )}
                <tr>
                  <td className="text-start text-[16px] font-bold text-black-magic md:text-end">
                    {titleOfTotal(t, invoice.status, invoice.documentType!)}
                  </td>
                  <td className="text-[16px] font-bold text-black-magic">
                    <span>{currencyFormatter(totalToPay || 0, invoice.currency || 'EUR')}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="pt-2.5">
          {taxExemptions?.length > 0 && (
            <Accordion>
              <Accordion.Item value="taxExtemptionsVat" className="flex justify-start border-none">
                <div>
                  <Accordion.Control className="p-0 text-[14px] text-moroccan-blue hover:bg-white-vanilla">
                    <span className="pr-1 font-semibold">{t('label.taxExemptionsVat')}</span>
                  </Accordion.Control>
                </div>
              </Accordion.Item>
              <Accordion.Item value="taxExtemptionsVat" className="border-none">
                <Accordion.Panel className="ml-[-14px] text-[14px] font-normal text-dark-bronze">
                  {taxExemptions?.map((taxExemption, index) => (
                    <div className="pb-1" key={index}>
                      <span>
                        {`${taxExemption.id}) ${taxExemption.reason} (${taxExemption.description})`}
                        <br />
                      </span>
                    </div>
                  ))}
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          )}
        </div>
      </>
    </div>
  );
};

export default Body;
