import { Radio, RadioGroupProps, Stack } from '@mantine/core';
import { useField, useFormikContext } from 'formik';
import { ReactNode } from 'react';

import IconRadioButtonOff from '@/assets/icons/IconRadioButtonOff.svg?react';
import IconRadioButtonOn from '@/assets/icons/IconRadioButtonOn.svg?react';
import { cn } from '@/utils';

export type FormikRadioButtonGroupProps = Omit<RadioGroupProps, 'children'> & {
  name: string;
  data: {
    value: string;
    title: ReactNode;
    description: ReactNode;
  }[];
};

const FormikRadioButtonGroup = ({ name, data, ...rest }: FormikRadioButtonGroupProps) => {
  const { setFieldValue } = useFormikContext<Record<string, unknown>>();
  const [field] = useField(name);

  const cards = data.map((item) => (
    <Radio.Card
      value={item.value}
      key={item.value}
      withBorder={false}
      className={cn(
        `min-h-16 w-full rounded-2xl bg-light-silver px-4 py-[7px] outline`,
        {
          'outline-1 outline-dark-braun': field.value !== item.value,
        },
        {
          'outline-2 outline-moroccan-blue': field.value === item.value,
        }
      )}
    >
      <div className="flex items-center">
        <div className="">
          {field.value === item.value ? <IconRadioButtonOn className="fill-moroccan-blue" /> : <IconRadioButtonOff />}
        </div>
        <div className="ml-3 flex flex-col">
          <span className="text-[14px] font-semibold leading-[130%]">{item.title}</span>
          <span className="text-[11px] leading-[130%] text-dark-angel">{item.description}</span>
        </div>
      </div>
    </Radio.Card>
  ));

  return (
    <Radio.Group value={field.value} onChange={(value) => setFieldValue(name, value)} {...rest}>
      <Stack gap={8}>{cards}</Stack>
    </Radio.Group>
  );
};

export default FormikRadioButtonGroup;
