import { useTranslation } from '@/components/I18N';
import { Invoice_Document_Type } from '@/graphql/types';
import { PreviewInvoiceQueryQuery } from '@/graphql-operations';

import { SummaryDivider } from './summary/SummaryDivider';
import SummaryLine from './summary/SummaryItem';
import TotalsPreviewSkeleton from './TotalsPreviewSkeleton';

type InvoiceCalculatedTotalsPreviewProps = {
  isPreviewLoading: boolean;
  totalsPreviewData: PreviewInvoiceQueryQuery | undefined;
  invoiceType: string;
};

const InvoiceCalculatedTotalsPreview = ({
  isPreviewLoading,
  totalsPreviewData,
  invoiceType,
}: InvoiceCalculatedTotalsPreviewProps) => {
  const { t } = useTranslation();
  const { summary } = totalsPreviewData?.previewInvoice || {};

  if (isPreviewLoading) {
    return <TotalsPreviewSkeleton />;
  }

  const hasWithholding = summary?.withholdingTaxAmount ? summary?.withholdingTaxAmount > 0 : false;
  const total = summary?.total || 0;
  const withholdingTaxAmount = summary?.withholdingTaxAmount || 0;
  const totalToPay = hasWithholding ? total - withholdingTaxAmount : total;

  let titleOfTotal;

  if (invoiceType === Invoice_Document_Type.Invoice) {
    titleOfTotal = <span>{t('label.totalToPay')}</span>;
  } else if (
    invoiceType === Invoice_Document_Type.InvoiceReceipt ||
    invoiceType === Invoice_Document_Type.Receipt ||
    invoiceType === Invoice_Document_Type.SimplifiedInvoice
  ) {
    titleOfTotal = <span>{t('label.totalPaid')}</span>;
  } else if (invoiceType === Invoice_Document_Type.CreditNote) {
    titleOfTotal = <span>{t('label.totalToCredit')}</span>;
  } else {
    titleOfTotal = <span>{t('label.totalAmount')}</span>;
  }

  return (
    <>
      <SummaryLine title={t('label.subtotal')} amount={summary?.amountWithoutTaxWithoutDiscount} />
      {!!summary?.globalDiscountAmount && (
        <SummaryLine title={t('label.discount')} amount={summary?.globalDiscountAmount} />
      )}
      <SummaryLine title={t('label.vat')} amount={summary?.taxAmount} />
      <SummaryDivider />
      {hasWithholding && (
        <>
          <SummaryLine title={t('label.total')} amount={summary?.total} className="pt-1" />
          <SummaryLine title={t('label.withholdingTax')} amount={withholdingTaxAmount} />
          <SummaryDivider />
        </>
      )}
      <SummaryLine
        title={titleOfTotal}
        amount={totalToPay}
        className="pb-2 pt-1 text-[17px] font-semibold"
        color="text-black-magic"
      />
    </>
  );
};

export default InvoiceCalculatedTotalsPreview;
