import { ActionIcon, Flex, Stack } from '@mantine/core';
import { SelectionPreviewVariant, SELECTION_PREVIEW_VARIANT } from '../types';
import IconPencil from '@/assets/icons/IconPencil.svg?react';
import IconTrashCan from '@/assets/icons/IconTrashCan.svg?react';
import ItemTotalSkeleton from './ItemTotalSkeleton';

type SelectionPreviewItemsProps = {
  stack1Rows: (string | number | undefined | React.ReactNode)[];
  stack2Rows?: string[];
  stack3Rows?: string[];
  variant: SelectionPreviewVariant;
  handleEditClick?: () => void;
  handleDeleteClick?: () => void;
  isPreviewLoading: boolean;
};

const SelectionPreviewItems = ({
  stack1Rows,
  stack2Rows,
  stack3Rows,
  variant,
  handleEditClick,
  handleDeleteClick,
  isPreviewLoading,
}: SelectionPreviewItemsProps) => {
  const getActionAreaIcon = () => {
    if (variant === SELECTION_PREVIEW_VARIANT.EDIT) {
      return (
        <ActionIcon className="rounded-full bg-angel-feather hover:bg-feather-dark" size="lg" onClick={handleEditClick}>
          <IconPencil height={20} width={20} />
        </ActionIcon>
      );
    }

    if (variant === SELECTION_PREVIEW_VARIANT.EDIT_REMOVE) {
      return (
        <div className="flex justify-between gap-2">
          <ActionIcon
            className="rounded-full bg-angel-feather hover:bg-feather-dark"
            size="lg"
            onClick={handleEditClick}
          >
            <IconPencil height={20} width={20} />
          </ActionIcon>
          <ActionIcon
            className="rounded-full bg-angel-feather hover:bg-feather-dark"
            size="lg"
            onClick={handleDeleteClick}
          >
            <IconTrashCan height={20} width={20} />
          </ActionIcon>
        </div>
      );
    }
    return (
      <ActionIcon className="rounded-full bg-angel-feather hover:bg-feather-dark" size="lg" onClick={handleDeleteClick}>
        <IconTrashCan height={20} width={20} />
      </ActionIcon>
    );
  };

  return (
    <Flex
      mih={70} // TODO: do we need this????
      gap="md"
      justify="space-between"
      align="center"
      direction="column"
      wrap="wrap"
    >
      <Flex justify="space-between" className="w-full">
        <Stack gap={0}>
          {stack1Rows?.map((row, index) => {
            return (
              <div
                key={index}
                className={index === 0 ? 'text-md max-w-[450px] text-black-olive' : 'text-silver-chalise'}
              >
                {row}
              </div>
            );
          })}
        </Stack>
        {isPreviewLoading ? (
          <Flex justify="flex-end">
            <ItemTotalSkeleton />
          </Flex>
        ) : (
          <Stack gap={0}>
            {stack2Rows &&
              stack2Rows.length > 0 &&
              stack2Rows.map((row, index) => (
                <div
                  className={
                    index === 0
                      ? 'text-md max-w-[450px] text-end font-semibold text-black-olive'
                      : 'text-end text-silver-chalise'
                  }
                  key={index}
                >
                  {row}
                </div>
              ))}
          </Stack>
        )}
      </Flex>
      <Flex justify="space-between" className="w-full">
        <Stack gap={0} className="text-end">
          {stack3Rows?.map((row, index) => {
            return (
              <div key={index} className={index === 0 ? 'text-md text-black-olive' : 'text-silver-chalise'}>
                {row}
              </div>
            );
          })}
        </Stack>
        <div className="my-auto flex self-end">{getActionAreaIcon()}</div>
      </Flex>
    </Flex>
  );
};

export default SelectionPreviewItems;
