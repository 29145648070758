import React, { forwardRef } from 'react';
import { Textarea, TextareaProps } from '@mantine/core';
import { useField } from 'formik';
import classes from './CustomTextareaInput.module.css';

export interface FormikTextareaInputProps extends TextareaProps {
  name: string;
  placeholder?: string;
  styles?: Record<string, string | number>;
  customClassNames?: Record<string, string>;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const FormikTextareaInput = forwardRef<HTMLTextAreaElement, FormikTextareaInputProps>(
  (
    { name, placeholder, label, error, styles, onChange, customClassNames, ...props }: FormikTextareaInputProps,
    ref
  ) => {
    const [field, meta, helpers] = useField(name);

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (onChange) {
        onChange(event);
      }
      helpers.setValue(event.target.value);
    };

    const mergedClasses = {
      ...classes,
      input: `${customClassNames?.input} ${classes.input}`,
    };

    return (
      <Textarea
        {...field}
        {...props}
        value={field.value}
        onChange={handleOnChange}
        size={(styles?.size as string) || 'md'}
        label={field.value ? label : ''}
        placeholder={placeholder}
        error={meta.touched && meta.error ? meta.error : error}
        classNames={mergedClasses}
        styles={{ label: { fontSize: '11px' }, ...styles }}
        autosize
        ref={ref}
      />
    );
  }
);

export default FormikTextareaInput;
