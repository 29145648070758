import { FormikNumberInput } from '@/components/FormikWrapped';
import { FormikNumberInputProps } from '@/components/FormikWrapped/FormikNumberInput';
import { Flex, ActionIcon } from '@mantine/core';
import { IconPlus, IconMinus } from '@tabler/icons-react';

type QuantityInputProps = FormikNumberInputProps & {
  name: string;
  error?: string;
  width: number;
  setFieldValue: (field: string, value: number) => void;
  currentFieldValue: number;
};

// can be turned into a FormikWrapped component and moved to src/components/FormikWrapped to be reused
const QuantityInput = ({
  name,
  min = 0,
  max = 9999999,
  error,
  width,
  setFieldValue,
  currentFieldValue,
}: QuantityInputProps) => {
  const handleIncrement = () => {
    if (currentFieldValue === max) return;
    setFieldValue(name, currentFieldValue + 1);
  };

  const handleDecrement = () => {
    if (currentFieldValue === min) return;
    setFieldValue(name, currentFieldValue - 1);
  };

  const isDecrementDisabled = min ? currentFieldValue - 1 < min : false;
  const isIncrementDisabled = max ? currentFieldValue + 1 > max : false;

  return (
    <Flex align="center" gap={12}>
      <ActionIcon
        className="rounded-full bg-angel-feather hover:bg-feather-dark disabled:bg-seashell"
        disabled={isDecrementDisabled}
        size="lg"
        onClick={handleDecrement}
      >
        <IconMinus height={24} width={24} stroke={2} color={isDecrementDisabled ? '#ced4da' : '#29241BCC'} />
      </ActionIcon>
      <FormikNumberInput
        name={name}
        value={currentFieldValue}
        onChange={(value) => {
          if (typeof value === 'string' && value === '') {
            return setFieldValue(name, 1);
          }

          setFieldValue(name, +value);
        }}
        allowNegative={false}
        allowDecimal={false}
        isAllowed={(enteredValue) => {
          const { floatValue, value } = enteredValue;
          const currentFieldValueWithinRange = currentFieldValue > min && currentFieldValue <= max;

          // prevent user from entering 0 at the beginning
          if (value.startsWith('0')) {
            return false;
          }

          // allow user to delete value when it is within range
          if (currentFieldValueWithinRange) {
            return true;
          }

          if (!floatValue || floatValue === 0) return false;

          return floatValue >= min && floatValue <= max;
        }}
        clampBehavior="strict"
        min={min}
        max={max}
        w={width}
        size="xs"
        hideControls
        error={error}
        customClassNames={{
          input: `p-4 text-base rounded-xl text-center`,
        }}
      />
      <ActionIcon
        className="rounded-full bg-angel-feather hover:bg-feather-dark disabled:bg-seashell"
        size="lg"
        disabled={isIncrementDisabled}
        onClick={handleIncrement}
      >
        <IconPlus height={24} width={24} stroke={2} color={isIncrementDisabled ? '#ced4da' : '#29241BCC'} />
      </ActionIcon>
    </Flex>
  );
};

export default QuantityInput;
