import { cn } from '@/utils';
import { Paper, PaperProps, Stack, StackProps, Text, TextProps } from '@mantine/core';
import { PropsWithChildren } from 'react';

export type CardBlockProps = PropsWithChildren<
  PaperProps & {
    stackedContent?: boolean;
    stackedContentProps?: StackProps;
  }
>;

export const CardBlock = ({ children, stackedContent, stackedContentProps, className, ...props }: CardBlockProps) => {
  return (
    <Paper className={cn('rounded-3xl bg-white-vanilla p-4 sm:p-5', className)} {...props}>
      {stackedContent ? <CardBlockStack {...stackedContentProps}>{children}</CardBlockStack> : children}
    </Paper>
  );
};

export const CardBlockTitle = ({ children, className, ...props }: PropsWithChildren<TextProps>) => {
  return (
    <Text fw={600} className={cn('text-[17px] leading-[22px] text-black-magic', className)} {...props}>
      {children}
    </Text>
  );
};

export const CardBlockStack = ({ children, className, ...props }: PropsWithChildren<StackProps>) => {
  return (
    <Stack className={cn('gap-2 sm:gap-4', className)} {...props}>
      {children}
    </Stack>
  );
};

CardBlock.Title = CardBlockTitle;
CardBlock.Stack = CardBlockStack;
