import React from 'react';
import { cn } from '@/utils';

type ElementWrapperProps = {
  classNames?: string;
  children: React.ReactNode;
};

const CreateInvoiceElementWrapper = ({ classNames, children }: ElementWrapperProps) => {
  return <div className={cn('rounded-3xl bg-white-vanilla p-4 sm:p-5', classNames)}>{children}</div>;
};

export default CreateInvoiceElementWrapper;
