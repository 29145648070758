import 'dayjs/locale/pt';
import 'dayjs/locale/en';

import { DatePickerInput, DatePickerInputProps } from '@mantine/dates';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';

import { cn, DATE_FORMAT } from '@/utils';

import { i18n } from '../I18N';
import classes from './CustomInput.module.css';
import classesWithoutLabel from './CustomInputWithoutLabel.module.css';

type FormikDateInputProps = DatePickerInputProps & {
  name: string;
  onChange?: (date: Date | null) => void;
};

const FormikDateInput = (props: FormikDateInputProps) => {
  const { setFieldValue } = useFormikContext();
  const { onChange, ...rest } = props;

  const handleOnChange = (date: Date | null) => {
    if (onChange) {
      onChange(date);
    } else {
      setFieldValue(props.name, date);
    }
  };

  dayjs.locale(i18n.language);

  const cls = !props.label ? classesWithoutLabel : classes;

  const mergedClasses = {
    ...cls,
    input: cn(cls.input, 'rounded-2xl text-[16px]'),
    label: cn(cls.label, 'font-normal text-gray-midnight'),
  };

  return (
    <DatePickerInput
      firstDayOfWeek={['pt', 'pt-PT'].includes(i18n.language) ? 0 : 1}
      locale={i18n.language}
      classNames={mergedClasses}
      // @ts-expect-error
      onChange={handleOnChange}
      hideOutsideDates
      valueFormat={DATE_FORMAT}
      {...rest}
    />
  );
};

export default FormikDateInput;
