import { useState } from 'react';
import CustomModal from '@/components/CustomModal';
import { Alert, Group, LoadingOverlay, Paper } from '@mantine/core';
import { Form, Formik } from 'formik';
import { FormikCheckbox, FormikSelect } from '@/components/FormikWrapped';
import { IconInfoCircle } from '@tabler/icons-react';
import { ExportSAFTPTValidationSchema } from './validationSchema';
import { useTranslation } from 'react-i18next';
import { useGetSaftPtExportLazyQuery } from '@/graphql-operations';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { showErrorToast } from '@/components/Toast';
import { DayJS_Indexed_Months, getMonthIndex } from '@/utils/dates';
import { TFunction } from '@/components/I18N';
import { Button } from '@/components/ButtonVariants';

dayjs.extend(utc);

type ExportSAFTPTModalProps = {
  isOpened: boolean;
  close: () => void;
  onOpenChange: (opened: boolean) => void;
};

const getDropdownValues = (t: TFunction) =>
  Object.keys(DayJS_Indexed_Months)
    .filter((key) => isNaN(Number(key)))
    .map((month) => ({ value: month, label: t(`label.month.${month.toLowerCase()}`) }));

const ExportSAFTPTModal = ({ isOpened, close, onOpenChange }: ExportSAFTPTModalProps) => {
  const [downloadYear, setDownloadYear] = useState(false);
  const [getSaftPtExport, { loading: getSaftPtExportLoading }] = useGetSaftPtExportLazyQuery({
    onError: (error) => {
      showErrorToast({
        title: t('error.gettingSaftptFile'),
        message: error.message,
      });
      return;
    },
    fetchPolicy: 'no-cache',
  });

  const isMobileScreen = () => {
    const userAgent = window.navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  };

  const initialValues = { year: dayjs().year().toString(), month: DayJS_Indexed_Months[dayjs().month()] };
  const handleFormSubmit = async ({ year, month }: { year: string; month: string }) => {
    const monthIndex = getMonthIndex(month);
    const dateObjUTC = dayjs().year(+year).month(monthIndex).utc();

    const dayJSUnit = downloadYear ? 'year' : 'month';

    const startDate = dateObjUTC.startOf(dayJSUnit).format();
    const endDate = dateObjUTC.endOf(dayJSUnit).format();

    const saftPtExportResponse = await getSaftPtExport({
      variables: {
        startDate,
        endDate,
      },
    });

    if (!saftPtExportResponse.data?.exportSAFTPT.signedUrl) return;

    const { signedUrl } = saftPtExportResponse.data.exportSAFTPT;

    const downloadURL = new URL(signedUrl);
    const fileName = `saftpt_${year}_${month}.xml`;

    downloadURL.searchParams.append('response-content-disposition', `attachment; filename=${fileName}`);

    console.log('downloadURL', downloadURL.toString());

    if (isMobileScreen()) {
      window.location.href = downloadURL.toString();
    } else {
      const anchor = document.createElement('a');
      anchor.href = downloadURL.toString();
      anchor.download = fileName;
      anchor.click();
    }
  };

  const { t } = useTranslation();

  return (
    <CustomModal
      isOpened={isOpened}
      handleClose={close}
      // closeOnClickOutside={false}
      title={t('label.exportSAFTPT')}
      onOpenChange={onOpenChange}
    >
      <Paper
        p={0}
        shadow="none"
        style={{
          position: 'relative',
          backgroundColor: 'var(--mantine-color-body)',
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={ExportSAFTPTValidationSchema}
        >
          {({ errors, touched }) => {
            return (
              <Form autoComplete="off">
                <LoadingOverlay visible={getSaftPtExportLoading} />

                <Alert variant="light" color="#ffc038" title={t('label.vatReporting')} icon={<IconInfoCircle />}>
                  {t('label.vatMustBeSubmitted')}
                </Alert>

                <Group grow mt="md" align="start">
                  <FormikSelect
                    size="md"
                    name="year"
                    label={t('label.year')}
                    placeholder={t('placeholder.selectYear')}
                    data={[{ value: '2024', label: '2024' }]}
                    error={errors.year && touched.year ? errors.year : null}
                  />

                  <FormikSelect
                    size="md"
                    name="month"
                    disabled={downloadYear}
                    label={t('label.month')}
                    placeholder={t('placeholder.selectMonth')}
                    data={getDropdownValues(t)}
                    error={errors.month && touched.month ? errors.month : null}
                  />
                </Group>

                <FormikCheckbox
                  name="downloadYear"
                  mt="lg"
                  label={t('label.downloadYear')}
                  checked={downloadYear}
                  onChange={(e) => setDownloadYear(e.target.checked)}
                />

                <Button type="submit" fullWidth size="m" variant="primary" className="mt-5">
                  {t('button.label.download')}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </CustomModal>
  );
};

export default ExportSAFTPTModal;
