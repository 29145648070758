import { lazy, Suspense } from 'react';
import { useFormikContext } from 'formik';
import { useReactiveVar } from '@apollo/client';
import { Drawer, LoadingOverlay } from '@mantine/core';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { PreviewInvoiceQueryQuery, useTaxExemptionReasonsQuery } from '@/graphql/operations';
import { useTranslation } from '@/components/I18N';
import { CreateInvoiceFormValues } from '../types';
import mapToPreviewInvoicePDF from './mapToPreviewInvoicePDF';

type InvoicePdfPreviewDrawerProps = {
  closePreview: () => void;
  totalsPreviewData: PreviewInvoiceQueryQuery | undefined;
  isDraftInvoice?: boolean;
};

const InvoicePdfPreviewRoute = lazy(() => import('@/components/InvoicePdf/parts/preview'));

const InvoicePdfPreviewDrawer = ({ closePreview, totalsPreviewData }: InvoicePdfPreviewDrawerProps) => {
  const { t } = useTranslation();
  const tenantObject = useReactiveVar(tenantVar);
  const { values } = useFormikContext<CreateInvoiceFormValues>();
  const { data: taxExemptionReasons, loading: loadingTaxExemptionReasons } = useTaxExemptionReasonsQuery();

  if (loadingTaxExemptionReasons) return <LoadingOverlay visible />;

  return (
    <Drawer opened onClose={closePreview} title={t('button.label.preview')} position="right" size="xl">
      <Suspense fallback={<LoadingOverlay visible />}>
        <InvoicePdfPreviewRoute
          invoice={mapToPreviewInvoicePDF(values, totalsPreviewData)}
          tenant={tenantObject}
          taxExemptionReasons={taxExemptionReasons?.taxExemptionReasons || []}
          isPreview
        />
      </Suspense>
    </Drawer>
  );
};

export default InvoicePdfPreviewDrawer;
