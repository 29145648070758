import React from 'react';

import { Invoice } from '@/graphql/types';
import { PreviewInvoiceQueryQuery } from '@/graphql-operations';

import CreateInvoiceElementWrapper from './CreateInvoiceElementWrapper';
import FormActionButtons from './FormActionButtons';
import InvoiceCalculatedTotalsPreview from './InvoiceCalculatedTotalsPreview';

type InvoiceTotalsWithActionButtonsProps = {
  isCTAHidden?: boolean;
  handleDraftSubmission: () => void;
  handleIssueSubmission: () => void;
  openDeleteConfirmationModal: () => void;
  isPreviewLoading: boolean;
  totalsPreviewData: PreviewInvoiceQueryQuery | undefined;
  elementWrapperClasses?: string;
  actionButtonsClasses?: string;
  showTotalsPreview: boolean;
  invoice: Invoice;
  invoiceType: string;
};

const InvoiceTotalsWithActionButtons = ({
  invoice,
  invoiceType,
  openDeleteConfirmationModal,
  isCTAHidden = false,
  handleDraftSubmission,
  handleIssueSubmission,
  isPreviewLoading,
  totalsPreviewData,
  elementWrapperClasses = '',
  actionButtonsClasses,
  showTotalsPreview,
}: InvoiceTotalsWithActionButtonsProps) => {
  return (
    <CreateInvoiceElementWrapper classNames={elementWrapperClasses}>
      {showTotalsPreview && (
        <InvoiceCalculatedTotalsPreview
          isPreviewLoading={isPreviewLoading}
          invoiceType={invoiceType}
          totalsPreviewData={totalsPreviewData}
        />
      )}

      {!isCTAHidden && (
        <FormActionButtons
          wrapperClasses={actionButtonsClasses}
          handleDraftSubmission={handleDraftSubmission}
          handleIssueSubmission={handleIssueSubmission}
          openDeleteConfirmationModal={openDeleteConfirmationModal}
          invoice={invoice}
        />
      )}
    </CreateInvoiceElementWrapper>
  );
};

export default InvoiceTotalsWithActionButtons;
