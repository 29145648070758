import { useTranslation } from 'react-i18next';
import { InvoiceCardRelatedDocumentsProps } from '../types';
import { DAY_MONTH_YEAR, currencyFormatter } from '@/utils';
import dayjs from 'dayjs';
import { translateInvoiceDocumentType } from '@/utils/translateInvoiceDocumentType';
import IconSaveBlue from '@/assets/icons/IconSaveBlue.svg?react';
import IconDebitNoteBlack from '@/assets/icons/IconDebitNoteBlack.svg?react';
import IconCreditNoteBlack from '@/assets/icons/IconCreditNoteBlack.svg?react';
import IconFileBlackMedium from '@/assets/icons/IconFileBlackMedium.svg?react';
import { Invoice_Document_Type } from '@/graphql/types';
import { useNavigate } from 'react-router-dom';
import InvoiceStatus from '../../InvoiceStatus/InvoiceStatus';

const RelatedDocuments = ({ invoice, currencyCode, onDocumentClick }: InvoiceCardRelatedDocumentsProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const formattedDate = dayjs(invoice.date?.toLocaleString()).format(DAY_MONTH_YEAR);

  const translatedDocumentType = translateInvoiceDocumentType(invoice.documentType!, t);

  const handleDownload = () => {
    navigate(`/invoices/${invoice.id}/preview`);
  };

  const hasWithholding = invoice.summary?.withholdingTaxAmount ? invoice.summary?.withholdingTaxAmount > 0 : false;
  const total = invoice.summary?.total || 0;
  const withholdingTaxAmount = invoice.summary?.withholdingTaxAmount || 0;
  const totalToPay = hasWithholding ? total - withholdingTaxAmount : total;

  const formattedAmount = currencyFormatter(totalToPay || 0, currencyCode || 'EUR');

  let DocumentIcon;
  if (invoice.documentType === Invoice_Document_Type.DebitNote) {
    DocumentIcon = IconDebitNoteBlack;
  } else if (invoice.documentType === Invoice_Document_Type.CreditNote) {
    DocumentIcon = IconCreditNoteBlack;
  } else {
    DocumentIcon = IconFileBlackMedium;
  }

  let amountSign = '';
  if (invoice.documentType === Invoice_Document_Type.DebitNote) {
    amountSign = '+';
  } else if (invoice.documentType === Invoice_Document_Type.CreditNote) {
    amountSign = '-';
  }

  return (
    <>
      <div className="overflow-hidden rounded-3xl">
        <div className="flex justify-between bg-angel-feather p-4">
          <div className="flex">
            <DocumentIcon className="my-auto" />
            <h1 className="text-light-black-magi] pl-2 text-[17px] font-normal">{translatedDocumentType}</h1>
          </div>

          <IconSaveBlue onClick={handleDownload} className="hover:cursor-pointer" />
        </div>
        <div className="bg-white-vanilla p-5 hover:cursor-pointer" onClick={onDocumentClick}>
          <div className="flex justify-between pb-3">
            <span className="text-[17px] font-semibold text-black-magic">{invoice.number}</span>
            {invoice?.status && invoice?.documentType && (
              <InvoiceStatus
                status={invoice.status}
                dueDate={invoice.dueDate ? invoice.dueDate.toString() : ''}
                invoiceType={invoice.documentType}
              />
            )}
          </div>
          <section>
            <table className="w-full border-t border-fog-bronze">
              <thead>
                <tr>
                  <td className="w-[50px] pt-3 text-left text-[14px] text-dark-bronze">{t('label.issueDate')}</td>
                  <td className="w-[90px] pt-3 text-right text-[14px] text-dark-bronze">{t('label.totalAmount')}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="w-[50px] pb-2 text-left text-[17px] text-black-magic">{formattedDate}</td>
                  <td className="w-[90px] pb-2 text-right text-[17px] font-bold text-black-magic">
                    {amountSign}
                    {formattedAmount}
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
      </div>
    </>
  );
};

export default RelatedDocuments;
