import { useTranslation } from '@/components/I18N';
import { Flex, Alert, Text } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';

type IssueInvoiceConfirmationTextsProps = {
  isIssueDateAfterToday: boolean;
};

const IssueInvoiceConfirmationTexts = ({ isIssueDateAfterToday }: IssueInvoiceConfirmationTextsProps) => {
  const { t } = useTranslation();

  const ConfirmText = <Text size="sm">{t('modal.invoiceIssueConfirmation.body')}</Text>;

  if (isIssueDateAfterToday) {
    return (
      <Flex direction="column" gap="md">
        <Alert
          variant="light"
          color="#ffb61c"
          title={t('alert.issueDateGreaterThanToday.title')}
          icon={<IconAlertCircle />}
          classNames={{
            message: 'text-black-magic',
          }}
        >
          {t('alert.issueDateGreaterThanToday.body')}
        </Alert>
        {ConfirmText}
      </Flex>
    );
  }

  return ConfirmText;
};

export default IssueInvoiceConfirmationTexts;
