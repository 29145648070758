import { forwardRef, useState } from 'react';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { Invoice, Invoice_Document_Type, Invoice_Status, Discount_Type } from '@/graphql-types';
import { normalizeInvoiceItemToItem, normilizeInvoiceCustomerToCustomer } from '@/utils/normalizeData';
import validationSchema from './parts/validationSchema';
import { CreateInvoiceFormValues, InvoiceDateOptions, InvoiceDueDateOptions } from './types';
import { useTranslation } from '@/components/I18N';
import { DEFAULT_PAYMENT_METHOD } from '@/utils';
import { getIncomingInvoiceDate, getIncomingInvoiceDueDate } from './parts/getIncomingInvoiceDates';
import NewInvoiceForm from './NewInvoiceForm';

type NewInvoiceProps = {
  withPeview?: boolean;
  invoice?: Invoice;
  invoiceDocumentType?: Invoice_Document_Type;
  strictDocumentType?: boolean;
};

const NewInvoice = forwardRef(
  ({ withPeview = true, invoice, invoiceDocumentType, strictDocumentType }: NewInvoiceProps, ref) => {
    const location = useLocation();
    const { t } = useTranslation();
    const [amountWithTax, setAmountWithTax] = useState<number | undefined>();
    const [isFirstSubmitAttempt, setIsFirstSubmitAttempt] = useState(false);

    let initialIncomingValues: CreateInvoiceFormValues | null = null;

    const isCreditNote = invoiceDocumentType === Invoice_Document_Type.CreditNote;
    const isDebitNote = invoiceDocumentType === Invoice_Document_Type.DebitNote;
    const isDraftInvoice = invoice?.status === Invoice_Status.Draft;
    const isEditable = isDraftInvoice || isCreditNote || isDebitNote;

    if (isEditable && invoice) {
      initialIncomingValues = {
        invoiceType: invoice.documentType!,
        invoiceFormCustomer: normilizeInvoiceCustomerToCustomer(invoice?.customer),
        notes: invoice.notes || '',
        invoiceItems:
          invoice?.items?.map((item) => {
            return normalizeInvoiceItemToItem(item!);
          }) || [],
        discountSwitch: !!invoice.summary?.globalDiscountPercent || !!invoice.summary?.globalDiscountAmount,
        discountType: invoice.summary?.globalDiscountType,
        invoiceDate: getIncomingInvoiceDate({
          isCreditNote,
          isDebitNote,
          isDraftInvoice,
          invoiceIssueDate: invoice.date,
        }),
        dueDate: getIncomingInvoiceDueDate({
          isCreditNote,
          isDraftInvoice,
          invoiceDueDate: invoice.dueDate,
          invoiceIssueDate: invoice.date,
        }),
        scheduled: false,
        discountPercent: invoice.summary?.globalDiscountPercent || undefined,
        discountAmount: invoice.summary?.globalDiscountAmount || undefined,
        paymentMethod: invoice.payment?.method || '',
        reason: invoice.reason || '',
        reasonType: '',
      };
    }

    const fwprops = { withPeview, invoice, invoiceDocumentType, strictDocumentType, ref };

    return (
      <>
        <Formik<CreateInvoiceFormValues>
          enableReinitialize
          initialValues={
            initialIncomingValues
              ? initialIncomingValues
              : {
                  invoiceType: location?.state?.invoiceType || Invoice_Document_Type.Invoice,
                  invoiceFormCustomer: null,
                  invoiceItems: [],
                  notes: '',
                  invoiceDate: InvoiceDateOptions.Immediately,
                  dueDate: InvoiceDueDateOptions.InAWeek,
                  scheduled: false,
                  discountSwitch: false,
                  reason: '',
                  reasonType: '',
                  paymentMethod: DEFAULT_PAYMENT_METHOD,
                  discountType: Discount_Type.Percent,
                  discountPercent: undefined,
                  discountAmount: undefined,
                }
          }
          validationSchema={validationSchema(isCreditNote, isDebitNote, t, amountWithTax)}
          onSubmit={() => {}}
          validateOnBlur={isFirstSubmitAttempt}
          validateOnChange={isFirstSubmitAttempt}
        >
          <NewInvoiceForm
            {...fwprops}
            setAmountWithTax={setAmountWithTax}
            setIsFirstSubmitAttempt={setIsFirstSubmitAttempt}
          />
        </Formik>
      </>
    );
  }
);

export default NewInvoice;
