import { ReactNode } from 'react';

import { cn, convertPriceToFromCents, formatter } from '@/utils';

type SummaryItemProps = {
  title: ReactNode;
  amount?: number;
  className?: string;
  color?: string;
};

const SummaryLine = ({ title, amount = 0, color = 'text-bordo-black', className }: SummaryItemProps) => {
  return (
    <div className={cn('flex justify-between text-[14px]', className, color)}>
      <span>{title}</span>
      <span>
        {formatter({
          valueToFormat: convertPriceToFromCents(amount, true),
        })}
      </span>
    </div>
  );
};

export default SummaryLine;
