import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { showErrorToast, showSuccessToast } from '@/components/Toast';
import {
  useIssueInvoiceMutation,
  useDeleteInvoiceMutation,
  useMarkInvoicePaidMutation,
  useCancelInvoiceMutation,
  // GetInvoicesDocument,
} from '@/graphql-operations';
import { Invoice_Document_Type, Invoice_Status } from '@/graphql-types';
import { translateInvoiceDocumentType } from '@/utils/translateInvoiceDocumentType';

type Props = {
  documentType: Invoice_Document_Type | undefined;
  markAsPaidModalClose: () => void;
};

const useMiniInvoiceCardMutations = (
  documentType: Props['documentType'],
  markAsPaidModalClose: Props['markAsPaidModalClose']
) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const translatedDocumentType = documentType ? translateInvoiceDocumentType(documentType, t) : '';

  const [issueDraftInvoice, { loading: issueDraftInvoiceLoading }] = useIssueInvoiceMutation({
    onError: (error) => {
      showErrorToast({ title: t('error.createInvoice'), message: error.message });
      return;
    },
    onCompleted: () => {
      showSuccessToast({
        title: t('label.invoiceCreated', { type: translatedDocumentType }),
        message: t('label.invoiceCreatedSuccessfully', { type: translatedDocumentType }),
      });
      navigate('/invoices');
    },
    // refetchQueries: [GetInvoicesDocument],
  });

  const [deleteInvoice, { loading: deleteInvoiceLoading }] = useDeleteInvoiceMutation({
    onError: (error) => {
      showErrorToast({ title: t('error.deleteInvoice'), message: error.message });
      return;
    },
    onCompleted: (data) => {
      const successToastTitle =
        data.deleteInvoice.status === Invoice_Status.Draft
          ? t('label.draftDeleted')
          : t('label.invoiceDeleted', { type: translatedDocumentType });

      const successToastMessage =
        data.deleteInvoice.status === Invoice_Status.Draft
          ? t('label.draftDeletedSuccessfully')
          : t('success.invoiceDeleted', { type: translatedDocumentType });

      showSuccessToast({
        title: successToastTitle,
        message: successToastMessage,
      });
      navigate('/invoices');
    },
    // refetchQueries: [GetInvoicesDocument],
  });

  const [markInvoicePaid, { loading: markInvoicePaidLoading }] = useMarkInvoicePaidMutation({
    onError: (error) => {
      showErrorToast({ title: t('error.markAsPaid'), message: error.message });
      return;
    },
    onCompleted: () => {
      markAsPaidModalClose();
      showSuccessToast({ title: t('label.markAsPaid'), message: t('success.invoiceMarkedAsPaid') });
    },
    // refetchQueries: [GetInvoicesDocument],
  });

  const [cancelInvoice, { loading: cancelInvoiceLoading }] = useCancelInvoiceMutation({
    onError: (error) => {
      showErrorToast({ title: t('error.cancelInvoice'), message: error.message });
      return;
    },
    onCompleted: () => {
      showSuccessToast({ title: t('label.cancelInvoice'), message: t('success.invoiceCancelled') });
    },
    // refetchQueries: [GetInvoicesDocument],
  });
  return {
    issueDraftInvoice,
    issueDraftInvoiceLoading,
    deleteInvoice,
    deleteInvoiceLoading,
    markInvoicePaid,
    markInvoicePaidLoading,
    cancelInvoice,
    cancelInvoiceLoading,
  };
};

export default useMiniInvoiceCardMutations;
