import { useTranslation } from 'react-i18next';
import { InvoiceCardSubHeaderProps } from '../types';
import cn from 'classnames';
import IconCalendar from '../../../../assets/icons/IconCalendar.svg?react';
import IconWatch from '../../../../assets/icons/IconWatch.svg?react';
import { Customer, Invoice_Document_Type, Invoice_Status } from '@/graphql/types';
import { DEFAULT_COUNTRY } from '@/utils';
import { CustomerAvatar } from '@/components/Customers/CustomerAvatar/CustomerAvatar';
import _omit from 'lodash/omit';
import { isOverdueInvoice } from '@/utils/dates';
import { useReactiveVar } from '@apollo/client';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import InfoCard from '@/components/InfoCard/InfoCard';
const SubHeader = ({ issuedDate, dueDate, canceledDate, to, customer, invoice }: InvoiceCardSubHeaderProps) => {
  const { t } = useTranslation();
  const tenant = useReactiveVar(tenantVar);
  const getCustomerInfo = (customer: Customer) => {
    if (customer.country === DEFAULT_COUNTRY) {
      return customer.taxpayerNumber ? `${customer.country} ${customer.taxpayerNumber}` : t('label.noNif');
    } else if (customer.country !== DEFAULT_COUNTRY) {
      return `${customer.country} ${customer.taxpayerNumber}`;
    }
    return customer.country;
  };
  let showDueDate = false;
  let showCanceledDate = false;
  let hideSecondDate = false;
  if (invoice.documentType === Invoice_Document_Type.Invoice) {
    if ([Invoice_Status.Issued, Invoice_Status.Paid].includes(invoice.status!)) {
      showDueDate = true;
    }
    if (invoice.status === Invoice_Status.Canceled) {
      showCanceledDate = true;
    }
  }
  if ([Invoice_Document_Type.InvoiceReceipt, Invoice_Document_Type.SimplifiedInvoice].includes(invoice.documentType!)) {
    if (invoice.status === Invoice_Status.Paid) {
      hideSecondDate = true;
    }
    showCanceledDate = true;
  }
  if (invoice.documentType === Invoice_Document_Type.CreditNote) {
    if (invoice.status === Invoice_Status.Issued) {
      hideSecondDate = true;
    }
    if (invoice.status === Invoice_Status.Canceled) {
      showCanceledDate = true;
    }
  }
  const infoCardCount = hideSecondDate ? 2 : 3;
  const dueDateBlock = showDueDate && (
    <InfoCard
      icon={<IconWatch className="fill-deep-gray h-5 w-5" />}
      iconClassName="bg-deep-angel"
      title={
        <span
          className={cn({
            'text-bright-red':
              invoice.status === Invoice_Status.Issued && isOverdueInvoice(invoice.dueDate!, tenant!.timezone),
          })}
        >
          {dueDate}
        </span>
      }
      description={t('label.due')}
    />
  );
  const canceledDateBlock = showCanceledDate && (
    <InfoCard
      icon={<IconWatch className="fill-deep-gray h-5 w-5" />}
      iconClassName="bg-deep-angel"
      title={<span>{canceledDate}</span>}
      description={t('label.canceled')}
    />
  );
  return (
    <>
      {/* <h2 className="pb-3 text-[15px] font-medium">{t('page.invoicePage.title_other', { count: 0 })}</h2> */}
      <div
        className={cn({
          'grid grid-rows-2 xl:flex xl:justify-between': infoCardCount === 3,
          'flex justify-between': infoCardCount === 2,
        })}
      >
        <div
          className={cn('my-auto flex max-w-full', {
            'border-b border-solid border-medium-vanilla pb-2 xl:justify-start xl:border-none xl:pb-0':
              infoCardCount === 3,
            'justify-start pb-0': infoCardCount === 2,
          })}
        >
          <InfoCard
            icon={<CustomerAvatar className="fill-deep-gray" customer={_omit(to, ['id'])} size={40} radius={12} />}
            title={to.name}
            description={getCustomerInfo(customer)}
          />
        </div>
        <div className="flex justify-between space-x-6 pt-2 text-start xl:justify-end xl:pt-0">
          <InfoCard
            icon={<IconCalendar className="fill-deep-gray h-5 w-5" />}
            iconClassName="bg-deep-angel"
            title={issuedDate}
            description={t('label.issued')}
          />
          {!hideSecondDate && dueDateBlock}
          {!hideSecondDate && canceledDateBlock}
        </div>
      </div>
    </>
  );
};
export default SubHeader;
