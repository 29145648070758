import * as Yup from 'yup';

export const validationSchema = Yup.object({
  paymentMethod: Yup.string().required('Payment method is required'),
  isPaidInFull: Yup.boolean(),
  isPaidPartially: Yup.boolean(),
  isPaidToday: Yup.boolean(),
  isChooseDate: Yup.boolean(),
  dateOfPayment: Yup.string().when('isChooseDate', {
    is: true,
    then: () => Yup.string().required('Date of payment is required'),
    otherwise: () => Yup.string().notRequired(),
  }),
  amountPaid: Yup.number().when(['isPaidInFull', 'isPaidPartially'], {
    is: (isPaidInFull: boolean, isPaidPartially: boolean) => isPaidInFull || isPaidPartially,
    then: () =>
      Yup.number()
        .required('Amount paid is required')
        .positive()
        .test('is-not-greater', 'Amount paid cannot be greater than total', function (value) {
          const { totalRef } = this.options.context as { totalRef: number };
          return value <= totalRef;
        }),
    otherwise: () => Yup.number().notRequired(),
  }),
  totalRef: Yup.number().notRequired(),
});
