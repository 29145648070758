import { TFunction } from 'i18next';

import { Invoice_Document_Type, Invoice_Status } from '@/graphql/types';

export const titleOfTotal = (t: TFunction, status: Invoice_Status, invoiceType: Invoice_Document_Type) => {
  if (status === Invoice_Status.Issued && invoiceType === Invoice_Document_Type.Invoice) {
    return t('label.totalToPay');
  }

  if (status === Invoice_Status.Paid) {
    return t('label.totalPaid');
  }

  if (invoiceType === Invoice_Document_Type.CreditNote) {
    return t('label.totalToCredit');
  }

  return t('label.totalAmount');
};
