import { Card, Stack } from '@mantine/core';
import { InvoiceCardSubHeader, InvoiceCardBody, InvoiceCardRelatedDocuments } from './parts';
import { InvoiceCardProps } from './types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '@/components/I18N';
import CreateInvoiceElementWrapper from '../Create/parts/CreateInvoiceElementWrapper';
import IconFileBlack from '@/assets/icons/IconFileBlack.svg?react';

const InvoiceContent: React.FC<InvoiceCardProps> = ({
  subHeaderProps,
  bodyProps,
  parentDocument,
  relatedDocuments,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <div className="grid-row-3 grid gap-3 xs:gap-8">
        <CreateInvoiceElementWrapper>
          <InvoiceCardSubHeader
            issuedDate={subHeaderProps.issuedDate}
            dueDate={subHeaderProps.dueDate}
            canceledDate={subHeaderProps.canceledDate}
            to={subHeaderProps.to}
            customer={subHeaderProps.customer}
            invoice={subHeaderProps.invoice}
          />
        </CreateInvoiceElementWrapper>

        <Card className="bg-white-vanilla px-5 pb-2.5 pt-5">
          <InvoiceCardBody
            amountSubtotal={bodyProps.amountSubtotal}
            currencyCode={bodyProps.currencyCode}
            amountTax={bodyProps.amountTax}
            amountTotal={bodyProps.amountTotal}
            discount={bodyProps.discount}
            items={bodyProps.items}
            invoiceData={bodyProps.invoiceData}
          />
        </Card>

        {!!parentDocument && (
          <Stack gap="lg">
            <div className="flex gap-2.5">
              <div className="pt-[9px]">
                <IconFileBlack />
              </div>
              <span className="text-[26px] font-bold text-black-magic">{t('label.parentDocument')}</span>
            </div>
            <InvoiceCardRelatedDocuments
              key={parentDocument.id}
              invoice={parentDocument}
              currencyCode={parentDocument.currency ?? ''}
              onDocumentClick={() => navigate(`/invoices/${parentDocument.id}`)}
            />
          </Stack>
        )}

        {!!relatedDocuments?.length && (
          <Stack gap="lg">
            <div className="flex gap-2.5">
              <div className="pt-[9px]">
                <IconFileBlack />
              </div>
              <span className="text-[26px] font-bold text-black-magic">{t('label.relatedDocuments')}</span>
            </div>
            {relatedDocuments.map((invoice) => (
              <InvoiceCardRelatedDocuments
                key={invoice.id}
                invoice={invoice}
                currencyCode={invoice.currency ?? ''}
                onDocumentClick={() => navigate(`/invoices/${invoice.id}`)}
              />
            ))}
          </Stack>
        )}
      </div>
    </>
  );
};

export default InvoiceContent;
