import { Group, InputError,useCombobox } from '@mantine/core';
import { useDebouncedValue, useScrollIntoView } from '@mantine/hooks';
import { useField, useFormikContext } from 'formik';
import compact from 'lodash/compact';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DashedButton } from '@/components/common/DashedButton/DashedButton';
import CustomModal from '@/components/CustomModal';
import CustomSearchCombobox from '@/components/CustomSearchCombobox';
import CreateNewItemForm from '@/components/Items/CreateNewItemForm/CreateNewItemForm';
import { showErrorToast } from '@/components/Toast';
import { PreviewInvoiceQueryQuery, useGetItemsQuery } from '@/graphql-operations';
import { Invoice_Document_Type, InvoiceItem, Item, Item_Type, Item_Unit_Type,ItemInput } from '@/graphql-types';
import { convertPriceToFromCents, formatter } from '@/utils';
import { ExtendedItem } from '@/utils/normalizeData';

import { CreateInvoiceFormValues, FormItem, SELECTION_PREVIEW_VARIANT } from '../types';
import QuantityInput from './QuantityInput';
import SelectionPreviewItems from './SelectionPreviewItems';

type FormItemsProps = {
  fieldName: string;
  canAddItem?: boolean;
  invoiceDocumentType?: Invoice_Document_Type;
  totalsPreviewData: PreviewInvoiceQueryQuery | undefined;
  isPreviewLoading: boolean;
  originalInvoiceItems?: (InvoiceItem | undefined)[];
  handleTotalsOnItemsChange: (items: Partial<FormItem>[]) => void;
};

const FormItems = ({
  fieldName,
  canAddItem = true,
  invoiceDocumentType,
  totalsPreviewData,
  isPreviewLoading,
  originalInvoiceItems,
  handleTotalsOnItemsChange,
}: FormItemsProps) => {
  // const isSmScreen = useMediaQuery('(max-width: 575px)');
  const [isMobileItemsDrawerOpened, setMobileItemsDrawerOpened] = useState(false);
  const { t } = useTranslation();
  const isCreditNote = invoiceDocumentType === Invoice_Document_Type.CreditNote;
  const isDebitNote = invoiceDocumentType === Invoice_Document_Type.DebitNote;
  const isCreditDebitNote =
    invoiceDocumentType === Invoice_Document_Type.CreditNote || invoiceDocumentType === Invoice_Document_Type.DebitNote;

  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 60,
  });
  const { setFieldValue, errors, setFieldError, values } = useFormikContext<CreateInvoiceFormValues>();

  const [field] = useField<FormItem[]>(fieldName);

  const [search, setSearch] = useState('');
  const [{ item: itemToEdit, index: itemToEditIndex }, setItemToEdit] = useState<{
    item?: ExtendedItem;
    index?: number;
  }>({});
  const [fetchedItemsData, setFetchedItemsData] = useState<Item[]>([]);

  const isEditing = !!itemToEdit;
  const modalTitle = isEditing ? t('modal.editItem.title') : t('modal.newItem.title');

  const [debouncedSearch] = useDebouncedValue(search, 500);
  const [opened, setOpened] = useState(false);

  const { loading: getItemsQueryLoading, fetchMore } = useGetItemsQuery({
    variables: {
      searchParams: { search: debouncedSearch },
      params: { limit: 5 },
      excludeIds: compact(field.value.map((item) => item.ref || item.id)),
    },
    onCompleted(data) {
      setFetchedItemsData(data.items);
    },
    onError(err) {
      showErrorToast({
        title: t('error.gettingItems'),
        description: err.message,
      });
    },
    fetchPolicy: 'network-only',
    skip: !canAddItem,
  });

  const handleFetchMoreItems = async () => {
    // if item is now an InvoiceItem: example in debit note or draft inv - we use ref to exclude it from search
    const excludeIds = compact(field.value.map((item) => item.ref || item.id));

    const { data } = await fetchMore({
      variables: {
        params: { limit: 5 },
        excludeIds,
        searchParams: { search: debouncedSearch },
      },
    });

    setFetchedItemsData(data.items);
  };

  const combobox = useCombobox({
    onDropdownOpen: handleFetchMoreItems,
  });

  const isDeleteItemIconVisible = isCreditNote || isDebitNote ? field.value.length > 1 : true;

  const handleAddEditItemModalClose = () => {
    setOpened(false);
    setItemToEdit({ item: undefined, index: undefined });
  };

  const handleNewItemSuccess = async (newItem: ItemInput) => {
    setSearch('');

    const newItemWithDefaultQuantity = {
      ...newItem,
      // TODO: change this to newItem.quantity || 1 when we move the quantity field to the item modal
      quantity: itemToEdit?.quantity || 1,
      // unitType: newItem.unitType || Item_Unit_Type.Unit,
    };

    if (itemToEdit) {
      // const valuesWithoutOriginalItemBeforeEdit = field.value.filter((v) => v.id !== itemToEdit.id);
      // const newEditedItemFieldValues = [
      //   ...valuesWithoutOriginalItemBeforeEdit,
      //   { ...newItemWithDefaultQuantity, ref: itemToEdit.ref },
      // ];
      // field.value.toSpliced(); IS NOT SUPPORTED BY OUR TS VERSION
      const newEditedItemFieldValues = JSON.parse(JSON.stringify(field.value)) as FormItem[];

      // @ts-expect-error i can't...
      newEditedItemFieldValues.splice(itemToEditIndex!, 1, newItemWithDefaultQuantity);

      await setFieldValue(fieldName, newEditedItemFieldValues);
      handleTotalsOnItemsChange(newEditedItemFieldValues);
      setItemToEdit({ item: undefined, index: undefined });
      setFieldError(fieldName, '');
    } else {
      const newFieldValues = [...field.value, newItemWithDefaultQuantity];
      await setFieldValue(fieldName, newFieldValues);
      handleTotalsOnItemsChange(newFieldValues);
    }

    setOpened(false);
  };

  const handleComboboxValueChange = async (val: string | null) => {
    if (val === '$create') {
      setMobileItemsDrawerOpened(false);
      setItemToEdit({ item: undefined, index: undefined });
      setOpened(true);
    } else {
      setSearch('');
      const fullItemObjectFromValue = fetchedItemsData.find((obj) => val === obj.id)!;
      const fullItemObjectFromValueWithDefaultQuantity = {
        ...fullItemObjectFromValue,
        quantity: 1,
        unitType: fullItemObjectFromValue.unitType || Item_Unit_Type.Unit,
      };

      const newFieldValues = [...field.value, fullItemObjectFromValueWithDefaultQuantity];

      await setFieldValue(fieldName, newFieldValues);

      handleTotalsOnItemsChange(newFieldValues);
      // Remove the selected item from fetchedItemsData
      setFetchedItemsData((prev) => {
        const filteredItems = prev.filter((item) => item.id !== fullItemObjectFromValue.id);
        return filteredItems;
      });

      setFieldError(fieldName, '');
      scrollIntoView();
    }
    combobox.closeDropdown();
    handleMobileItemsDrowerClose();
  };

  const formattedItemsData =
    fetchedItemsData.map((item) => ({
      label: item.name!,
      value: item.id,
      amount: formatter({ valueToFormat: convertPriceToFromCents(item.unitPrice || 0, true) }),
    })) || [];

  const handleEditClick = (item: Item, index: number) => {
    setItemToEdit({ item, index });
    setOpened(true);
  };

  const getItemSearchFieldError = () => {
    if (Array.isArray(errors.invoiceItems) && errors.invoiceItems.length > 0) {
      return undefined;
    }

    return errors.invoiceItems as string;
  };

  const handleMobileItemsDrowerOpen = () => {
    handleFetchMoreItems();
    setMobileItemsDrawerOpened(true);
  };

  const handleMobileItemsDrowerClose = () => {
    setSearch('');
    setMobileItemsDrawerOpened(false);
  };

  const getItemElement = () => {
    const shouldShowAddItemButton = fetchedItemsData.length === 0 && !debouncedSearch && canAddItem;
    // const shouldShowItemDropdown = !shouldShowAddItemButton && canAddItem;
    // const isViewingOnSmallScreen = isSmScreen;

    if (!shouldShowAddItemButton) {
      return (
        <>
          {!isCreditDebitNote && (
            <>
              <DashedButton className="my-2 sm:my-0" onClick={handleMobileItemsDrowerOpen}>
                {t('button.label.addAnItem')}
              </DashedButton>
              {getItemSearchFieldError() && <InputError size="md">{getItemSearchFieldError()}</InputError>}
            </>
          )}
          <CustomModal
            title={t('button.label.addAnItem')}
            isOpened={isMobileItemsDrawerOpened}
            handleClose={handleMobileItemsDrowerClose}
            onOpenChange={setMobileItemsDrawerOpened}
          >
            <CustomSearchCombobox
              targetRef={targetRef}
              fieldName={fieldName}
              comboboxInstance={combobox}
              setSearch={setSearch}
              search={search}
              placeholder={t('placeholder.searchForItems')}
              loading={getItemsQueryLoading}
              formattedOptions={formattedItemsData}
              handleValueChange={handleComboboxValueChange}
              // fieldError={getItemSearchFieldError()}
              searchName={t('dropdown.label.item')}
              mode="mobile"
              minOptionsToShowSearch={5}
            />
          </CustomModal>
        </>
      );
    }

    // if (!isViewingOnSmallScreen && shouldShowItemDropdown) {
    //   return (
    //     <CustomSearchCombobox
    //       targetRef={targetRef}
    //       fieldName={fieldName}
    //       comboboxInstance={combobox}
    //       setSearch={setSearch}
    //       search={search}
    //       placeholder={t('placeholder.searchForItems')}
    //       loading={getItemsQueryLoading}
    //       formattedOptions={formattedItemsData}
    //       handleValueChange={handleComboboxValueChange}
    //       fieldError={getItemSearchFieldError()}
    //       searchName={t('dropdown.label.item')}
    //     />
    //   );
    // }

    if (shouldShowAddItemButton && !getItemsQueryLoading) {
      return (
        <>
          <DashedButton className="my-2 sm:my-0" onClick={() => setOpened(true)}>
            {t('button.label.addAnItem')}
          </DashedButton>
          {getItemSearchFieldError() && <InputError size="md">{getItemSearchFieldError()}</InputError>}
        </>
      );
    }
  };

  return (
    <>
      <CustomModal
        isOpened={opened}
        handleClose={handleAddEditItemModalClose}
        title={modalTitle}
        onOpenChange={setOpened}
      >
        <CreateNewItemForm
          isEditing={!!itemToEdit}
          disabledFields={
            isCreditDebitNote
              ? {
                  name: true,
                  taxRate: true,
                  unitType: true,
                  vatRate: true,
                  type: true,
                  vatExemptionReason: true,
                  withholdingTaxAvailable: true,
                  withholdingTaxPercent: true,
                }
              : {}
          }
          onSuccess={handleNewItemSuccess}
          isUpdatingInvoiceItem
          defaultFormValues={
            itemToEdit
              ? {
                  name: itemToEdit?.name || search,
                  taxRate: itemToEdit?.taxRate || 0,
                  // do not convert the price to cents when editing an item (it's already in cents)
                  unitPrice: itemToEdit?.unitPrice,
                  unitType: itemToEdit?.unitType || Item_Unit_Type.Unit,
                  vatRate: itemToEdit?.vatRate || undefined,
                  type: itemToEdit?.type || Item_Type.Service,
                  vatExemptionReason: itemToEdit?.vatExemptionReason,
                  withholdingTaxAvailable: itemToEdit?.withholdingTaxAvailable,
                  withholdingTaxPercent: itemToEdit?.withholdingTaxPercent,
                  withholdingTaxType: itemToEdit?.withholdingTaxType,
                  withholdingTaxReason: itemToEdit?.withholdingTaxReason,
                }
              : undefined
          }
          updateItemId={itemToEdit?.ref || itemToEdit?.id}
        />
      </CustomModal>
      {field.value?.length > 0 && (
        <div className="grid grid-cols-1 divide-y *:py-4 first:*:pt-0 last:*:pb-0">
          {field.value.map((item, fieldValueIndex) => {
            const previewItem = totalsPreviewData?.previewInvoice.items?.find((pItem, previewItemIndex) => {
              if (!pItem?.ref) {
                return fieldValueIndex === previewItemIndex;
              }
              return pItem!.ref === (item.ref || item.id);
            });

            return (
              <SelectionPreviewItems
                isPreviewLoading={isPreviewLoading}
                key={item.id}
                stack1Rows={[
                  item.name,
                  `${item.quantity} x ${formatter({
                    valueToFormat: convertPriceToFromCents(item.unitPrice || 0, true),
                  })}`,
                  `${item.taxRate}% ${t('label.vat')}`,
                  <>{item.withholdingTaxAvailable && `${item.withholdingTaxPercent}% ${t('label.withholdingTax')}`}</>,
                  // `${t('label.unitType')}: ${item.unitType || Item_Unit_Type.Unit}`,
                ]}
                stack3Rows={[
                  //@ts-ignore
                  <Group>
                    <QuantityInput
                      name={`invoiceItems.${fieldValueIndex}.quantity`}
                      currentFieldValue={field.value[fieldValueIndex].quantity || 1}
                      setFieldValue={async (field, value) => {
                        // hackerman fix for the quantity input to get total preview
                        const currentItemBeingUpdated = values.invoiceItems?.[fieldValueIndex];
                        const updatedItem = { ...currentItemBeingUpdated, quantity: value };
                        const replaceExistingItemWithUpdatedItemInValues = values.invoiceItems?.map((item, i) =>
                          i === fieldValueIndex ? updatedItem : item
                        );

                        await setFieldValue(field, value);
                        handleTotalsOnItemsChange(replaceExistingItemWithUpdatedItemInValues);
                      }}
                      min={1}
                      max={isCreditNote ? originalInvoiceItems?.[fieldValueIndex]?.meta?.quantity : undefined}
                      width={70}
                      //@ts-ignore
                      error={errors?.invoiceItems?.[fieldValueIndex]?.quantity}
                    />
                  </Group>,
                ]}
                stack2Rows={[
                  isCreditDebitNote
                    ? formatter({
                        valueToFormat: convertPriceToFromCents(previewItem?.meta?.amountWithTax || 0, true),
                      })
                    : formatter({
                        valueToFormat: convertPriceToFromCents(
                          previewItem?.meta?.amountWithoutTaxWithoutDiscount || 0,
                          true
                        ),
                      }),
                  isCreditDebitNote
                    ? formatter({
                        valueToFormat: convertPriceToFromCents(previewItem?.meta?.amountWithoutTax || 0, true),
                      })
                    : '',
                  isCreditDebitNote
                    ? formatter({
                        valueToFormat: convertPriceToFromCents(previewItem?.meta?.taxAmount || 0, true),
                      })
                    : '',
                ]}
                variant={
                  isDeleteItemIconVisible ? SELECTION_PREVIEW_VARIANT.EDIT_REMOVE : SELECTION_PREVIEW_VARIANT.EDIT
                }
                handleDeleteClick={async () => {
                  setSearch('');
                  const newFieldValuesAfterDelete = JSON.parse(JSON.stringify(field.value)) as FormItem[];

                  newFieldValuesAfterDelete.splice(fieldValueIndex, 1);

                  await setFieldValue(fieldName, newFieldValuesAfterDelete);
                  handleTotalsOnItemsChange(newFieldValuesAfterDelete);

                  const { data } = await fetchMore({
                    variables: {
                      params: { limit: 5 },
                      excludeIds: compact(newFieldValuesAfterDelete.map((item) => item.id)),
                      searchParams: { search: debouncedSearch },
                    },
                  });
                  setFetchedItemsData(data.items);
                }}
                handleEditClick={() => handleEditClick(item, fieldValueIndex)}
              />
            );
          })}
        </div>
      )}
      {getItemElement()}
    </>
  );
};

export default FormItems;
