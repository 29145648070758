import { Group, Stack, UnstyledButton } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import IconArrowLeftBlack from '@/assets/icons/IconArrowLeftBlack.svg?react';
import { cn } from '@/utils';

import { InvoiceStatsSkeleton } from './InvoiceStatsSkeleton';

export type InvoiceStatsProps = {
  statusColor?: string;
  statusLabel?: string;
  textColor?: string;
  subtotalValue?: string;
  totalVat?: string;
  statusTotal?: string;
  statusCount?: number;
  isSelected: boolean;
  hideTotals?: boolean;
  loading: boolean;
  onClick: () => void;
};

const InvoiceStats = ({
  statusColor,
  statusLabel,
  textColor = 'text-white',
  subtotalValue,
  statusCount,
  totalVat,
  statusTotal,
  isSelected,
  hideTotals,
  loading,
  onClick,
}: InvoiceStatsProps) => {
  const { t } = useTranslation();

  if (loading) {
    return <InvoiceStatsSkeleton isSelected={isSelected} hideTotals={hideTotals} />;
  }

  return (
    <UnstyledButton
      className={cn('flex cursor-pointer rounded-[20px] bg-white-vanilla md:rounded-[24px]', {
        'outline outline-[3px] outline-moroccan-blue': isSelected,
      })}
      onClick={onClick}
    >
      <Stack className="w-full p-4 sm:px-5" gap={16}>
        <div className="flex justify-between">
          <Group gap={8}>
            <span className={`rounded-[100px] px-3 text-[14px] font-semibold ${textColor} ${statusColor}`}>
              {statusCount || 0}
            </span>
            <span className="text-[17px] font-bold text-dark-resin">{statusLabel}</span>
          </Group>
          <span className="my-auto hidden text-[17px] font-bold text-black-magic xs:block">{statusTotal}</span>
          <IconArrowLeftBlack className="my-auto xs:hidden" />
        </div>
        <div
          className={cn('hidden grid-cols-2 grid-rows-2', {
            'md:grid': !hideTotals,
          })}
        >
          <span className="text-[14px] font-normal text-dark-bronze">{t('label.subtotal')}</span>
          <span className="text-end text-[14px] font-normal text-dark-bronze">{subtotalValue}</span>
          <span className="text-[14px] font-normal text-dark-bronze">{t('label.totalVat')}</span>
          <span className="text-end text-[14px] font-normal text-dark-bronze">{totalVat}</span>
        </div>
      </Stack>
    </UnstyledButton>
  );
};

export default InvoiceStats;
