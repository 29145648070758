import { Badge } from '@mantine/core';
import { cn } from '@/utils';
import { Invoice_Document_Type, Invoice_Status } from '@/graphql-types';
import { translateInvoiceStatus } from '@/utils/translateInvoiceStatus';
import { useReactiveVar } from '@apollo/client';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { isOverdueInvoice } from '@/utils/dates';
import { useTranslation } from 'react-i18next';

type InvoiceStatusProps = {
  status: Invoice_Status;
  dueDate?: string;
  invoiceType: Invoice_Document_Type;
};

const InvoiceStatus = ({ status, dueDate, invoiceType }: InvoiceStatusProps) => {
  const { t } = useTranslation();
  const tenant = useReactiveVar(tenantVar);
  const isDraft = status === Invoice_Status.Draft;
  const isProcessing = status === Invoice_Status.Processing;
  const isPending = status === Invoice_Status.Created;
  const isPaid = status === Invoice_Status.Paid;
  const isCancelled = status === Invoice_Status.Canceled;
  const isUnpaid = status === Invoice_Status.Issued;

  const hasPastDueDate = dueDate ? isOverdueInvoice(dueDate, tenant!.timezone) : false;
  const canBePaid = [
    Invoice_Document_Type.Invoice,
    Invoice_Document_Type.CreditNote,
    Invoice_Document_Type.DebitNote,
  ].includes(invoiceType);
  const isOverdue = hasPastDueDate && !isDraft && !isPaid && canBePaid;

  const isCreditNote = invoiceType === Invoice_Document_Type.CreditNote;
  const isDebitNote = invoiceType === Invoice_Document_Type.DebitNote;
  const isCreditOrDebitNote = isCreditNote || isDebitNote;

  let translatedStatus = translateInvoiceStatus(status, t);
  let backgroundColor = '';

  if (isCancelled) {
    translatedStatus = t('status.canceled');
    backgroundColor = '#ff4f4f';
  } else if (isCreditOrDebitNote && (status === Invoice_Status.Issued || isOverdue)) {
    translatedStatus = t('status.issued');
    backgroundColor = '#FFC850';
  } else if (isOverdue) {
    translatedStatus = t('status.overdue');
    backgroundColor = '#ffa662';
  }

  return (
    <Badge
      variant="filled"
      radius="md"
      className={cn(
        'min-w-max px-2 text-[11px] font-bold capitalize text-white',
        {
          'bg-dark-bronze': isDraft,
        },
        {
          'bg-light-green': isPaid,
        },
        {
          'bg-dark-lemon': isPending,
        },
        {
          'bg-light-red': isCancelled,
        },
        {
          'bg-light-brand text-black': isUnpaid || isProcessing,
        },
        {
          'bg-dark-chestnut text-white': isOverdue,
        }
      )}
      style={{ backgroundColor }}
    >
      {translatedStatus}
    </Badge>
  );
};

export default InvoiceStatus;
