import { ApolloError, FetchResult, useReactiveVar } from '@apollo/client';
import { Grid, LoadingOverlay, Space, Stack, Text } from '@mantine/core';
import { useDisclosure, useMediaQuery, useViewportSize } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { Form, FormikHelpers, FormikProps, useFormikContext } from 'formik';
import _omit from 'lodash/omit';
import { forwardRef, Suspense, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AdditionalTenantInfo from '@/components/AdditionalTenantInfo/AdditionalTenantInfoForm/AdditionalTenantInfoForm';
import { Button } from '@/components/ButtonVariants';
import { CardBlock } from '@/components/common/CardBlock/CardBlock';
import CustomModal from '@/components/CustomModal';
import { FormikInput, FormikSelect } from '@/components/FormikWrapped';
import FormikTextareaInput from '@/components/FormikWrapped/FormikTextareaInput';
import { FormikCleanInput } from '@/components/FormikWrapped/new';
import { useTranslation } from '@/components/I18N';
import { ConnectWithATOptions } from '@/components/Settings/AtCommunication/Credentials/ConnectWithATOptions';
import { showErrorToast, showSuccessToast } from '@/components/Toast';
import { client } from '@/config/ApolloClientProvider';
// import IconTrashCan from '@/assets/icons/clean/IconTrashCan.svg?react';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import {
  CreateDraftInvoiceMutation,
  IssueInvoiceMutation,
  TenantDocument,
  TenantQuery,
  TenantQueryVariables,
  UpdateInvoiceMutation,
  useDeleteInvoiceMutation,
  usePreviewInvoiceQueryLazyQuery,
} from '@/graphql-operations';
import {
  Discount_Type,
  Invoice,
  Invoice_Aggregated_Status,
  Invoice_Document_Type,
  Invoice_Status,
  Invoice_Sync_With_At_Status,
  InvoiceInput,
  InvoicePreviewInput,
  Item_Unit_Type,
  Tenant,
  Tenant_Pt_Territory,
} from '@/graphql-types';
import { cn, PT_POSTAL_CODE_REGEX } from '@/utils';
import {
  normilizeInvoiceCustomerToCustomer,
  normilizeInvoiceDate,
  normilizeInvoiceDueDate,
} from '@/utils/normalizeData';
import { scrollToErrors } from '@/utils/scrollToErrors';
import { translateInvoiceDocumentType } from '@/utils/translateInvoiceDocumentType';

import FormCustomer from './parts/FormCustomer';
import FormDiscount from './parts/FormDiscount';
import FormInvoiceType from './parts/FormInvoiceType';
import FormItems from './parts/FormItems';
import FormSettings from './parts/FormSettings';
import { getGlobalDiscountForSubmitPayload } from './parts/getDiscountsForSubmit';
import InvoicePdfPreviewDrawer from './parts/InvoicePdfPreviewDrawer';
import InvoiceTotalsWithActionButtons from './parts/InvoiceTotalsWithActionButtons';
import useHandleConfirmationModal from './parts/useHandleConfirmationModal';
import { CreateInvoiceFormValues, HandlePreviewCalculationsProps, InvoiceActionType } from './types';
import useNewInvoiceMutations from './useNewInvoiceMutations';

const tenantIsMissingSomeData = (tenant: Tenant) => {
  return (
    !tenant.city ||
    tenant.city === 'Desconhecido' ||
    !tenant.address ||
    tenant.address === 'Desconhecido' ||
    !tenant.postalCode ||
    !PT_POSTAL_CODE_REGEX.test(tenant.postalCode) ||
    !tenant.territory ||
    tenant.territory === Tenant_Pt_Territory.Unknown
  );
};

type NewInvoiceFormProps = {
  withPeview?: boolean;
  invoice?: Invoice;
  invoiceDocumentType?: Invoice_Document_Type;
  strictDocumentType?: boolean;
  setAmountWithTax?: React.Dispatch<React.SetStateAction<number | undefined>>;
  setIsFirstSubmitAttempt: React.Dispatch<React.SetStateAction<boolean>>;
};

const NewInvoiceForm = forwardRef(
  (
    {
      withPeview = true,
      invoice,
      invoiceDocumentType,
      strictDocumentType,
      setAmountWithTax,
      setIsFirstSubmitAttempt,
    }: NewInvoiceFormProps,
    ref
  ) => {
    const formikProps = useFormikContext<CreateInvoiceFormValues>();
    const { values, validateForm, setFieldValue, setFieldError } = formikProps;
    const [selectedReason, setSelectedReason] = useState<string | null>(null);
    const [additionalInfoModalOpened, additionalInfoModal] = useDisclosure(false);
    const [setupATConnectionModalOpened, setupATConnectionModal] = useDisclosure(false);
    const { width: windowWidth } = useViewportSize();
    const navigate = useNavigate();
    const tenantObject = useReactiveVar(tenantVar);
    const { t } = useTranslation();
    const { createInvoice, createLoading, issueInvoice, issueLoading, updateInvoice, updateLoading } =
      useNewInvoiceMutations();

    // FIXME: try without useEffect
    useEffect(() => {
      if (tenantObject && (tenantObject.verifiedAddressDetails === false || tenantIsMissingSomeData(tenantObject))) {
        additionalInfoModal.open();
      } else {
        additionalInfoModal.close();
      }
    }, [tenantObject, additionalInfoModal]);

    const isSmScreen = useMediaQuery('(max-width: 575px)');

    const [isPreviewOpen, { open: openPreview, close: closePreview }] = useDisclosure(false);
    const [execPreviewQuery, { loading: loadingPreview, data: totalsPreviewData }] = usePreviewInvoiceQueryLazyQuery({
      fetchPolicy: 'network-only',
      onError: (error) => {
        showErrorToast({ title: t('error.fetchingTotalsPreview'), message: error.message });
      },
    });

    const [deleteInvoice, { loading: deleteInvoiceLoading }] = useDeleteInvoiceMutation({
      onError: (error) => {
        showErrorToast({ title: t('error.deleteInvoice'), message: error.message });
        return;
      },
      onCompleted: () => {
        showSuccessToast({
          title: t('label.draftDeleted'),
          message: t('label.draftDeletedSuccessfully'),
        });
        navigate('/invoices');
      },
    });

    useImperativeHandle(ref, () => ({
      handleHeaderPreviewClick,
    }));

    useEffect(() => {
      if (setAmountWithTax) setAmountWithTax(totalsPreviewData?.previewInvoice.summary?.amountWithTax);
    }, [totalsPreviewData?.previewInvoice.summary?.amountWithTax, setAmountWithTax]);

    const isCreditNote = invoiceDocumentType === Invoice_Document_Type.CreditNote;
    const isDebitNote = invoiceDocumentType === Invoice_Document_Type.DebitNote;
    const isDraftInvoice = invoice?.status === Invoice_Status.Draft;
    // const isEditable = isDraftInvoice || isCreditNote || isDebitNote;
    const isCreditOrDebitNote = isCreditNote || isDebitNote;

    function handleHeaderPreviewClick() {
      handleOpenPdfPreview(validateForm);
    }

    const handlePreviewCalculations = async ({
      invoiceType,
      newFormItemsValue,
      selectedDsicountType,
      newDiscountPercentValue,
      newDiscountAmountValue,
      setFieldValue,
    }: HandlePreviewCalculationsProps) => {
      if (!newFormItemsValue.length) {
        setFieldValue('discountAmount', 0);
        setFieldValue('discountPercent', 0);
      }
      const typesToCalculate = [
        Invoice_Document_Type.Invoice,
        Invoice_Document_Type.SimplifiedInvoice,
        Invoice_Document_Type.InvoiceReceipt,
      ];

      if (!typesToCalculate.includes(invoiceType)) {
        return;
      }

      const formValueMappedToMutation: InvoicePreviewInput = {
        summary: {
          globalDiscountType: selectedDsicountType,
          globalDiscountPercent:
            selectedDsicountType === Discount_Type.Percent ? newDiscountPercentValue || 0 : undefined,
          globalDiscountAmount: selectedDsicountType === Discount_Type.Amount ? newDiscountAmountValue || 0 : undefined,
        },
        items: newFormItemsValue.map((invoiceItem) => {
          return {
            ref: invoiceItem.ref || invoiceItem.id,
            meta: {
              quantity: invoiceItem.quantity,
              withholdingTaxEnabled: invoiceItem.withholdingTaxAvailable || false,
            },
            data: {
              unitPrice: invoiceItem.unitPrice!,
              taxRate: invoiceItem.taxRate,
              vatRate: invoiceItem.vatRate,
              // withholdingTaxAvailable: invoiceItem.withholdingTaxAvailable || false,
              withholdingTaxPercent: invoiceItem.withholdingTaxPercent,
              // withholdingTaxReason: invoiceItem.withholdingTaxReason,
              withholdingTaxType: invoiceItem.withholdingTaxType,
            },
          };
        }),
      };

      await execPreviewQuery({ variables: { invoice: formValueMappedToMutation } });
    };

    const handleFormSubmit = async (
      formikProps: FormikProps<CreateInvoiceFormValues>,
      actionType: InvoiceActionType
    ) => {
      const formErrors = await formikProps.validateForm();

      scrollToErrors(formErrors, {
        invoiceFormCustomer: 'invoiceCustomer',
        invoiceItems: 'invoiceItems',
        notes: 'notes',
      });

      if (Object.keys(formErrors).length) {
        return;
      }

      const {
        invoiceType,
        invoiceFormCustomer,
        invoiceItems,
        notes,
        invoiceDate,
        dueDate,
        paymentMethod,
        discountAmount,
        discountPercent,
        discountType,
      } = formikProps.values;
      const customerToInvoice = _omit(invoiceFormCustomer, [
        'id',
        '__typename',
        'companyName',
        'companyPosition',
        'description',
      ]);

      const isReceiptOrSimplifiedInvoice = [
        Invoice_Document_Type.InvoiceReceipt,
        Invoice_Document_Type.SimplifiedInvoice,
      ].includes(invoiceType);
      const invoiceActionUpdateDraftInvoice = actionType === InvoiceActionType.draft && isDraftInvoice;

      const normilizedIssueDate = normilizeInvoiceDate(invoiceDate, isCreditOrDebitNote);

      const formValueMappedToMutation: InvoiceInput = {
        documentType: !invoiceDocumentType ? invoiceType : invoiceDocumentType,
        parentInvoiceId: isCreditOrDebitNote ? invoice?.id : undefined,
        // series: null,
        reason: formikProps.values.reasonType === 'other' ? formikProps.values.reason : formikProps.values.reasonType,
        notes: notes,
        customer: {
          ref: invoiceFormCustomer?.id,
          data: {
            ...customerToInvoice,
          },
        },
        date: normilizedIssueDate,
        dueDate: isReceiptOrSimplifiedInvoice
          ? normilizedIssueDate
          : normilizeInvoiceDueDate(dueDate, invoiceDate, isCreditNote, isDebitNote),
        currency: 'EUR',
        summary: {
          globalDiscountPercent: getGlobalDiscountForSubmitPayload(
            isCreditOrDebitNote,
            totalsPreviewData?.previewInvoice.summary?.globalDiscountPercent,
            discountPercent
          ),
          globalDiscountAmount: getGlobalDiscountForSubmitPayload(
            isCreditOrDebitNote,
            totalsPreviewData?.previewInvoice.summary?.globalDiscountAmount,
            discountAmount
          ),
          globalDiscountType: isCreditOrDebitNote
            ? totalsPreviewData?.previewInvoice.summary?.globalDiscountType
            : discountType,
        },
        items: invoiceItems.map((invoiceItem) => {
          return {
            // We pass ref when invoiceItem is of type InvoiceItem, otherwise we pass id if it's of type Item
            ref: invoiceItem.ref || invoiceItem.id,
            // Only when we edit draft invoice AND invoice item existed in original draft, we need to pass id of the invoice item to update it
            ...(invoiceActionUpdateDraftInvoice && invoiceItem.ref ? { id: invoiceItem.id } : {}),
            meta: {
              quantity: invoiceItem.quantity,
              withholdingTaxEnabled: invoiceItem.withholdingTaxAvailable || false,
            },
            data: {
              name: invoiceItem.name!,
              type: invoiceItem.type,
              unitPrice: invoiceItem.unitPrice!,
              taxRate: invoiceItem.taxRate,
              vatRate: invoiceItem.vatRate,
              vatExemptionReason: invoiceItem.vatExemptionReason,
              unitType: invoiceItem.unitType || Item_Unit_Type.Unit,
              withholdingTaxAvailable: invoiceItem.withholdingTaxAvailable || false,
              withholdingTaxPercent: invoiceItem.withholdingTaxPercent,
              withholdingTaxReason: invoiceItem.withholdingTaxReason,
              withholdingTaxType: invoiceItem.withholdingTaxType,
            },
          };
        }),
      };

      let response: FetchResult<UpdateInvoiceMutation | CreateDraftInvoiceMutation | IssueInvoiceMutation> = {};

      if (isReceiptOrSimplifiedInvoice && !isCreditNote && !isDebitNote) {
        formValueMappedToMutation.payment = {
          date: normilizeInvoiceDate(invoiceDate),
          method: paymentMethod,
        };
      }

      const handleIssueInvoiceError = async (error: ApolloError) => {
        const [formatedErr] = error.graphQLErrors;
        const errorCode = formatedErr?.extensions?.errorCode;

        if (errorCode === 'AT_NO_VERIFIED_CREDENTIALS') {
          // refetch tenant
          const res = await client.query<TenantQuery, TenantQueryVariables>({
            query: TenantDocument,
            variables: { tenantId: tenantObject!.id },
            fetchPolicy: 'network-only',
          });

          console.log('refetched tenant', res.data.tenant);

          if (res?.data?.tenant) {
            tenantVar(res.data.tenant);
            setupATConnectionModal.open();
          }
        }

        showErrorToast({ title: t('error.createInvoice'), message: error.message });
      };

      if (invoiceActionUpdateDraftInvoice) {
        response = await updateInvoice({
          variables: {
            updateInvoiceId: invoice.id,
            invoice: formValueMappedToMutation,
          },
        });
      } else if (actionType === InvoiceActionType.draft && !isDraftInvoice) {
        response = await createInvoice({
          variables: {
            invoice: formValueMappedToMutation,
          },
        });
      } else if (actionType === InvoiceActionType.issue && !isDraftInvoice) {
        response = await issueInvoice({
          variables: {
            invoice: formValueMappedToMutation,
          },
          onError: handleIssueInvoiceError,
        });
      } else if (actionType === InvoiceActionType.issue && isDraftInvoice) {
        response = await issueInvoice({
          variables: {
            id: invoice.id,
            invoice: formValueMappedToMutation,
          },
          onError: handleIssueInvoiceError,
        });
      }

      if (response?.data) {
        const responseData = response.data;
        const invoiceId =
          (responseData as UpdateInvoiceMutation).updateInvoice?.id ||
          (responseData as CreateDraftInvoiceMutation).createDraftInvoice?.id ||
          (responseData as IssueInvoiceMutation).issueInvoice?.id;
        const invoiceDocType =
          (responseData as UpdateInvoiceMutation).updateInvoice?.documentType ||
          (responseData as CreateDraftInvoiceMutation).createDraftInvoice?.documentType ||
          (responseData as IssueInvoiceMutation).issueInvoice?.documentType;
        const invoiceStatus =
          (responseData as UpdateInvoiceMutation).updateInvoice?.status ||
          (responseData as CreateDraftInvoiceMutation).createDraftInvoice?.status ||
          (responseData as IssueInvoiceMutation).issueInvoice?.status;

        if (!invoiceId || !invoiceDocType) {
          return;
        }

        const syncWithAT =
          (responseData as UpdateInvoiceMutation).updateInvoice?.syncWithAt ||
          (responseData as CreateDraftInvoiceMutation).createDraftInvoice?.syncWithAt ||
          (responseData as IssueInvoiceMutation).issueInvoice?.syncWithAt;

        if (
          syncWithAT?.status === Invoice_Sync_With_At_Status.Failed &&
          syncWithAT.atCode &&
          ['99', '33'].includes(syncWithAT.atCode)
        ) {
          // refetch tenant
          const res = await client.query<TenantQuery, TenantQueryVariables>({
            query: TenantDocument,
            variables: { tenantId: tenantObject!.id },
            fetchPolicy: 'network-only',
          });

          console.log('refetched tenant', res.data.tenant);

          if (res?.data?.tenant) {
            tenantVar(res.data.tenant);
          }
        }

        const translatedDocumentType = invoiceDocType ? translateInvoiceDocumentType(invoiceDocType, t) : '';
        const successToastTitle =
          invoiceStatus === Invoice_Status.Draft
            ? isDraftInvoice
              ? t('label.draftUpdated')
              : t('label.draftCreated')
            : t('label.invoiceCreated', { type: translatedDocumentType });

        const successToastMessage =
          invoiceStatus === Invoice_Status.Draft
            ? isDraftInvoice
              ? t('label.draftUpdatedSuccessfully')
              : t('label.draftCreatedSuccessfully')
            : t('label.invoiceCreatedSuccessfully', { type: translatedDocumentType });

        showSuccessToast({
          title: successToastTitle,
          message: successToastMessage,
        });

        if (invoiceStatus === Invoice_Status.Draft) {
          navigate(`/invoices?status=${Invoice_Aggregated_Status.Draft}`);
        } else {
          navigate(`/invoices/${invoiceId}`);
        }
      }
    };

    const openConfirmationModal = useHandleConfirmationModal({ setIsFirstSubmitAttempt });
    const handleOpenPdfPreview = async (validateForm: FormikHelpers<CreateInvoiceFormValues>['validateForm']) => {
      const formErrors = await validateForm();

      scrollToErrors(formErrors, {
        invoiceFormCustomer: 'invoiceCustomer',
        invoiceItems: 'invoiceItems',
        notes: 'notes',
      });

      const formHasErrors = Object.values(formErrors).filter(Boolean).length > 0;

      if (formHasErrors) {
        return;
      }

      openPreview();
    };

    const handleCloseFormPage = () => {
      // close();
      navigate('/invoices');
    };

    const handleSetupATConnection = () => {
      setupATConnectionModal.open();
    };

    const handleDeleteInvoice = () => {
      deleteInvoice({
        variables: {
          deleteInvoiceId: invoice!.id,
        },
      });
    };

    const openDeleteConfirmationModal = () =>
      modals.openConfirmModal({
        modalId: 'delete-invoice-confirmation',
        title: t('modal.deleteInvoiceDraftConfirmation.title'),
        centered: true,
        children: <Text size="sm">{t('modal.deleteDraftConfirmation.body')}</Text>,
        labels: { confirm: t('button.label.yes'), cancel: t('button.label.no') },
        confirmProps: {
          color: '#F2F3F6',
          variant: 'filled',
          classNames: {
            label: 'text-bright-red',
          },
        },
        cancelProps: { color: '#F2F3F6', variant: 'filled' },
        onConfirm: handleDeleteInvoice,
      });

    const responsiveScreensActionBlock = (
      <InvoiceTotalsWithActionButtons
        invoice={invoice!}
        invoiceType={values.invoiceType}
        openDeleteConfirmationModal={openDeleteConfirmationModal}
        showTotalsPreview={values.invoiceItems.length > 0}
        elementWrapperClasses="bg-white"
        actionButtonsClasses="flex w-full justify-around gap-2"
        isPreviewLoading={loadingPreview}
        totalsPreviewData={totalsPreviewData}
        handleDraftSubmission={() => handleFormSubmit(formikProps, InvoiceActionType.draft)}
        handleIssueSubmission={() =>
          openConfirmationModal({ formikProps, isCreditOrDebitNote, handleFormSubmit, handleSetupATConnection })
        }
      />
    );

    const creditDataOptions = [
      { value: 'Anulação da fatura', label: t('option.invoiceCancellation') },
      { value: 'Erro na fatura', label: t('option.invoiceError') },
      { value: 'Redução do preço', label: t('option.priceReduction') },
      { value: 'Devolução de bens', label: t('option.returnOfGoods') },
      { value: 'Troca de bens', label: t('option.exchangeOfGoods') },
      { value: 'other', label: t('option.other') },
    ];

    const handleReasonTypeChange = async (value: string) => {
      const selectedOther = value === 'other' && values.reasonType !== 'other';

      await setFieldValue('reasonType', value);

      if (value !== 'other') {
        await setFieldValue('reason', '', false);
      }

      if (selectedOther) {
        setFieldError('reason', '');
      }
    };

    return (
      <Form
        className={cn('mx-auto sm:pb-0', {
          // 'pb-[250px]': !isCreditOrDebitNote,
          'pb-0': isCreditOrDebitNote,
        })}
      >
        <LoadingOverlay
          visible={createLoading || issueLoading || updateLoading || deleteInvoiceLoading || windowWidth === 0}
        />
        <Grid gutter="lg">
          {!isCreditOrDebitNote && (
            <div className="flex w-full justify-around bg-white px-2 pb-0 pt-2 sm:pb-3">
              <div className="grid w-full grid-cols-2 grid-rows-1 gap-2 xs:grid-rows-2 lg:grid-cols-3 lg:grid-rows-1">
                <div className="col-span-2 lg:col-span-1">
                  {!strictDocumentType && <FormInvoiceType fieldName="invoiceType" />}
                </div>

                <div className="col-span-2">
                  <FormSettings />
                </div>
              </div>
              <div className="flex">
                {/* {invoice?.id && isDraftInvoice && (
                  <Button
                    visibleFrom="md"
                    variant="default"
                    className="ml-2 h-[56px] rounded-[24px] border-none bg-angel-feather pl-4 pr-4 text-[17px] font-semibold hover:bg-feather-dark"
                    onClick={openDeleteConfirmationModal}
                  >
                    <IconTrashCan height={20} width={20} className="fill-black" />
                  </Button>
                )} */}
              </div>
            </div>
          )}
          <Grid.Col span={withPeview ? (windowWidth < 1184 ? 12 : 6) : 12}>
            <Stack gap={16}>
              {/* {!username && !isSmScreen && <AccessRequired handleSetupATConnection={handleSetupATConnection} />} */}

              <CardBlock stackedContent data-validation-id="invoiceCustomer">
                <CardBlock.Title>{t('label.billTo')}</CardBlock.Title>
                <FormCustomer
                  fieldName="invoiceFormCustomer"
                  preSelectedCustomerData={normilizeInvoiceCustomerToCustomer(invoice?.customer)}
                  canDeleteCustomer={!isCreditOrDebitNote}
                />
              </CardBlock>

              <CardBlock stackedContent data-validation-id="invoiceItems">
                <CardBlock.Title>{t('label.items')}</CardBlock.Title>
                <FormItems
                  fieldName="invoiceItems"
                  canAddItem={!isCreditOrDebitNote}
                  invoiceDocumentType={invoiceDocumentType}
                  originalInvoiceItems={invoice?.items}
                  handleTotalsOnItemsChange={(newFormItemsValue) =>
                    handlePreviewCalculations({
                      invoiceType: values.invoiceType,
                      actionType: InvoiceActionType.draft,
                      newFormItemsValue,
                      selectedDsicountType: values.discountType,
                      newDiscountPercentValue: values.discountPercent,
                      newDiscountAmountValue: values.discountAmount,
                      setFieldValue,
                    })
                  }
                  totalsPreviewData={totalsPreviewData}
                  isPreviewLoading={loadingPreview}
                />
                {values.invoiceItems.length > 0 && (
                  <FormDiscount
                    invoiceDocumentType={invoiceDocumentType}
                    handleTotalsOnDiscountChange={(newDiscountValue) => {
                      const isDiscountTypePercent = values.discountType === Discount_Type.Percent;

                      handlePreviewCalculations({
                        invoiceType: values.invoiceType,
                        actionType: InvoiceActionType.draft,
                        newFormItemsValue: values.invoiceItems,
                        selectedDsicountType: values.discountType,
                        newDiscountPercentValue: isDiscountTypePercent ? newDiscountValue : values.discountPercent,
                        newDiscountAmountValue: isDiscountTypePercent ? values.discountAmount : newDiscountValue,
                        setFieldValue,
                      });
                    }}
                    originalInvoiceDiscountAmount={invoice?.summary?.globalDiscountAmount}
                  />
                )}
              </CardBlock>

              {isCreditOrDebitNote && (
                <>
                  {!isCreditNote && <FormSettings />}

                  {isDebitNote && (
                    <FormikInput
                      name="reason"
                      label={t('label.reason')}
                      placeholder={t('placeholder.reason', {
                        type: translateInvoiceDocumentType(invoiceDocumentType, t),
                      })}
                      labelProps={{
                        mb: 2,
                      }}
                      error={formikProps.errors.reason}
                    />
                  )}

                  {isCreditNote && (
                    <>
                      {isSmScreen ? <Space h={16} /> : ''}
                      <FormikSelect
                        label={t('label.reason')}
                        name="reasonType"
                        placeholder={t('placeholder.reason', {
                          type: translateInvoiceDocumentType(invoiceDocumentType, t),
                        })}
                        data={creditDataOptions}
                        labelProps={{
                          mb: 2,
                        }}
                        error={formikProps.errors.reasonType}
                        onChange={(value) => {
                          handleReasonTypeChange(value!);
                          setSelectedReason(value);
                          formikProps.setFieldValue('reasonType', value);
                        }}
                      />

                      {selectedReason === 'other' && (
                        <FormikCleanInput
                          size="fiz-lg"
                          name="reason"
                          placeholder={t('placeholder.reason', {
                            type: translateInvoiceDocumentType(invoiceDocumentType, t),
                          })}
                          labelProps={{
                            mb: 2,
                          }}
                          error={formikProps.errors.reason}
                        />
                      )}
                    </>
                  )}
                </>
              )}

              <CardBlock stackedContent data-validation-id="notes">
                <CardBlock.Title>{t('label.notes')}</CardBlock.Title>
                <FormikTextareaInput
                  name="notes"
                  label={`${t('label.notes')} ${t('label.optional')}`}
                  placeholder={`${t('placeholder.enterNotes')} ${t('label.optional')}`}
                />
              </CardBlock>
            </Stack>

            {isCreditOrDebitNote && (
              <div className="bottom-5">
                <Button
                  onClick={() =>
                    openConfirmationModal({
                      formikProps,
                      isCreditOrDebitNote,
                      handleFormSubmit,
                      handleSetupATConnection,
                    })
                  }
                  size="l"
                  fullWidth
                  variant="primary"
                  className="mt-[40px]"
                >
                  {t('button.label.issue')}
                </Button>
              </div>
            )}
          </Grid.Col>
          {withPeview && (
            <Grid.Col className="hidden lg:block" span={windowWidth < 1184 ? 12 : 6}>
              <div className="sticky top-[100px] mx-auto bg-white lg:pl-[18px]">
                <div className="hidden pb-[35px] lg:block">
                  <Suspense fallback={<LoadingOverlay visible />}>
                    <InvoiceTotalsWithActionButtons
                      invoice={invoice!}
                      invoiceType={values.invoiceType}
                      openDeleteConfirmationModal={openDeleteConfirmationModal}
                      showTotalsPreview
                      actionButtonsClasses="sticky top-0 hidden w-full justify-around gap-2 lg:flex"
                      isPreviewLoading={loadingPreview}
                      totalsPreviewData={totalsPreviewData}
                      isCTAHidden={isCreditOrDebitNote}
                      handleDraftSubmission={() => handleFormSubmit(formikProps, InvoiceActionType.draft)}
                      handleIssueSubmission={() =>
                        openConfirmationModal({
                          formikProps,
                          isCreditOrDebitNote,
                          handleFormSubmit,
                          handleSetupATConnection,
                        })
                      }
                    />
                  </Suspense>
                </div>
              </div>
            </Grid.Col>
          )}
          {/* <Affix position={{ bottom: 0 }} className="z-[190] w-full sm:hidden">
                  {!isCreditOrDebitNote && values.invoiceItems.length > 0 && responsiveScreensActionBlock}
                </Affix> */}
          <div className="sticky bottom-0 left-0 z-[40] hidden w-full gap-2 sm:block lg:hidden">
            {!isCreditOrDebitNote && responsiveScreensActionBlock}
          </div>
          <div className="w-full sm:hidden">{!isCreditOrDebitNote && responsiveScreensActionBlock}</div>
        </Grid>
        {isPreviewOpen && (
          <InvoicePdfPreviewDrawer
            closePreview={closePreview}
            totalsPreviewData={totalsPreviewData}
            isDraftInvoice={isDraftInvoice}
          />
        )}

        {additionalInfoModalOpened && (
          <CustomModal
            isOpened={additionalInfoModalOpened}
            fullScreen={isSmScreen}
            handleClose={handleCloseFormPage}
            closeOnClickOutside={false}
            title={
              <div className="grid-row grid items-center gap-2">
                <span>{t('label.enterYourAddress')}</span>
                <span className="text-[14px] font-semibold text-light-black-magic">
                  {t('label.requiredByTaxAuthorityForInvoicing')}
                </span>
              </div>
            }
          >
            <AdditionalTenantInfo />
          </CustomModal>
        )}

        {setupATConnectionModalOpened && (
          <CustomModal
            title={t('label.atCommunication')}
            isOpened={setupATConnectionModalOpened}
            handleClose={setupATConnectionModal.close}
            size={500}
          >
            <ConnectWithATOptions onConnectionAdded={setupATConnectionModal.close} />
          </CustomModal>
        )}
      </Form>
    );
  }
);

export default NewInvoiceForm;
