import React, { Fragment } from 'react';
import { Box, Combobox, Flex, Group, InputBase, Text } from '@mantine/core';
import { CustomSearchComboboxProps } from './types';
import InputRightSection from './InputRightSection';
import { useTranslation } from 'react-i18next';
import IconPlusWithBlueBg from '@/assets/icons/IconPlusWithBlueBg.svg?react';
import { CustomerAvatar } from '../Customers/CustomerAvatar/CustomerAvatar';
import omit from 'lodash/omit';
import { cn, DEFAULT_COUNTRY } from '@/utils';
import { useField } from 'formik';
import { FormItem } from '../Invoice/Create/types';

const CustomSearchCombobox = ({
  fieldName,
  comboboxInstance,
  setSearch,
  search,
  label = '',
  placeholder,
  loading,
  formattedOptions,
  handleValueChange,
  fieldError,
  searchName,
  targetRef,
  mode = 'desktop',
  minOptionsToShowSearch,
}: CustomSearchComboboxProps) => {
  const isMobileMode = mode === 'mobile';
  const { t } = useTranslation();
  const exactOptionMatch = formattedOptions?.some((option) => option.label.toLowerCase() === search.toLowerCase());
  const filteredOptions = exactOptionMatch
    ? formattedOptions
    : formattedOptions?.filter((option) => option.label.toLowerCase().includes(search.toLowerCase().trim()));

  const options = filteredOptions?.map((option) => {
    return (
      <Fragment key={option.value}>
        {fieldName === 'invoiceFormCustomer' ? (
          <Combobox.Option
            value={option.value}
            className="py-0 pl-4 pr-0 text-[17px] first-of-type:pt-1 last-of-type:pb-1"
          >
            <Flex align="center" className="border-b border-fog-bronze py-2">
              <CustomerAvatar customer={omit(option.customer, '__typename')} size={48} radius={16} />
              <Group className="ml-3">
                <div>
                  <Text className="text-[17px] font-normal">{option.label}</Text>
                  <Text className="text-[14px] font-normal text-black-burgundy">
                    {option.country === DEFAULT_COUNTRY
                      ? option.taxpayerNumber
                        ? `${option.country} ${option.taxpayerNumber}`
                        : t('label.noNif')
                      : `${option.country} ${option.taxpayerNumber}`}
                  </Text>
                </div>
              </Group>
            </Flex>
          </Combobox.Option>
        ) : (
          <Combobox.Option
            value={option.value}
            className="border-b border-fog-bronze py-0 pl-4 pr-0 text-[17px] first-of-type:pt-1 last-of-type:pb-1"
          >
            <Group gap="xs" className="flex justify-between py-[17px] pr-3">
              <span className="text-[17px] font-normal text-black-magic">{option.label}</span>
              <span className="text-[17px] font-normal text-dark-bronze">{option.amount}</span>
            </Group>
          </Combobox.Option>
        )}
      </Fragment>
    );
  });

  const [field] = useField<FormItem[]>(fieldName);
  const ComboboxTarget = isMobileMode ? Combobox.EventsTarget : Combobox.Target;
  const ComboboxItemsContainer = isMobileMode ? Box : Combobox.Dropdown;

  return (
    <>
      {field.value?.length > 0 && fieldName === 'invoiceItems' && !isMobileMode && (
        <span className="text-[17px] font-bold text-black-magic">{t('label.addNewItem')}</span>
      )}
      <Combobox
        store={isMobileMode ? undefined : comboboxInstance}
        withinPortal={false}
        onOptionSubmit={handleValueChange}
      >
        <ComboboxTarget ref={targetRef}>
          {typeof minOptionsToShowSearch === 'undefined' ||
          filteredOptions.length >= minOptionsToShowSearch ||
          search ? (
            <InputBase
              label={label}
              size="lg"
              rightSection={
                <InputRightSection loading={loading} setSearch={setSearch} search={search} fieldName={fieldName} />
              }
              value={search}
              onChange={(event) => {
                if (!isMobileMode) {
                  comboboxInstance.openDropdown();
                  comboboxInstance.updateSelectedOptionIndex();
                }

                setSearch(event.currentTarget.value);
              }}
              onClick={() => {
                if (isMobileMode) return;
                comboboxInstance.openDropdown();
              }}
              onBlur={() => {
                if (isMobileMode) return;
                comboboxInstance.closeDropdown();
              }}
              placeholder={placeholder}
              error={fieldError}
            />
          ) : (
            <span ref={targetRef}></span>
          )}
        </ComboboxTarget>

        <ComboboxItemsContainer
          className={cn('solid overflow-hidden rounded-3xl border border-light-midnight p-0 only:border-0', {
            'shadow-onboarding-button': !isMobileMode,
            'mt-4 bg-white': isMobileMode,
          })}
        >
          <Combobox.Options>
            <>
              {options}
              {(!exactOptionMatch || fieldName === 'invoiceFormCustomer') && (
                <Combobox.Option value="$create" className="flex py-4 pl-4 text-[17px] text-moroccan-blue">
                  <IconPlusWithBlueBg />
                  <span className="pl-4">
                    {t(search ? 'label.addFromSearch' : 'label.addNew', { search, name: searchName })}
                  </span>
                </Combobox.Option>
              )}
            </>
          </Combobox.Options>
        </ComboboxItemsContainer>
      </Combobox>
    </>
  );
};

export default CustomSearchCombobox;
