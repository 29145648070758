import { LoadingOverlay } from '@mantine/core';
import { Form, Formik } from 'formik';
import { FormikInput, FormikSelect } from '@/components/FormikWrapped';
import { useTranslation } from 'react-i18next';
import { GetSeriesDocument, GetTenantsDocument, TenantDocument, useUpdateTenantMutation } from '@/graphql-operations';
import { showErrorToast } from '@/components/Toast';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { useReactiveVar } from '@apollo/client';
import { Tenant_Pt_Territory } from '@/graphql/types';
import { validationSchema } from '../validationSchema';
import { IMaskInput } from 'react-imask';
import { scrollToErrors } from '@/utils/scrollToErrors';
import { useState } from 'react';
import { Button } from '@/components/ButtonVariants';
// import { PT_IBAN_MASK } from '@/utils/iban';

const AdditionalTenantInfo = () => {
  const { t } = useTranslation();
  const tenantObject = useReactiveVar(tenantVar);
  const [isFirstSubmitAttempt, setIsFirstSubmitAttempt] = useState(false);

  const [updateTenantMutation, { loading: updateTenantMutationLoading }] = useUpdateTenantMutation({
    refetchQueries: [GetSeriesDocument, GetTenantsDocument, TenantDocument],
    onError: (error) => {
      showErrorToast({ title: 'Error:', message: error.message });
      return;
    },
  });

  if (!tenantObject) return null;

  const initialValues = {
    city: tenantObject.city === 'Desconhecido' ? '' : tenantObject.city,
    address: tenantObject.address === 'Desconhecido' ? '' : tenantObject.address,
    postalCode: tenantObject.postalCode,
    territory:
      tenantObject.territory === Tenant_Pt_Territory.Unknown ? Tenant_Pt_Territory.Continental : tenantObject.territory,
    // iban: tenantObject.iban,
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema(t)}
      validateOnBlur={isFirstSubmitAttempt}
      validateOnChange={isFirstSubmitAttempt}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const updatedTenant = await updateTenantMutation({
          variables: {
            updateTenantId: tenantObject.id || '',
            tenant: {
              city: values.city,
              address: values.address,
              postalCode: values.postalCode,
              territory: values.territory,
              verifiedAddressDetails: true,
              // iban: values.iban ? `PT${values.iban}` : null,
            },
          },
        });

        setSubmitting(false);
        resetForm({ values });
        tenantVar(updatedTenant.data?.updateTenant);
      }}
    >
      {({ errors, touched, setFieldValue, validateForm }) => {
        return (
          <Form autoComplete="off">
            <LoadingOverlay visible={updateTenantMutationLoading} />

            <FormikSelect
              size="md"
              name="territory"
              label={t('label.territory')}
              placeholder={t('label.territory')}
              data={[
                { value: Tenant_Pt_Territory.Azores, label: t('label.azores') },
                { value: Tenant_Pt_Territory.Continental, label: t('label.mainland') },
                { value: Tenant_Pt_Territory.Madeira, label: t('label.madeira') },
              ]}
              error={errors.territory && touched.territory ? errors.territory : null}
            />

            <FormikInput
              size="md"
              mt={16}
              name="postalCode"
              label={t('label.postalCode')}
              placeholder={t('placeholder.enterYourPostalCode')}
              error={errors.postalCode && touched.postalCode ? errors.postalCode : null}
              component={IMaskInput}
              inputMode="numeric"
              mask="0000-000"
              handleOnAccept={(value) => {
                setFieldValue('postalCode', value);
              }}
            />

            <FormikInput
              size="md"
              mt={16}
              name="city"
              label={t('label.city')}
              placeholder={t('placeholder.enterYourCity')}
              error={errors.city && touched.city ? errors.city : null}
            />

            <FormikInput
              size="md"
              mt={16}
              name="address"
              label={t('label.address')}
              placeholder={t('placeholder.enterYourAddress')}
              error={errors.address && touched.address ? errors.address : null}
            />

            {/* <FormikInput
              size="md"
              mt={16}
              name="iban"
              label={`${t('label.iban')} ${t('label.optional')}`}
              placeholder={`${t('label.iban')} ${t('label.optional')}`}
              component={IMaskInput}
              mask={PT_IBAN_MASK}
              unmask
              inputMode="numeric"
              handleOnAccept={(value) => {
                setFieldValue('iban', value);
              }}
              error={errors.iban && touched.iban ? errors.iban : null}
            /> */}

            <Button
              type="submit"
              onClick={async () => {
                setIsFirstSubmitAttempt(true);
                const formErrors = await validateForm();
                scrollToErrors(formErrors);
              }}
              size="m"
              fullWidth
              variant="primary"
              className="mt-8"
            >
              {t('button.label.confirm')}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AdditionalTenantInfo;
