import { Invoice_Document_Type, InvoiceInput, Item_Unit_Type } from '@/graphql-types';
import { PreviewInvoiceQueryQuery } from '@/graphql-operations';
import { normilizeInvoiceDate, normilizeInvoiceDueDate } from '@/utils/normalizeData';
import { CreateInvoiceFormValues } from '../types';
import _omit from 'lodash/omit';
import { getGlobalDiscountForSubmitPayload } from './getDiscountsForSubmit';

const mapToPreviewInvoicePDF = (
  formValues: CreateInvoiceFormValues,
  totalsPreviewData: PreviewInvoiceQueryQuery | undefined
): InvoiceInput => {
  const {
    invoiceFormCustomer,
    invoiceItems,
    invoiceType,
    invoiceDate,
    dueDate,
    paymentMethod,
    discountPercent,
    discountAmount,
    discountType,
  } = formValues;
  const isReceiptOrSimplifiedInvoice = [
    Invoice_Document_Type.InvoiceReceipt,
    Invoice_Document_Type.SimplifiedInvoice,
  ].includes(invoiceType);
  const normilizedIssueDate = normilizeInvoiceDate(invoiceDate);
  const customerToInvoice = _omit(invoiceFormCustomer, [
    'id',
    '__typename',
    'companyName',
    'companyPosition',
    'description',
  ]);

  return {
    documentType: invoiceType,
    // parentInvoiceId: isCreditOrDebitNote ? invoice?.id : undefined,
    // series: null,
    // reason: formikProps.values.reasonType === 'other' ? formikProps.values.reason : formikProps.values.reasonType,
    notes: formValues.notes,
    customer: {
      ref: invoiceFormCustomer?.id,
      data: customerToInvoice,
    },
    payment: {
      date: normilizedIssueDate,
      method: paymentMethod,
    },
    date: normilizedIssueDate,
    dueDate: isReceiptOrSimplifiedInvoice ? normilizedIssueDate : normilizeInvoiceDueDate(dueDate, invoiceDate),
    currency: 'EUR',
    summary: {
      globalDiscountPercent: getGlobalDiscountForSubmitPayload(
        false,
        totalsPreviewData?.previewInvoice.summary?.globalDiscountPercent,
        discountPercent
      ),
      globalDiscountAmount: getGlobalDiscountForSubmitPayload(
        false,
        totalsPreviewData?.previewInvoice.summary?.globalDiscountAmount,
        discountAmount
      ),
      globalDiscountType: discountType,
    },
    items: invoiceItems.map((invoiceItem) => {
      return {
        // We pass ref when invoiceItem is of type InvoiceItem, otherwise we pass id if it's of type Item
        ref: invoiceItem.ref || invoiceItem.id,
        // Only when we edit draft invoice AND invoice item existed in original draft, we need to pass id of the invoice item to update it
        // ...(invoiceActionUpdateDraftInvoice && invoiceItem.ref ? { id: invoiceItem.id } : {}),
        meta: {
          quantity: invoiceItem.quantity,
          withholdingTaxEnabled: invoiceItem.withholdingTaxAvailable || false,
        },
        data: {
          name: invoiceItem.name!,
          type: invoiceItem.type,
          unitPrice: invoiceItem.unitPrice!,
          taxRate: invoiceItem.taxRate,
          vatRate: invoiceItem.vatRate,
          vatExemptionReason: invoiceItem.vatExemptionReason,
          unitType: invoiceItem.unitType || Item_Unit_Type.Unit,
          withholdingTaxAvailable: invoiceItem.withholdingTaxAvailable,
          withholdingTaxPercent: invoiceItem.withholdingTaxPercent,
          withholdingTaxReason: invoiceItem.withholdingTaxReason,
          withholdingTaxType: invoiceItem.withholdingTaxType,
        },
      };
    }),
  };
};

export default mapToPreviewInvoicePDF;
