import { Checkbox, CheckboxProps } from '@mantine/core';
import { useField, useFormikContext } from 'formik';

interface FormikCheckboxProps extends CheckboxProps {
  name: string;
}

const FormikCheckbox = ({ name, ...rest }: FormikCheckboxProps) => {
  const [field] = useField(name);
  const { errors, touched, setFieldValue, setFieldError } = useFormikContext<Record<string, unknown>>();

  return (
    <Checkbox
      {...field}
      {...rest}
      id={name}
      checked={field.value}
      onChange={(event) => {
        setFieldError(name, undefined);
        setFieldValue(name, event.currentTarget.checked);
      }}
      error={errors[name] && touched[name] ? errors[name] : null}
    />
  );
};

export default FormikCheckbox;
