import { Stack, Group, Skeleton } from '@mantine/core';

const TotalsPreviewSkeleton = () => {
  return (
    <Stack mih={10} gap={8}>
      <Group justify="space-between">
        <Skeleton height={20} width={123} />
        <Skeleton height={20} width={90} />
      </Group>
      <Group justify="space-between">
        <Skeleton height={16} width={80} />
        <Skeleton height={16} width={55} />
      </Group>
      <Group justify="space-between" mb={12}>
        <Skeleton height={16} width={80} />
        <Skeleton height={16} width={55} />
      </Group>
    </Stack>
  );
};

export default TotalsPreviewSkeleton;
