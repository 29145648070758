import { Group, Skeleton, Stack, UnstyledButton } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import IconArrowLeftBlack from '@/assets/icons/IconArrowLeftBlack.svg?react';
import { cn } from '@/utils';

export type InvoiceStatsProps = {
  isSelected: boolean;
  hideTotals?: boolean;
};

export const InvoiceStatsSkeleton = ({ isSelected, hideTotals }: InvoiceStatsProps) => {
  const { t } = useTranslation();

  return (
    <UnstyledButton
      className={cn('flex cursor-pointer rounded-[20px] bg-white-vanilla md:rounded-[24px]', {
        'outline outline-[3px] outline-moroccan-blue': isSelected,
      })}
    >
      <Stack className="w-full p-4 sm:px-5" gap={16}>
        <div className="flex justify-between">
          <Group gap={8}>
            <Skeleton height={18} width={80} my={4} />
          </Group>
          <span className="my-auto hidden text-[17px] font-bold text-black-magic xs:block">
            <Skeleton height={18} width={80} my={4} />
          </span>
          <IconArrowLeftBlack className="my-auto xs:hidden" />
        </div>
        <div className={cn('hidden grid-cols-2 grid-rows-2', { 'md:grid': !hideTotals })}>
          <span className="text-[14px] font-normal text-dark-bronze">{t('label.subtotal')}</span>
          <Skeleton className="ml-auto" height={17} width={80} my={2} />
          <span className="text-[14px] font-normal text-dark-bronze">{t('label.totalVat')}</span>
          <Skeleton className="ml-auto" height={17} width={80} my={2} />
        </div>
      </Stack>
    </UnstyledButton>
  );
};
