export const transformNameAndCityToTitleCase = (text: string) => {
  if (!text) {
    return '';
  }

  return text
    .toLowerCase()
    .split(' ')
    .map((word) => {
      if (word.includes('-')) {
        return word
          .split('-')
          .map((subWord) => subWord.charAt(0).toUpperCase() + subWord.slice(1))
          .join('-');
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    })
    .join(' ');
};

export const ptAddressToTitleCase = (str: string) => {
  if (!str) {
    return '';
  }

  // Define common abbreviations and exceptions
  const exceptions = ['E', 'N', 'S/N', 'AV', 'R', 'DR', 'JR'];
  const lowercaseWords = ['e', 'de', 'do', 'da', 'dos', 'das', 'a', 'o', 'no', 'na', 'nos', 'nas'];

  return str
    .toLowerCase()
    .split(' ')
    .map(function (word, index) {
      // Check for numeric values or alphanumeric codes that should remain unchanged
      if (word.match(/^\d+$/) || word.match(/^\d+[a-zA-Z]*$/) || exceptions.includes(word.toUpperCase())) {
        return word.toUpperCase();
      }

      // Check for lowercase words, ensure they are lowercase unless at the start
      if (lowercaseWords.includes(word) && index !== 0) {
        return word;
      }

      // Convert first letter to uppercase and the rest to lowercase
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};
