import { useReactiveVar } from '@apollo/client';
import { SimpleGrid, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import dayjs from 'dayjs';

import { Button } from '@/components/ButtonVariants';
import { useTranslation } from '@/components/I18N';
import DownloadPdfActionModal from '@/components/Invoice/InvoiceSidebar/parts/DownloadPdfAction';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { Invoice_Document_Type, Invoice_Status, Invoice_Sync_With_At_Status, Maybe } from '@/graphql-types';
import { cn } from '@/utils';

import { InvoiceSummaryActionsProps } from '../types';

const InvoiceSummaryActions = ({
  invoice,
  status,
  documentType,
  markAsPaidModalOpen,
  handleEditDraftInvoice,
  handleDeleteInvoice,
  handleCancelInvoice,
  handleSetupATConnection,
}: InvoiceSummaryActionsProps) => {
  const { t } = useTranslation();
  const tenantObject = useReactiveVar(tenantVar);
  const tenantHasNoCredentials = !tenantObject?.credentials?.verified;
  const [downloadModalOpened, { open: downloadModalOpen, close: downloadModalClose }] = useDisclosure(false);
  const [downloadReceiptModalOpened, { open: downloadReceiptModalOpen, close: downloadReceiptModalClose }] =
    useDisclosure(false);

  const handleMarkAsPaidModalOpen = () => {
    if (tenantHasNoCredentials) {
      handleSetupATConnection();
    } else {
      markAsPaidModalOpen();
    }
  };

  const handleCancelModalOpen = () => {
    if (tenantHasNoCredentials) {
      handleSetupATConnection();
    } else {
      openCancelConfirmationModal();
    }
  };

  const openDeleteConfirmationModal = () =>
    modals.openConfirmModal({
      modalId: 'delete-invoice-confirmation',
      title: t('modal.deleteInvoiceDraftConfirmation.title'),
      centered: true,
      children: <Text size="sm">{t('modal.deleteDraftConfirmation.body')}</Text>,
      labels: { confirm: t('button.label.delete'), cancel: t('button.label.cancel') },
      confirmProps: {
        color: '#F2F3F6',
        variant: 'filled',
        classNames: {
          label: 'text-bright-red',
        },
      },
      cancelProps: { color: '#F2F3F6', variant: 'filled' },
      onConfirm: handleDeleteInvoice,
    });

  const openCancelConfirmationModal = () =>
    modals.openConfirmModal({
      modalId: 'cancel-invoice-confirmation',
      title: t('modal.cancelInvoiceConfirmation.title'),
      centered: true,
      children: <Text size="sm">{t('modal.cancelInvoiceConfirmation.body')}</Text>,
      labels: { confirm: t('button.label.yes'), cancel: t('button.label.no') },
      // closeOnConfirm: false,
      confirmProps: {
        color: '#F2F3F6',
        variant: 'filled',
        // loading: isCancelInvoiceLoading,
        // loaderProps: { type: 'dots' },
        classNames: {
          label: 'text-bright-red',
        },
      },
      cancelProps: { color: '#F2F3F6', variant: 'filled' },
      onConfirm: handleCancelInvoice,
    });

  const shouldCheckTimeLimit = (documentType: Invoice_Document_Type) => {
    return ![Invoice_Document_Type.CreditNote, Invoice_Document_Type.Invoice].includes(documentType);
  };

  const isOlderThan48Hours = (issuedAt: Maybe<string | Date>) => {
    if (!shouldCheckTimeLimit(documentType)) {
      return false;
    }
    const start = dayjs(issuedAt);
    const now = dayjs();
    const diffInMinutes = now.diff(start, 'minute');
    return diffInMinutes >= 48 * 60;
  };

  const actions = [
    {
      name: 'edit',
      label: t('button.label.edit'),
      onClick: handleEditDraftInvoice,
      isAvailable: status === Invoice_Status.Draft,
      primary: true,
    },
    {
      name: 'download',
      label: t('button.label.downloadPdf'),
      onClick: () => {
        downloadModalOpen();
      },
      isAvailable: status !== Invoice_Status.Draft,
    },
    {
      name: 'download-receipt',
      label: t('button.label.downloadReceiptPdf'),
      onClick: () => {
        downloadReceiptModalOpen();
      },
      isAvailable: status === Invoice_Status.Paid && invoice.receipt,
    },
    {
      name: 'markAsPaid',
      label: t('button.label.markAsPaid'),
      onClick: handleMarkAsPaidModalOpen,
      isAvailable:
        status === Invoice_Status.Issued &&
        [Invoice_Document_Type.DebitNote, Invoice_Document_Type.Invoice].includes(documentType),
      primary: true,
    },
    {
      name: 'delete',
      label: t('button.label.delete'),
      onClick: openDeleteConfirmationModal,
      isAvailable: status === Invoice_Status.Draft,
      default: true,
    },
    {
      name: 'cancel',
      label: t('button.label.cancel'),
      onClick: handleCancelModalOpen,
      isAvailable:
        invoice.syncWithAt?.status !== Invoice_Sync_With_At_Status.Failed &&
        ((status === Invoice_Status.Issued &&
          [Invoice_Document_Type.DebitNote, Invoice_Document_Type.CreditNote, Invoice_Document_Type.Invoice].includes(
            documentType
          ) &&
          !isOlderThan48Hours(invoice.issuedAt)) ||
          (status === Invoice_Status.Paid &&
            [Invoice_Document_Type.InvoiceReceipt, Invoice_Document_Type.SimplifiedInvoice].includes(documentType) &&
            !isOlderThan48Hours(invoice.issuedAt))),
      danger: true,
    },
  ];

  return (
    <SimpleGrid className="grid grid-cols-1 gap-2">
      {actions.map(
        (action) =>
          action.isAvailable && (
            <Button
              variant={action.primary ? 'primary' : 'secondary'}
              size="m"
              onClick={action.onClick}
              fullWidth
              // className={cn('h-12 rounded-[20px] bg-angel-feather text-[17px] hover:bg-angel-feather', {
              //   'bg-light-brand hover:bg-light-orange': action.primary,
              // })}
            >
              <Text className={cn('font-semibold', { 'text-carmine-red': action.danger })}>{action.label}</Text>
            </Button>
          )
      )}
      {downloadModalOpened && (
        <DownloadPdfActionModal
          opened={downloadModalOpened}
          close={downloadModalClose}
          title={t('label.downloadPdf')}
          invoice={invoice}
        />
      )}

      {downloadReceiptModalOpened && (
        <DownloadPdfActionModal
          opened={downloadReceiptModalOpened}
          close={downloadReceiptModalClose}
          title={t('button.label.downloadReceiptPdf')}
          invoice={invoice.receipt!}
        />
      )}
    </SimpleGrid>
  );
};

export default InvoiceSummaryActions;
