import { InvoicePreviewSummary } from '@/graphql-types';
import { CreateInvoiceFormValues } from '../types';

export const getGlobalDiscountForSubmitPayload = (
  isCreditOrDebitNote: boolean,
  previewGlobalDiscountValue:
    | InvoicePreviewSummary['globalDiscountAmount']
    | InvoicePreviewSummary['globalDiscountPercent'],
  formDiscountValue: CreateInvoiceFormValues['discountPercent'] | CreateInvoiceFormValues['discountAmount']
) => {
  if (isCreditOrDebitNote) {
    return previewGlobalDiscountValue;
  }

  return formDiscountValue || 0;
};
